import { Document, Font, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { getCountryISOAlpha3 } from '../../helper/CountryHelper';
import { getExtended } from '../../helper/FormatHelper';
import { getCompanyLogoFromProject } from '../../helper/PackageJsonHelper';
import { getResource, getResourceByLanguage } from '../../helper/ResourcesHelper';
import { sanitizeStringForPDF } from '../../helper/StringHelper';

// var img = getCurrentURLImage();
var img = `${window.location.origin}${getCompanyLogoFromProject()}`;
var checked = require('../../css/images/black-check-box.png');
var checkbox = require('../../css/images/blank-check-box.png');

const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};


export const savePdfBuffer = async (document) => {
  var blob = await pdf(document).toBlob();
  // var url = window.URL.createObjectURL(blob); 
  return blob;
};

export const savePdf = async (document, filename) => {
  saveBlob(await pdf(document).toBlob(), filename);
};
const addPropertiesStep = (obj) => (
  <View style={styles.section} >
    <View style={[{ width: '100%'}]}>
      <Text style={styles.textLabel}>{obj.description}</Text>
      <Text style={styles.boxText}>
        <Text style={styles.textSmall}>
          {sanitizeStringForPDF(obj.value)}
        </Text>
      </Text>
    </View>
  </View>
);

Font.register({
  family: 'ZCool',
  src: 'https://fonts.gstatic.com/s/zcoolkuaile/v7/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf'
});

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30
  },
  section: {
    flexDirection: 'row',
  },
  header: {
    position: 'absolute',
    flexDirection: 'row',
    left: 0,
    right: 0,
    marginLeft: 10,
    marginRight: 10
  },
  imageSection: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 6,
    paddingTop: 7,
    // padding: 5,
    marginBottom: 7,
    borderBottom: 1,
    borderBottomColor: '#555555', //backgroundColor: '#00548d',
    flexDirection: 'row',
  },
  titleHeader: {
    position: 'static',
    // flexDirection: 'row',
    textAlign: 'center',
    backgroundColor: '#c2922c',
    color: '#FFFFFF', //#666
    fontSize: 13,
    fontWeight: 'ultrabold',
    // width: '450px',
    height: '20px',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2
  },
  subTitleDocument: {
    // position: 'static',
    flexDirection: 'column',
    flexGrow: 1,
    textAlign: 'left',
    backgroundColor: '#c2922c',
    color: '#FFFFFF', //#666
    fontSize: 12,
    padding: 1,
    fontWeight: 'ultrabold',
    width: '530px',
    height: '20px',
  },
  footerSection: {
    padding: 10,
    paddingTop: 30,
    borderTop: 1,
    borderBottomColor: '#555555',
    color: '#fff',
    backgroundColor: '#00548d',
    flexDirection: 'row',
    position: 'relative',
  },
  columnDetails: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  rowDetails: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  paragraph: {
    fontSize: 8,
    justifyContent: 'space-between',
    marginTop: 10,
    paddingBottom: 10
  },
  textLarge: {
    fontSize: 12,
    marginTop: 5,
    paddingTop: 5,
  },
  subtitle: {
    color: '#13417a',
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
    fontWeight: 700
  },
  textLabel: {
    fontSize: 8, //6
    marginTop: 5,
    paddingTop: 5,
    marginBottom: 1
  },
  whiteBackground: {
    backgroundColor: "#ffffff"
  },
  textLabelBottom: {
    fontSize: 6,
    marginBottom: 5,
    paddingBottom: 5,
    marginTop: 1
  },
  textSmall: {
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
    backgroundColor: "#ffffff"
  },
  textSmall1: {
    fontSize: 9,
    // marginTop: 2,
    // paddingTop: 2,
    backgroundColor: "#ffffff"
  },
  textBold: {
    fontSize: 10,
    marginTop: 5,
    paddingTop: 5,
    fontWeight: 'bold',
    backgroundColor: "#ffffff"
  },
  boxText: {
    border: 1,
    // borderRadius: 5,
    borderRadius: 0,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    backgroundColor: '#ffffff'
  },
  boxText1: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 6,
    backgroundColor: '#ffffff'
  },
  boxText3: {
    border: '1px',
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 5,
    bordercollapse: 'collapse',
    width: '100%'
  },
  boxCheckbox: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 7,
    width: '15px',
    height: '15px',
  },
  smallPaddingCenter: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 0.5,
    width: '15px',
    height: '15px',
    textAlign: 'center'
  },
  boxDeclarationSignature: {
    borderBottom: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    width: 270,
    height: 50
  },
  boxDeclarationSignatureByImg: {
    borderBottom: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    paddingRight: 35,
    paddingLeft: 35,
  },
  signatureImg: {
    width: 200,
    height: 50
  },
  boxDeclarationDate: {
    //border: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 6,
    flexGrow: 2
  },
  boxDeclarationFullName: {
    //border: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 2
  },
  bulletPoint: {
    width: 6
  },
  spaceTopBottom: {
    //paddingBottom: 12,
    margin: 1,
  },
  spaceTopBottomForExtended: {
    //margin: 10,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 7,
    bottom: 25,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey',
  },
  footer: {
    position: 'absolute',
    bottom: 25,
    left: 0,
    right: 0,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    backgroundColor: '#c2922c',
    marginLeft: 10,
    marginRight: 10
  },
  titlefooter: {
    fontSize: 8,
    marginTop: 5,
    marginBottom: 5,
    // paddingTop: 5,
    // paddingBottom: 5,
    // paddingLeft: 15,
    // paddingRight: 15,
    textAlign: 'center',
    color: 'white',
  },

  breakable: {
    width: '100%',
    height: 25,
  },
});


const styleChinesse = {
  margin: 0,
  marginTop: 10,
  fontSize: 8,
  textAlign: 'justify',
  fontFamily: 'ZCool',
  padding: 0
}

//Document for Steps
/* --------------------STEP 1------------------- */
const Generalbusinessinformation = (obj, addObj, objAddress, objContacts) => (
  <View>
    <View style={[styles.section, { paddingTop: 10 }]}>
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text>
          <Text style={styles.textLabel}>As a client of Lighthouse FX, we're required to ensure that the information we hold on you is up to date, so we can comply with our regulatory obligations. This forms a part of our compliance due diligence procedures, whilst also helping us to ensure that our service is most efficiently oriented towards your requirements.</Text>
        </Text>
      </View>
    </View>
    <View style={[styles.columnDetails, { paddingRight: 10 }]}>
      <Text style={styles.textLabel}></Text>
    </View>
    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>{getResource('GeneralBusiness_Subtitle')}</Text>
      </View>
    </View>
    <Text style={styles.textLabel}>* These fields are mandatory. </Text>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* Registered business name</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj.companyName)}
          </Text>
        </Text>

      </View>
      {/*COLUMN 2 */}
     {/* <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* ABN/ACN Number</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'ABN/ACN Number'))}
          </Text>
        </Text>
</View> */}
      {/* COLUMN 3 */}
    {/*   <View style={[{ width: '33.4%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>* Company Registration/ Establishment Date</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'Company Registration/ Establishment Date'))}
          </Text>
        </Text>
      </View>

      */}
    </View>

   {/* <View style={[styles.section, {marginTop: 10}]}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>Trading Name (if applicable)</Text>
        <Text style={[styles.boxText]} break={true}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'Trading Name'))}
          </Text>
        </Text>
      </View>
    </View>

  */}
{/*
    <View style={[styles.section, { marginTop: 10 }]}>
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text>
          <Text style={styles.textLabel}>* Business Structure</Text>
        </Text>
        <View style={[styles.rowDetails, { paddingRight: 10 }]}>
          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {getExtended(addObj.step1, 'Business Structure') === 'Proprietary Company' ? 'X' : ''}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>Proprietary Company</Text>
          </View>
          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {getExtended(addObj.step1, 'Business Structure') === 'Public Company' ? 'X' : ''}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>Public Company</Text>
          </View>
        </View>
      </View>
    </View>
*/}

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>* Telephone number</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj.ResidentialPhoneNumber)}
          </Text>
        </Text>

      </View>
      {/*COLUMN 2 */}
      <View style={[{ width: '50%', paddingLeft: 5 }]}>
        <Text style={styles.textLabel}>* Authorised person's email address</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objContacts['EmailAddress01'] != null ? objContacts['EmailAddress01'] : '')}
          </Text>
        </Text>
      </View>
    </View>

    {/* <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 15 }]}>
        <Text style={styles.textSmall}>* Officially Registered Office Address</Text>
      </View>
    </View> */}
    <View style={styles.section}>
      {/* COLUMN 1 */}
      <View style={[{ width: '100%', paddingRight: 0 }]}>

        <Text style={styles.textLabel}>* Registered business address</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(objAddress.BusinessAddressStreet != null && objAddress.BusinessAddressStreet !== "") ?
              `${sanitizeStringForPDF(objAddress.BusinessAddressStreet)}, ${sanitizeStringForPDF(objAddress.BusinessAddressCityID)}, ${sanitizeStringForPDF(objAddress.OtherState !== ''? objAddress.OtherState:objAddress.BusinessAddressStateName)}, ${sanitizeStringForPDF(objAddress.BusinessAddressPostalCode)}, ${sanitizeStringForPDF(objAddress.countryName)}`
              :
              ` `
            }
          </Text>
        </Text>

      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}> Business website</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj.website)}
          </Text>
        </Text>

      </View>
      {/*COLUMN 2 */}
      { /*INDUSTRY SECTOR */}
      {/* <View style={[{ width: '50%', paddingLeft: 5 }]}>
        <Text style={styles.textLabel}>* Industry Sector</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj.IndustrySector)}
          </Text>
        </Text>
      </View> */}
    </View>

    {/* <View style={styles.section}>
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>* Transaction Purposes (with Lighthouse FX)</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'Purpose of Business'))}
          </Text>
        </Text>
      </View>
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>* Source of funds</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'Source of Funds'))}
          </Text>
        </Text>
      </View>
    </View> */}

    {/* <View style={[styles.columnDetails, { paddingRight: 10 }]}>
      <Text style={styles.textLabel}></Text>
    </View> */}

    {/* <View style={[styles.columnDetails, { paddingRight: 10 }]}>
      <Text style={styles.textLabel}></Text>
    </View> */}

     {/*
      addObj.step1.length > 0 &&
      <View >

        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>

        {
          addObj.step1.map((detail, index) => {
            if(index % 10 === 0)
            {
            return (<View><View break={true} />
                    {addPropertiesStep(detail)}</View>)
            }
            else
            {
            return  (addPropertiesStep(detail))    
            }                 
          })
        }
      </View> */
    } 
  </View >
);

/* --------------------STEP 2------------------- */
const PrincipalPlaceOfBusiness = (obj, addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_2')}</Text>
    <Text style={styles.textSmall}>{getResource('Subtitle_Corp_ResidentialAddress')}</Text>
    <Text style={styles.textLabel}>{getResource('Label_Business_StreetAddress')}</Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {(obj.BusinessAddressStreet !== '' && obj.BusinessAddressStreet != null) ?
          obj.BusinessAddressStreet : ' '}
      </Text>
    </Text>
    <Text style={styles.textLabel}>{getResource('Label_Business_AddressLine2')}</Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {(obj.BusinessAddressLine2 !== '' && obj.BusinessAddressLine2 != null) ?
          obj.BusinessAddressLine2 : ' '}
      </Text>
    </Text>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Business_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.BusinessAddressCityName !== '' && obj.BusinessAddressCityName != null) ?
              obj.BusinessAddressCityName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Business_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.OtherState === '' && obj.BusinessAddressStateName !== '' && obj.BusinessAddressStateName != null) ?
              obj.BusinessAddressStateName : ' '}
            {(obj.OtherState !== '' ? obj.OtherState : ' ')}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Business_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Business_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.BusinessAddressPostalCode !== '' && obj.BusinessAddressPostalCode != null) ?
              obj.BusinessAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>
    {
      addObj.step2.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step2.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 3------------------- */
const ProductsAndServices = (addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_3')}</Text>
    {
      addObj.step3.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step3.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 4------------------- */
const ForeignExchangeForwards = (addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_4')}</Text>
    {
      addObj.step4.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step4.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 5------------------- */
const Accountprimarycontact = (obj, director) => (
  <View>
    <View style={styles.breakable} />
    <View style={styles.section} break={true}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>{getResource('PrimaryContact_Subtitle')}{" " + director}</Text>
      </View>
    </View>
    {/* <View style={styles.section}>
      <View style={[styles.rowDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel} break={true}>{getResourceByLanguage('Label_Salutation', 'English') + ' '}</Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['SalutationID0' + director] != null && obj['SalutationID0' + director] === 'Mr.' ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}>

              </Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Mr</Text>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['SalutationID0' + director] != null && obj['SalutationID0' + director] === 'Miss.' ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Miss</Text>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['SalutationID0' + director] != null && obj['SalutationID0' + director] === 'Mrs.' ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Mrs</Text>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['SalutationID0' + director] != null && obj['SalutationID0' + director] === 'Ms.' ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Ms</Text>
        </View>
      </View>
    </View> */}

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['FirstName0' + director])}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>{getResource('Label_LastName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['LastName0' + director])}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['DateOfBirth0' + director])}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.rowDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel} break={true}>{getResourceByLanguage('Label_Roles', 'English') + ' '}</Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['Roles0' + director] != null && obj['Roles0' + director].includes('UBO') ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}>

              </Text>
            </View>
          </View>
          <Text style={styles.textLabel}>{getResource("Label_UBO")}</Text>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['Roles0' + director] != null && obj['Roles0' + director].includes('Director') ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>{getResource("Label_Director")}</Text>
        </View>

        {/* <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['Roles0' + director] != null && obj['Roles0' + director].includes('UBO') ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>UBO</Text>
        </View> */}
      </View>
    </View>
    <View style={styles.section}>
      <View style={[{ width: '100%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>* Registered business address</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressStreet0' + director] != null && obj['ResidentialAddressStreet0' + director] !== "") ?
              `${sanitizeStringForPDF(obj['ResidentialAddressStreet0' + director])}, ${sanitizeStringForPDF(obj['ResidentialAddressCity0' + director])}, ${sanitizeStringForPDF(obj['OtherState0' + director] !== '' ? obj['OtherState0' + director] :obj['ResidentialAddressStateName0' + director])}, ${sanitizeStringForPDF(obj['ResidentialAddressPostalCode0' + director])}, ${sanitizeStringForPDF(obj['countryName0' + director])}`
              :
              ` `
            }
          </Text>
        </Text>
      </View>
    </View>
    {/* <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_ContactNumbers')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_MainContactNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['ResidentialPhoneNumber0' + director] !== '' && obj['ResidentialPhoneNumber0' + director] != null) ?
              obj['ResidentialPhoneNumber0' + director] : ' ')}
          </Text>
        </Text>
      </View>
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['EmailAddress0' + director] !== '' && obj['EmailAddress0' + director] != null) ?
              obj['EmailAddress0' + director] : ' ')}
          </Text>
        </Text>
      </View>
    </View> */}
    {/* <View style={{ width: '50%', paddingRight: 5 }}>
      <Text style={styles.textLabel}>{getResource('Label_SSN')}</Text>
      <Text style={styles.boxText}>
        <Text style={styles.textSmall}>
          {(obj['SSN0' + director] !== '' && obj['SSN0' + director] != null) ?
            obj['SSN0' + director] : ' '}
        </Text>
      </Text>
    </View> */}

    {/*Personal Identification */}
    {
      ((obj['documentTypeID0' + director].includes('Identity') && (obj['numberIdentification0' + director] !== '' && obj['numberIdentification0' + director] != null)) ||
      (obj['documentTypeID0' + director].includes('Driving') && (obj['DriverLicenseNumber0' + director] !== '' && obj['DriverLicenseNumber0' + director] != null)) ||
      (obj['documentTypeID0' + director].includes('Passport') && (obj['PassportNumber0' + director] !== '' && obj['PassportNumber0' + director] != null))) && (
    <>
      <View style={styles.section} break={true}>
        <View style={styles.columnDetails}>
          <Text style={styles.textBold}>{getResource('SubTitle_PersonalIdentification')}</Text>
        </View>
      </View>

      <View style={styles.section}>
      {/*COLUMN 1 */}
        <View style={[{ width: '50%', paddingRight: 5 }]}>
          <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['countryNameIdentification0' + director] !== '' && obj['countryNameIdentification0' + director] != null) ?
                obj['countryNameIdentification0' + director] : ' '}
            </Text>
          </Text>
        </View>
      {/*COLUMN 2 */}
      {
        obj['documentTypeID0' + director].includes('Identity') &&
        <View style={[{ width: '50%', paddingLeft: 5 }]}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['numberIdentification0' + director] !== '' && obj['numberIdentification0' + director] != null) ?
                obj['numberIdentification0' + director] : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj['documentTypeID0' + director].includes('Driving') &&
        <View style={[{ width: '50%', paddingLeft: 5 }]}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['DriverLicenseNumber0' + director] !== '' && obj['DriverLicenseNumber0' + director] != null) ?
                obj['DriverLicenseNumber0' + director] : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj['documentTypeID0' + director].includes('Passport') &&
        <View style={[{ width: '50%', paddingLeft: 5 }]}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['PassportNumber0' + director] !== '' && obj['PassportNumber0' + director] != null) ?
                obj['PassportNumber0' + director] : ' '}
            </Text>
          </Text>
        </View>
      }
      </View>
    </>)}

    {/* <View>
      {
        obj['valuesOfAdditionalPropertiesDefs' + director].length > 0 &&
        <View>
          <View style={styles.section}>
            <View style={styles.columnDetails}>
              <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
            </View>
          </View>
          {
            obj['valuesOfAdditionalPropertiesDefs' + director].map((detail) => {
              return (addPropertiesStep(detail))
            })
          }
        </View>
      }
    </View> */}

  </View>
);

/* --------------------STEP DIRECTOR------------------- */
const Directorcontact = (obj, director) => (
  <View>
    <View style={styles.breakable} />
    <View style={styles.section} break={true}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>{getResource('DirectorContact_Subtitle')}{" " + director}</Text>
      </View>
    </View>


    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['FirstName0' + director])}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>{getResource('Label_LastName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['LastName0' + director])}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['DateOfBirth0' + director])}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.rowDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel} break={true}>{getResourceByLanguage('Label_Roles', 'English') + ' '}</Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['Roles0' + director] != null && obj['Roles0' + director].includes('UBO') ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}>

              </Text>
            </View>
          </View>
          <Text style={styles.textLabel}>{getResource("Label_UBO")}</Text>
        </View>

        {/* <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['Roles0' + director] != null && obj['Roles0' + director].includes('Director') ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>{getResource("Label_Director")}</Text>
        </View> */}

      </View>
    </View>
    <View style={styles.section}>
      <View style={[{ width: '100%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>* Registered business address</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressStreet0' + director] != null && obj['ResidentialAddressStreet0' + director] !== "") ?
              `${sanitizeStringForPDF(obj['ResidentialAddressStreet0' + director])}, ${sanitizeStringForPDF(obj['ResidentialAddressCity0' + director])}, ${sanitizeStringForPDF(obj['OtherState0' + director] !== '' ? obj['OtherState0' + director]: obj['ResidentialAddressStateName0' + director])}, ${sanitizeStringForPDF(obj['ResidentialAddressPostalCode0' + director])}, ${sanitizeStringForPDF(obj['countryName0' + director])}`
              :
              ` `
            }
          </Text>
        </Text>
      </View>
    </View>
  </View>
);

/* --------------------STEP 6------------------- */
const ShareholderOwner = (obj, director) => (
  <View>
    <View style={styles.breakable} />
    <View style={styles.section} break={true}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>{getResource('Shareholder_Subtitle')}{" " + director}</Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['FirstName0' + director])}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>{getResource('Label_LastName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['LastName0' + director])}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['DateOfBirth0' + director])}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[{ width: '100%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>* Registered business address</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressStreet0' + director] != null && obj['ResidentialAddressStreet0' + director] !== "") ?
              `${sanitizeStringForPDF(obj['ResidentialAddressStreet0' + director])}, ${sanitizeStringForPDF(obj['ResidentialAddressCity0' + director])}, ${sanitizeStringForPDF(obj['OtherState0' + director] !== ''?obj['OtherState0' + director]:obj['ResidentialAddressStateName0' + director])}, ${sanitizeStringForPDF(obj['ResidentialAddressPostalCode0' + director])}, ${sanitizeStringForPDF(obj['countryName0' + director])}`
              :
              ` `
            }
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_OwnerNumbers')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_OwnerNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['ResidentialPhoneNumber0' + director] !== '' && obj['ResidentialPhoneNumber0' + director] != null) ?
              obj['ResidentialPhoneNumber0' + director] : ' ')}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['EmailAddress0' + director] !== '' && obj['EmailAddress0' + director] != null) ?
              obj['EmailAddress0' + director] : ' ')}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
    {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Percentage_Ownership')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
          {sanitizeStringForPDF(obj['PercentageOwnership0' + director])}
          </Text>
        </Text>
      </View>
    </View>
  </View>
);


/* --------------------STEP 7------------------- */
const DirectorAndAppointedOfficer = (obj, addObj) => (
  <View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.FirstName !== '' && obj.FirstName != null) ?
              obj.FirstName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.DateOfBirth !== '' && obj.DateOfBirth != null) ?
              obj.DateOfBirth : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_MiddleName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ShortName !== '' && obj.ShortName != null) ?
              obj.ShortName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_LastName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.LastName !== '' && obj.LastName != null) ?
              obj.LastName : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStreet !== '' && obj.ResidentialAddressStreet != null) ?
              obj.ResidentialAddressStreet : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialCityId !== '' && obj.ResidentialCityId != null) ?
              obj.ResidentialCityId : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.OtherState === '' && obj.ResidentialAddressStateName !== '' && obj.ResidentialAddressStateName != null) ?
              obj.ResidentialAddressStateName : ' '}
              {(obj.OtherState !== '' ? obj.OtherState : ' ')}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressPostalCode !== '' && obj.ResidentialAddressPostalCode != null) ?
              obj.ResidentialAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>

    {/*Personal Identification */}
    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryNameIdentification !== '' && obj.countryNameIdentification != null) ?
              obj.countryNameIdentification : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj.documentTypeID.includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.numberIdentification !== '' && obj.numberIdentification != null) ?
                obj.numberIdentification : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.DriverLicenseNumber !== '' && obj.DriverLicenseNumber != null) ?
                obj.DriverLicenseNumber : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PassportNumber !== '' && obj.PassportNumber != null) ?
                obj.PassportNumber : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>

    {
      addObj.step6.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step6.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }


  </View>
);

/* --------------------STEP 8------------------- */
const UltimateBeneficialOwner = (obj, addObj) => (
  <View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.FirstName !== '' && obj.FirstName != null) ?
              obj.FirstName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.DateOfBirth !== '' && obj.DateOfBirth != null) ?
              obj.DateOfBirth : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_MiddleName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ShortName !== '' && obj.ShortName != null) ?
              obj.ShortName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_LastName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.LastName !== '' && obj.LastName != null) ?
              obj.LastName : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStreet !== '' && obj.ResidentialAddressStreet != null) ?
              obj.ResidentialAddressStreet : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialCityId !== '' && obj.ResidentialCityId != null) ?
              obj.ResidentialCityId : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.OtherState === '' && obj.ResidentialAddressStateName !== '' && obj.ResidentialAddressStateName != null) ?
              obj.ResidentialAddressStateName : ' '}
              {(obj.OtherState !== '' ? obj.OtherState: ' ')}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressPostalCode !== '' && obj.ResidentialAddressPostalCode != null) ?
              obj.ResidentialAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_ContactNumbers')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_MainContactNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialPhoneNumber !== '' && obj.ResidentialPhoneNumber != null) ?
              obj.ResidentialPhoneNumber : ' '}
          </Text>
        </Text>
      </View>
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.EmailAddress !== '' && obj.EmailAddress != null) ?
              obj.EmailAddress : ' '}
          </Text>
        </Text>
      </View>
    </View>

    {/*Personal Identification */}
    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryNameIdentification !== '' && obj.countryNameIdentification != null) ?
              obj.countryNameIdentification : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj.documentTypeID.includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.numberIdentification !== '' && obj.numberIdentification != null) ?
                obj.numberIdentification : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.DriverLicenseNumber !== '' && obj.DriverLicenseNumber != null) ?
                obj.DriverLicenseNumber : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PassportNumber !== '' && obj.PassportNumber != null) ?
                obj.PassportNumber : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>

    {
      addObj.step7 && addObj.step7.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step7.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }


  </View>
);

/* --------------------STEP 5------------------- */
const TradingRequirements = (obj) => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        {/*  ------------STEP 5--------------*/}
        <Text style={styles.subtitle}>{getResource('Title_Corp_5')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_CurrenciesInterested')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkEUR &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkEUR &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_EUR')}</Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkGBP &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkGBP &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_GBP')}</Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkUSD &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkUSD &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_USD')}</Text>
      </View>
      {/*COLUMN 4 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkAED &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkAED &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_AED')}</Text>
      </View>
      {/*COLUMN 5 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkOther &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {obj.CurrenciesInterestedTradingOther}</Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_AmountPerMonth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.AmountTradedMonthIDName !== '' && obj.AmountTradedMonthIDName != null) ?
              obj.AmountTradedMonthIDName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_PurposeForExchanging')}</Text>
        {!obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PurposeExchangingCurrencyIDName !== '' && obj.PurposeExchangingCurrencyIDName != null) ?
                obj.PurposeExchangingCurrencyIDName : ' '}
            </Text>
          </Text>
        }
        {obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PurposeExchangingCurrencyOther !== '' && obj.PurposeExchangingCurrencyOther != null) ?
                obj.PurposeExchangingCurrencyOther : ' '}
            </Text>
          </Text>
        }
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_HearAboutUs')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {obj.clickRadio}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      {obj.clickRadio === obj.SalesCallState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_SalesCall')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsSalesCall !== '' && obj.WhereYouHearAboutUsSalesCall != null) ?
                obj.WhereYouHearAboutUsSalesCall : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.ReferralState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Referral')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsReferral !== '' && obj.WhereYouHearAboutUsReferral != null) ?
                obj.WhereYouHearAboutUsReferral : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.EventState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Event')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsEvent !== '' && obj.WhereYouHearAboutUsEvent != null) ?
                obj.WhereYouHearAboutUsEvent : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.OtherState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Other')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsOther !== '' && obj.WhereYouHearAboutUsOther != null) ?
                obj.WhereYouHearAboutUsOther : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>
  </View>
);

/* --------------------STEP DECLARATION------------------- */
const Declaration = () => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={[styles.subtitle]}>{getResource('Title_Declaration')}</Text>
        <Text style={styles.paragraph}>{getResource('Label_Declaration')}</Text>
      </View>
    </View>
    <View style={[styles.section, { paddingTop: 10 }]}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        {/**------------SIGNATURE IMAGE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={styles.boxDeclarationSignatureByImg}>
            <Text style={styles.paragraph}>
              <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
            </Text>
          </Text>
          :
          <Text style={styles.boxDeclarationSignature}>
            <Text style={styles.paragraph}>
              {' '}
            </Text>
          </Text>
        }

        {/**------------SIGNATURE FULLNAME----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': ' + sessionStorage.getItem('signatureFullName')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': '
            }
          </Text>
        }

        {/**------------SIGNATURE TITLE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Title: Engineer*/}
            {
              getResource('Label_TitleOccupation') + ': ' + sessionStorage.getItem('signatureTitle')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_TitleOccupation') + ': '
            }
          </Text>
        }
        {/**------------SIGNATURE DATE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': ' + sessionStorage.getItem('signatureDate')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': '
            }
          </Text>
        }
      </View>
    </View>
  </View>
);

const ForeignExchangeAndPaymentRequirements = (objAdd) => (
  <View>
    <View style={styles.breakable} />
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Foreign Exchange and payment requirements</Text>
      </View>
    </View>

    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Currencies required</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '450px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Currencies Required'))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Approx. volume per trade</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '420px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Expected Monthly Trade Volume'))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Approx. number of transactions per month</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '350px' }]}>
        {(sanitizeStringForPDF(getExtended(objAdd.step1, 'Expected volume of transaction per month')))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Approx. monthly FX requirement</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '420px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Expected monthly FX requirements'))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Countries which funds transfers are expected to go to</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '310px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Trading Countries (which funds transfer to go)'))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Countries which funds transfers are expected to come from</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '310px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Trading Countries (where funds come from)'))}
      </Text>
    </View>

    {/* <View style={styles.breakable} /> */}
    <View style={styles.section}>
      {/* <View style={styles.section} break={true}> */}

      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* Print name</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(sessionStorage.getItem('signatureFullName'))}
          </Text>
        </Text>
      </View>

      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* Title</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(sessionStorage.getItem('signatureTitle'))}
          </Text>
        </Text>
      </View>

      <View style={[{ width: '33.4%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>* Date</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(sessionStorage.getItem('signatureDate'))}
          </Text>
        </Text>
      </View>
    </View>

    <Text style={styles.textLabel}>* Signature</Text>
    <Text style={styles.boxText}>
      {sessionStorage.getItem('signatureImg') !== 'null' ?
        <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
        :
        <Text style={styles.textSmall}>
          {sanitizeStringForPDF('')}
        </Text>
      }
    </Text>
  </View >
);

const ExtendedProperties = (addObj) => {
  const groupBy2 = ([a, b, ...rest]) => {
    if (rest.length === 0) {
      return [[a, b].filter(x => x != null)]
    }
    return [[a, b]].concat(groupBy2(rest));
  }

  const extendeds = groupBy2(addObj?.step1 ?? []);

  return (
    <View >
      <View style={styles.breakable} />

        {/* Foreign Exchange and Payment Requirements */}
      
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c'  }]}>
            <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Additional Information</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        {extendeds.map((row) => {
            return (
              <View style={styles.section} >
                {row.map((ext, index) => {
                  const desc = ext.description.toLowerCase();
                  let value = ext.value;
                  if (desc.includes('trading countries') || desc.includes('destination of funds') || desc.includes('origin of funds')) {
                    const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
                    const countryNames = ext.value
                      .split(',')
                      .filter(countryCode => countryCode != null && countryCode !== '' && getCountryISOAlpha3(countryCode) != null)// Filters any country not supported to prevent RangeError from Intl.DisplayNames.prototype.of()
                      .map((countryCode) => regionNamesInEnglish.of(countryCode))
                      .join(', ');
                    value = countryNames;
                  }
                  return (
                    <View style={[{ width: '50%', paddingRight: index === 0 ? 5 : 0,paddingLeft: index === 1 ? 5 : 0}]} break={true}>
                      <Text style={styles.textLabel}>{ext.description}</Text>
                      <Text style={styles.boxText}>
                        <Text style={styles.textSmall}>
                          {sanitizeStringForPDF(value)}
                        </Text>
                      </Text>
                    </View>
                  )
                })}
              </View>
            )
          })
        }

        {/* <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 10 }]}>
            <Text style={styles.textLabel}>* Print Name</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {sanitizeStringForPDF(sessionStorage.getItem('signatureFullName'))}
              </Text>
            </Text>
          </View>

          <View style={[styles.columnDetails, { paddingRight: 10 }]}>
            <Text style={styles.textLabel}>* Title</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {sanitizeStringForPDF(sessionStorage.getItem('signatureTitle'))}
              </Text>
            </Text>
          </View>

          <View style={[styles.columnDetails, { paddingRight: 10 }]}>
            <Text style={styles.textLabel}>* Date</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {sanitizeStringForPDF(sessionStorage.getItem('signatureDate'))}
              </Text>
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 10 }]}>
            <Text style={styles.textLabel}>* Signature</Text>
            <Text style={styles.boxText}>
              {sessionStorage.getItem('signatureImg') !== 'null' ?
                <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
                :
                <Text style={styles.textSmall}>
                  {sanitizeStringForPDF('')}
                </Text>
              }
            </Text>
          </View>
        </View> */}
    </View>
  );
}

const Signature = () => {

  return (
    <View>
      <View style={styles.breakable} />
        {/* <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c'  }]}>
            <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Additional Information</Text>
          </View>
        </View> */}

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 10 }]}>
            <Text style={styles.textLabel}>* Print name</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {sanitizeStringForPDF(sessionStorage.getItem('signatureFullName'))}
              </Text>
            </Text>
          </View>

          <View style={[styles.columnDetails, { paddingRight: 10 }]}>
            <Text style={styles.textLabel}>* Title</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {sanitizeStringForPDF(sessionStorage.getItem('signatureTitle'))}
              </Text>
            </Text>
          </View>

          <View style={[styles.columnDetails, { paddingRight: 10 }]}>
            <Text style={styles.textLabel}>* Date</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {sanitizeStringForPDF(sessionStorage.getItem('signatureDate'))}
              </Text>
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 10 }]}>
            <Text style={styles.textLabel}>* Signature</Text>
            <Text style={styles.boxText}>
              {sessionStorage.getItem('signatureImg') !== 'null' ?
                <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
                :
                <Text style={styles.textSmall}>
                  {sanitizeStringForPDF('')}
                </Text>
              }
            </Text>
          </View>
        </View>
    </View>
  );
}

// Create Document Component
export const MyDocumentCorporateOther = (props) => (
  <Document>
    <Page size="A4" style={styles.page} wrap>
      {/*-----------HEADER--------------*/}
      <View style={styles.section} fixed={true}>
        <View style={[styles.imageSection, { alignItems: 'center', padding: 2, marginTop: 25 }]}>
          {/* COLUMN 1 */}
          <View style={[{ padding: 1, marginRight: 150 }]}>
            <Image src={{ uri: img, method: 'GET', headers: {}, body: '' }} style={{width: 150, height: 50}} />
          </View>
          {/* COLUMN 2 */}
          <View style={[{ display: 'flex', width: 'auto', backgroundColor: '#c2922c', paddingTop: 2, paddingLeft: 2, paddingBottom: 2, paddingRight: 25 }]}>
            <Text style={[styles.titleHeader]}>Corporate Client Application Form</Text>
          </View>
        </View>
      </View>
      {/* ------------STEP 1-------------- */}
      {
        <View style={styles.spaceTopBottom}>
          {
            Generalbusinessinformation(props.obj.Generalbusinessinformation, props.obj.AdditionalProperties, props.obj.PrincipalPlaceOfBusiness, props.obj.Accountprimarycontact)
          }
          {
            ExtendedProperties(props.obj.AdditionalProperties)
          }
        </View>
      }
      {/* ------------STEP 2-------------- */}
      {/* {
        <View style={styles.spaceTopBottom} break={true}>
          {
            PrincipalPlaceOfBusiness(props.obj.PrincipalPlaceOfBusiness, props.obj.AdditionalProperties)
          }
        </View>
      } */}

      {/* ------------STEP 5--------------*/}
      {
        <View break={true}>
          {/* <View style={styles.section}>
            <View style={styles.columnDetails}>
              <Text style={styles.subtitle}>{getResource('Title_Corp_3')}</Text>
            </View>
          </View> */}
          <View style={styles.spaceTopBottom}>
            {
              Accountprimarycontact(props.obj.Accountprimarycontact, 1)
            }
          </View>
          {
            (props.obj.Accountprimarycontact.trader02) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 2)
              }
            </View>
          }
          {
            (props.obj.Accountprimarycontact.trader03) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 3)
              }
            </View>
          }
          {
            (props.obj.Accountprimarycontact.trader04) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 4)
              }
            </View>
          }
          {
            (props.obj.Accountprimarycontact.trader05) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 5)
              }
            </View>
          }
        </View>
      }

       {/* ------------STEP DIRECTORS--------------*/}
       {props.obj.DirectorAndAppointedOfficer.FirstName01 != null && props.obj.DirectorAndAppointedOfficer.FirstName01 !== "" &&
        <View break={true}>
          {/* <View style={styles.section}>
            <View style={styles.columnDetails}>
              <Text style={styles.subtitle}>{getResource('Title_Corp_3')}</Text>
            </View>
          </View> */}
          <View style={styles.spaceTopBottom}>
            {
              Directorcontact(props.obj.DirectorAndAppointedOfficer, 1)
            }
          </View>
          {
            (props.obj.DirectorAndAppointedOfficer.trader02) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Directorcontact(props.obj.DirectorAndAppointedOfficer, 2)
              }
            </View>
          }
          {
            (props.obj.DirectorAndAppointedOfficer.trader03) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Directorcontact(props.obj.DirectorAndAppointedOfficer, 3)
              }
            </View>
          }
          {
            (props.obj.DirectorAndAppointedOfficer.trader04) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Directorcontact(props.obj.DirectorAndAppointedOfficer, 4)
              }
            </View>
          }
          {
            (props.obj.DirectorAndAppointedOfficer.trader05) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Directorcontact(props.obj.DirectorAndAppointedOfficer, 5)
              }
            </View>
          }
        </View>
      }
      
      {/* ------------STEP 6--------------*/}
      
      {props.obj.Owners != null && props.obj.Owners.FirstName01 != null && props.obj.Owners.FirstName01 !== "" /*&& props.obj.Owners.length > 0 */&&
        <View break={true}>
          {/* <View style={styles.section}>
            <View style={styles.columnDetails}>
              <Text style={styles.subtitle}>{getResource('Title_Corp_3')}</Text>
            </View>
          </View> */}
          <View style={styles.spaceTopBottom}>
            {
              ShareholderOwner(props.obj.Owners, 1)
            }
          </View>
          {
            (props.obj.Owners.trader02) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                ShareholderOwner(props.obj.Owners, 2)
              }
            </View>
          }
          {
            (props.obj.Owners.trader03) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                ShareholderOwner(props.obj.Owners, 3)
              }
            </View>
          }
          {
            (props.obj.Owners.trader04) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                ShareholderOwner(props.obj.Owners, 4)
              }
            </View>
          }
          {
            (props.obj.Owners.trader05) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                ShareholderOwner(props.obj.Owners, 5)
              }
            </View>
          }
        </View>
      }

      {/* ------------STEP 7--------------*/}
      {/* {props.obj.DirectorAndAppointedOfficer != null && <View break={true}>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.subtitle}>{getResource('Title_Corp_6')}</Text>

            <Text style={styles.textLabel}>Have you a Director?</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {props.obj.DirectorAndAppointedOfficer.clickRadio}
              </Text>
            </Text>
          </View>
        </View> */}
        {/* ONLY YES 
          DIRECTOR AND APPOINTER OFFICER*/}
        {
          // props.obj.DirectorAndAppointedOfficer.clickRadio === getResource('Checkbox_Yes') &&
          // <View style={styles.spaceTopBottom}>
          //   {
          //     DirectorAndAppointedOfficer(props.obj.DirectorAndAppointedOfficer, props.obj.AdditionalProperties)
          //   }
          // </View>
        }
      {/* </View>
      } */}

      {/* ------------STEP 8--------------*/}
      {/* {props.obj.UltimateBeneficialOwner != null && <View break={true}>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.subtitle}>{getResource('Title_Corp_7')}</Text>

            <Text style={styles.textLabel}>Have you an Ultimate Beneficial Owner?</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {props.obj.UltimateBeneficialOwner.clickRadio}
              </Text>
            </Text>
          </View>
        </View> */}
        {/* ONLY YES 
          DIRECTOR AND APPOINTER OFFICER*/}
        {/* {
          props.obj.UltimateBeneficialOwner.clickRadio === getResource('Checkbox_Yes') &&
          <View style={styles.spaceTopBottom}>
            {
              UltimateBeneficialOwner(props.obj.UltimateBeneficialOwner, props.obj.AdditionalProperties)
            }
          </View>
        } */}
      {/* </View>
      } */}

      {/* ------------STEP 5--------------*/}
      {/*
        props.obj.ShareholderOwner.clickRadio === getResource('Checkbox_Yes') &&
        <View style={styles.spaceTopBottom} break={true}>
          {
            TradingRequirements(props.obj.TradingRequirements)
          }
        </View>
        */
      }

      {
        <View style={styles.spaceTopBottom} break={true}>
          {
            Signature()
          }
        </View>
      }

      {/* ------------STEP DECLARATION-------------- */}
      {
        // <View style={styles.spaceTopBottom}>
        //   {
        //     Declaration()
        //   }
        // </View>
      }

      {/*-----------FOOTER--------------*/}
      <View style={[styles.footer, { alignItems: 'center' }]} fixed={true}>
        <Text style={styles.titlefooter}>Lighthouse FX | T: +44 (0) 203 967 8796| A: 50 Liverpool Street, London, EC2M 7PY, England, United Kingdom| https://lighthousefx.co.uk</Text>
      </View>
      <View style={[styles.footer, { alignItems: 'right' }]} fixed={true}>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed={true} />
      </View>
      {/*<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed={true} />   */}
    </Page>

  </Document >
);

const returnUrlBlob = (blob) => {
  let url = blob //window.URL.createObjectURL(blob);
  return url;
}

export const returnFileContent = async (document) => {
  return returnUrlBlob(await pdf(document).toBlob())
}