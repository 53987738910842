import { getCurrentCompanyName } from "../helper/PackageJsonHelper";

export function getStyle() {
    const companyName = getCurrentCompanyName();
    switch (companyName) {
        case 'Nexolink':
            return defaultStyle;
        case 'Newport':
            return newPortStyle;
        case 'AFEX':
            return afexStyle;
        case 'Castle':
            return castleStyle;
        case 'Premier':
            return premierStyle;
        case 'Khyber':
            return khyberStyle;
        case 'Xushi':
            return xushiStyle;
        case 'Lighthouse':
            return lighthouseStyle;
        default:
            return defaultStyle;
    }
}

const defaultStyle = {
    headerColor: {
        borderBottom: '1px solid #e5e5e5',
    },
    backForm: {
        backgroundColor: '#fff'
    },
    titleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    inputStyle: {
        paddingBottom: "5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    boldLabel: {
        fontWeight: 800
    },
    /*MARGIN*/
    cBody: {
        padding: '10px 20px',
        border: '1px solid #e5e5e5',
        //backgroundColor: '#f8f8f8'
    },
    cForm: {
        padding: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        //borderBottom: '1px solid #e5e5e5',
        //borderTop: '1px solid #e5e5e5'
    },
    wButton: {
        padding: '0 10px',
        lineHeight: '28px'
    },
    resendCodeButton: {
        padding: '0px',
        lineHeight: '28px'
    },
    leftButton: {
        padding: '14px 0px 0px 5px'
    },
    formLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '5px'
    },
    preRegRadioLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '3px'
    },
    footerColor: {
        borderTop: '1px solid #333534',
        backgroundColor: 'rgb(19, 65, 122)',
        color: '#fff',
        width:'100%',
        
    },
    navbarLink: {
        fontSize: '12px',
        marginRight: '10px',
        color: '#333'
    }
}

const afexStyle = {
    headerColor: {
        backgroundColor: '#fff',
        borderBottom: '1px solid #f37323'
    },
    backForm: {
        backgroundColor: '#fff'
    },
    footerColor: {
        //borderTop: '1px solid rgb(229, 229, 229)',
        backgroundColor: '#15171A',
        color: '#fff',
        width:'100%',
    },
    titleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    inputStyle: {
        paddingBottom: "5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    boldLabel: {
        fontWeight: 800
    },
    /*MARGIN*/
    cBody: {
        padding: '10px 20px',
        border: '1px solid #e5e5e5',
        //backgroundColor: '#f8f8f8'
    },
    cForm: {
        //padding: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px'
        //borderBottom: '1px solid #e5e5e5',
        //borderTop: '1px solid #e5e5e5'
    },
    wButton: {
        padding: '0 10px',
        lineHeight: '28px'
    },
    resendCodeButton: {
        padding: '0px',
        lineHeight: '28px'
    },
    leftButton: {
        padding: '14px 0px 0px 5px'
    },
    formLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '5px'
    },
    preRegRadioLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '3px'
    },
    navbarLink: {
        fontSize: '12px',
        marginRight: '10px',
        color: '#333'
    }
}
const castleStyle = {
    headerColor: {
        backgroundColor: '#fff',
        borderBottom: '1px solid #7D8347'
        //borderBottom: '1px solid #f37323'
    },
    backForm: {
        backgroundColor: '#fff'
    },
    footerColor: {
        //borderTop: '1px solid rgb(229, 229, 229)',
        backgroundColor: '#222',
        color: '#fff',
        width:'100%',
    },
    titleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    inputStyle: {
        paddingBottom: "5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    boldLabel: {
        fontWeight: 800
    },
    /*MARGIN*/
    cBody: {
        padding: '10px 20px',
        border: '1px solid #e5e5e5',
        //backgroundColor: '#f8f8f8'
    },
    cForm: {
        //padding: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px'
        //borderBottom: '1px solid #e5e5e5',
        //borderTop: '1px solid #e5e5e5'
    },
    wButton: {
        padding: '0 10px',
        lineHeight: '28px'
    },
    resendCodeButton: {
        padding: '0px',
        lineHeight: '28px'
    },
    leftButton: {
        padding: '14px 0px 0px 5px'
    },
    formLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '5px'
    },
    preRegRadioLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '3px'
    },
    navbarLink: {
        fontSize: '12px',
        marginRight: '10px',
        color: '#333'
    }
}

const newPortStyle = {
    headerColor: {
        backgroundColor: '#fff',
        borderBottom: '1px solid rgb(229, 229, 229)'
    },
    backForm: {
        backgroundColor: '#ffffffcc'
    },
    footerColor: {
        //borderTop: '1px solid rgb(229, 229, 229)',
        backgroundColor: '#15171A',
        color: '#fff',
        width:'100%',
    },
    titleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    inputStyle: {
        paddingBottom: "5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    boldLabel: {
        fontWeight: 800
    },
    /*MARGIN*/
    cBody: {
        padding: '10px 20px',
        border: '1px solid #e5e5e5',
        //backgroundColor: '#f8f8f8'
    },
    cForm: {
        //padding: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px'
        //borderBottom: '1px solid #e5e5e5',
        //borderTop: '1px solid #e5e5e5'
    },
    wButton: {
        padding: '0 10px',
        lineHeight: '28px'
    },
    resendCodeButton: {
        padding: '0px',
        lineHeight: '28px'
    },
    leftButton: {
        padding: '14px 0px 0px 5px'
    },
    formLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '5px'
    },
    preRegRadioLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '3px'
    },
    navbarLink: {
        fontSize: '12px',
        marginRight: '10px',
        color: '#333'
    }
}

const premierStyle = {
    headerColor: {
        backgroundColor: '#ffffff',
        // borderBottom: '1px solid rgb(229, 229, 229)'
    },
    backForm: {
        backgroundColor: '#f8f8f8'
    },
    footerColor: {
        //borderTop: '1px solid rgb(229, 229, 229)',
        backgroundColor: '#434547',
        color: '#fff',
        width:'100%',
    },
    titleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    inputStyle: {
        paddingBottom: "5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    boldLabel: {
        fontWeight: 800
    },
    /*MARGIN*/
    cBody: {
        padding: '10px 20px',
        border: '1px solid #e5e5e5',
        //backgroundColor: '#f8f8f8'
    },
    cForm: {
        //padding: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px'
        //borderBottom: '1px solid #e5e5e5',
        //borderTop: '1px solid #e5e5e5'
    },
    wButton: {
        padding: '0 10px',
        lineHeight: '28px'
    },
    resendCodeButton: {
        padding: '0px',
        lineHeight: '28px'
    },
    leftButton: {
        padding: '14px 0px 0px 5px'
    },
    formLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '5px'
    },
    preRegRadioLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '3px'
    },
    navbarLink: {
        fontSize: '16px',
        // marginRight: '10px',
        color: '#333'
    }
}

const khyberStyle = {
    headerColor: {
        backgroundColor: '#ffffff',
        // borderBottom: '1px solid rgb(229, 229, 229)'
    },
    backForm: {
        backgroundColor: '#f8f8f8'
    },
    footerColor: {
        //borderTop: '1px solid rgb(229, 229, 229)',
        backgroundColor: '#434547',
        color: '#fff',
        width:'100%',
    },
    titleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    inputStyle: {
        paddingBottom: "5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    boldLabel: {
        fontWeight: 800
    },
    /*MARGIN*/
    cBody: {
        padding: '10px 20px',
        border: '1px solid #e5e5e5',
        //backgroundColor: '#f8f8f8'
    },
    cForm: {
        //padding: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px'
        //borderBottom: '1px solid #e5e5e5',
        //borderTop: '1px solid #e5e5e5'
    },
    wButton: {
        padding: '0 10px',
        lineHeight: '28px'
    },
    resendCodeButton: {
        padding: '0px',
        lineHeight: '28px'
    },
    leftButton: {
        padding: '14px 0px 0px 5px'
    },
    formLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '5px'
    },
    preRegRadioLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '3px'
    },
    navbarLink: {
        fontSize: '16px',
        // marginRight: '10px',
        color: '#333'
    }
}

const xushiStyle = {
    headerColor: {
        backgroundColor: '#ffffff',
        // borderBottom: '1px solid rgb(229, 229, 229)'
    },
    backForm: {
        backgroundColor: '#f8f8f8'
    },
    footerColor: {
        //borderTop: '1px solid rgb(229, 229, 229)',
        backgroundColor: '#434547',
        color: '#fff',
        width:'100%',
    },
    titleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    inputStyle: {
        paddingBottom: "5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    boldLabel: {
        fontWeight: 800
    },
    /*MARGIN*/
    cBody: {
        padding: '10px 20px',
        border: '1px solid #e5e5e5',
        //backgroundColor: '#f8f8f8'
    },
    cForm: {
        //padding: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px'
        //borderBottom: '1px solid #e5e5e5',
        //borderTop: '1px solid #e5e5e5'
    },
    wButton: {
        padding: '0 10px',
        lineHeight: '28px'
    },
    resendCodeButton: {
        padding: '0px',
        lineHeight: '28px'
    },
    leftButton: {
        padding: '14px 0px 0px 5px'
    },
    formLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '5px'
    },
    preRegRadioLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '3px'
    },
    navbarLink: {
        fontSize: '16px',
        // marginRight: '10px',
        color: '#333'
    }
}

const lighthouseStyle = {
    headerColor: {
        backgroundColor: '#ffffff',
        // borderBottom: '1px solid rgb(229, 229, 229)'
    },
    backForm: {
        backgroundColor: '#f8f8f8'
    },
    footerColor: {
        //borderTop: '1px solid rgb(229, 229, 229)',
        backgroundColor: '#434547',
        color: '#fff',
        width:'100%',
        
        
    },
    titleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    inputStyle: {
        paddingBottom: "5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    boldLabel: {
        fontWeight: 800
    },
    /*MARGIN*/
    cBody: {
        padding: '10px 20px',
        border: '1px solid #e5e5e5',
        //backgroundColor: '#f8f8f8'
    },
    cForm: {
        //padding: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px'
        //borderBottom: '1px solid #e5e5e5',
        //borderTop: '1px solid #e5e5e5'
    },
    wButton: {
        padding: '0 10px',
        lineHeight: '28px',
        marginTop: '33px'
    },
    resendCodeButton: {
        padding: '0px',
        lineHeight: '28px'
    },
    leftButton: {
        padding: '14px 0px 0px 5px'
    },
    formLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '5px'
    },
    preRegRadioLabel: {
        display: 'block',
        marginBottom: '1px',
        marginTop: '3px'
    },
    navbarLink: {
        fontSize: '16px',
        // marginRight: '10px',
        color: '#333'
    }
}

export const notFilledInputClass = "uk-input uk-form-danger";
export const regularInputClass = "uk-input";
export const notFilledSelectClass = "uk-select uk-form-danger";
export const regularSelectClass = "uk-select";