import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AppContext from '../../AppContext';
import { formatDate, formatStringToDate, formatValidInput } from '../../helper/FormatHelper';
import { getCurrentCompanyName, getDefaultMobileCountry } from '../../helper/PackageJsonHelper';
import { getResource } from '../../helper/ResourcesHelper';
import { validate } from '../../helper/ValidateHelper';
import { getLookUpTable } from '../../networking/Networking';
import { deleteE2EAttachment } from '../../networking/NetworkingAttachments';
import { deleteAttachment, getStatesByCountry } from '../../networking/NetworkingRegForm';
import AlertBox from '../shared/AlertBox';
import AttachmentsDialog from '../shared/AttachmentsDialog';
import DatePicker from '../shared/DatePicker';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';
import NewDynamicTable from '../shared/NewDynamicTable';

const today = new Date();
const defaultMobileCountry = getDefaultMobileCountry();
export default class ApplicantGeneralInformation extends React.Component {
    static contextType = AppContext;
    state = {
        FirstName: '',
        ShortName: '',
        LastName: '',
        DateOfBirth: '',
        ExpirationDate: '',
        ResidentialAddressStreet: '',
        ResidentialAddressLine2: '',
        ResidentialAddressCity: '',
        ResidentialAddressStateId: '',
        ResidentialAddressStateName: '',
        ResidentialAddressPostalCode: '',
        ResidentialPhoneNumber: '',
        EmailAddress: '',
        Citizenship: '',
        identificationNumber: '',
        IdentificationIssuingAgency: '',
        AffiliateReferredBy: '',
        OtherState: '',

        //Xushi
        MobileNumber: '',
        PEP: true,
        ReferredBy: false,
        ReferredByName: '',
        SourceOfIncomeOther: '',

        //ddwns values
        salutation: [],
        saludationID: '',
        saludationName: '',
        countryID: '',
        countryName: '',
        CitizenshipID: '',
        CitizenshipName: '',
        Occupation: '',
        identificationTypeID: '',
        identificationTypeName: '',

        countries: [],
        identificationType: [],
        documentTypeID: '',
        documentTypeIDName: '',
        documentTypeNumber: '',

        //RadioButtons
        YesState: getResource('Checkbox_Yes'),
        NoState: getResource('Checkbox_No'),
        FemaleState: getResource('Checkbox_Female'),
        MaleState: getResource('Checkbox_Male'),
        clickRadio: getResource('Checkbox_Yes'),
        clickRadioGender: 'none',

        //Validations
        validations: {
            DateOfBirth: true,
            ExpirationDate: true,
            EmailAddress: true,
            FirstName: true,
            LastName: true,
            ResidentialAddressCity: true,
            ResidentialAddressPostalCode: true,
            ResidentialAddressStateId: true,
            ResidentialAddressStreet: true,
            ResidentialPhoneNumber: true,
            Citizenship: true,
            clickRadio: true,
            clickRadioGender: true,
            countryID: true,
            saludationID: true,

            Occupation: true,
            identificationTypeID: true,
            identificationNumber: true,

            countryIDIdentification: true,
            countryIDDrivingLicense: true,
            countryIDPassport: true,
            numberIdentification: true,
            documentTypeID: true,

            //Xushi
            MobileNumber: true,
            RemitterName: true,
            RemitterContactNumber: true,
            RemitterRelationship: true,
            PEP: true,
            ReferredByName: true,
            sourceOfWealthID: true,
            SourceOfIncomeOther: true,
            CitizenshipID: true
        },
        documents: {
            personalIdentification1File: '',
            personalIdentification1FileName: '',
            personalIdentification2File: '',
            personalIdentification2FileName: '',
            drivingLicense1File: '',
            drivingLicense1FileName: '',
            drivingLicense2File: '',
            drivingLicense2FileName: '',
            passportFile: '',
            passportFileName: '',
            identityAdditionalFiles: [],
            drivingLicenseAdditionalFiles: [],
            passportAdditionalFiles: []
        },
        valid: true,

        TaskList: [],
        showDeleteAlert: false,
        statesByCountryID: [],
        employmentStatusID: '',
        employmentStatusName: '',
        employmentStatus: [],
        sourceOfWealthID: '',
        sourceOfWealthName: '',
        sourceOfWealth: [],
        occupationGroups: [],
        occupationDetails: [],

        //Attachments
        showAttachmentsDialogID1: false,
        showAttachmentsDialogID2: false,
        showAttachmentsDialogDL1: false,
        showAttachmentsDialogDL2: false,
        showAttachmentsDialogPS1: false,
        showAttachmentsDialogIDAdd: false,
        showAttachmentsDialogDLAdd: false,
        showAttachmentsDialogPSAdd: false,
        rowClickID1: false,
        rowClickID2: false,
        rowClickDL1: false,
        rowClickDL2: false,
        rowClickPS1: false,
        rowClickIDAdd: false,
        rowClickDLAdd: false,
        rowClickPSAdd: false,
        autoFocus: false
    };

    componentDidMount() {
        this.context.validated(this.validateRequiredInputs());
        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
        getLookUpTable('AFEX Occupation Groups').then(
            (json) => {
                if (json != null) {
                    const occupationGroups = json.lookUpTables[0].lookUpTableDetails.map(d => ({ ...d, name: d.LookUpTableDetail, value: d.MappingId }));
                    this.setState({ occupationGroups });
                }
            }
        );
        getLookUpTable('AFEX Occupation Details').then(
            (json) => {
                if (json != null) {
                    const occupationDetails = json.lookUpTables[0].lookUpTableDetails.map(d => ({ ...d, name: d.LookUpTableDetail, value: d.MappingId }));
                    this.setState({ occupationDetails });
                }
            }
        );
    }
    componentDidUpdate(prevProps) {
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
        if (prevProps.notFirstLoadPartial !== this.props.notFirstLoadPartial && !this.props.notFirstLoadPartial) {
            this.validateRequiredFieldsPartial();
        }
        if (prevProps.objApplicantGeneralInformation !== this.props.objApplicantGeneralInformation && this.props.objApplicantGeneralInformation != null) {
            this.setState({ ...this.props.objApplicantGeneralInformation }, () => {
                //this.searchState(this.state.countryID)
                this.context.validated(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                this.loadingDocumentType();
                this.renderDocuments();  
                this.props.ApplicantGeneralInformation(this.state)
            });
        }
    }

    //Document Type
    loadingDocumentType = () => {
        if (this.state.TaskList != null && this.state.TaskList.length > 0) {
            this.state.TaskList.forEach(value => {
                if(value.IdentificationID > 0 && value.DocumentType && value.Files && value.Files.length > 0)
                    {
                        this.switchDocumentType(value);
                        return true;
                    }
            });
        }
    
}

switchDocumentType = (value) => {
    let documentTypeID = '';
    switch (value.Title) {
        case 'Personal Identification document - 1':
            documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
            break;
        case 'Personal Identification document - 2':
            documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
            break;
        case 'Driving License Document - 1':
            documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
            break;
        case 'Driving License Document - 2':
            documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
            break;
        case 'Personal Passport Document - 1':
            documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
            break;
            case 'Personal Identification document - Add. Pages':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                this.setState({ addPI: value.Files, IdentificationID_PIAdd: value.IdentificationID });
                break;
            case 'Driving License Document - Add. Pages':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                this.setState({ addDL: value.Files, IdentificationID_DLAdd: value.IdentificationID });
                break;
            case 'Personal Passport Document - Add. Pages':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                this.setState({ addPss: value.Files, IdentificationID_PssAdd: value.IdentificationID });
                break;
        default:
            break;
    }
    if(this.state.documentTypeID == '' && documentTypeID != '')
    {
    this.setState({ documentTypeID: documentTypeID });
    }
}
//Loading Documents

renderDocuments = () => {
        if (this.state.TaskList != null && this.state.TaskList.length > 0) {
            this.switchDocuments();
        }
    
}

switchDocuments = () => {
    this.state.TaskList.forEach((value) => {
        switch (value.Title) {
            case 'Personal Identification document - 1':
                this.setState({ frontPI: value.Files, IdentificationID_frontPI: value.IdentificationID });
                break;
            case 'Personal Identification document - 2':
                this.setState({ endPI: value.Files, IdentificationID_endPI: value.IdentificationID });
                break;
            case 'Driving License Document - 1':
                this.setState({ frontDL: value.Files, IdentificationID_frontDL: value.IdentificationID });
                break;
            case 'Driving License Document - 2':
                this.setState({ endDL: value.Files, IdentificationID_endDL: value.IdentificationID });
                break;
            case 'Personal Passport Document - 1':
                this.setState({ frontPss: value.Files, IdentificationID_frontPss: value.IdentificationID });
                break;
            case 'Personal Identification document - Add. Pages':
                this.setState({ addPI: value.Files, IdentificationID_PIAdd: value.IdentificationID });
                break;
            case 'Driving License Document - Add. Pages':
                this.setState({ addDL: value.Files, IdentificationID_DLAdd: value.IdentificationID });
                break;
            case 'Personal Passport Document - Add. Pages':
                this.setState({ addPss: value.Files, IdentificationID_PssAdd: value.IdentificationID });
                break;
            default:
                break;
        }
    });
}

    handleUpdateSaludation = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        saludationID: value.value, saludationName: value.name,
                        validations: { ...this.state.validations, saludationID: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                } else {
                    this.setState({
                        saludationID: '', saludationName: ''
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                }
            }
        }
    }
    searchState = (countryID) => {
        if (countryID != null && countryID !== '') {
            getStatesByCountry(countryID, 0, 0).then(
                (value) => {
                    if (value.states) {
                        const states = value.states.map(c => ({ ...c, name: c["State Name"], value: c["State Id"] }));
                        this.setState({ statesByCountryID: states });
                    } else {
                        this.setState({ statesByCountryID: [] });
                    }
                }
            );
        }
    }
    handleUpdateCountry = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        countryID: value.value, countryName: value.name,
                        validations: { ...this.state.validations, countryID: true }
                    }, () => {
                        //this.searchState(value.value)
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                } else {
                    this.setState({
                        countryID: '', countryName: ''
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                }
            }
        }
    }
    handleUpdateFirstName = (event) => {
        this.setState({
            FirstName: event.target.value,
            validations: { ...this.state.validations, FirstName: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateShortName = (event) => {
        this.setState({
            ShortName: event.target.value,
            validations: { ...this.state.validations, ShortName: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateLastName = (event) => {
        this.setState({
            LastName: event.target.value,
            validations: { ...this.state.validations, LastName: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }

    handleAffiliateReferredBy = (event) => {
        this.setState({
            AffiliateReferredBy: event.target.value,
            validations: { ...this.state.validations, AffiliateReferredBy: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    };
    
    handleUpdateDateOfBirth = (date) => {
        this.setState({
            DateOfBirth: formatDate(date),
            validations: { ...this.state.validations, DateOfBirth: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateExpirationDate = (date) => {
        this.setState({
            ExpirationDate: formatDate(date),
            validations: { ...this.state.validations, ExpirationDate: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateResidentialAddressStreet = (event) => {
        this.setState({
            ResidentialAddressStreet: event.target.value,
            validations: { ...this.state.validations, ResidentialAddressStreet: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateResidentialAddressLine2 = (event) => {
        this.setState({
            ResidentialAddressLine2: event.target.value,
            validations: { ...this.state.validations, ResidentialAddressLine2: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateResidentialAddressCity = (event) => {
        this.setState({
            ResidentialAddressCity: event.target.value,
            validations: { ...this.state.validations, ResidentialAddressCity: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateResidentialAddressState = (value) => {
        if (value != null && value !== 'select') {
            // debugger;
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        ResidentialAddressStateId: value.value, ResidentialAddressStateName: value.name, OtherState: '',
                        validations: { ...this.state.validations, ResidentialAddressStateId: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                } else {
                    this.setState({
                        ResidentialAddressStateId: '', ResidentialAddressStateName: '',
                        validations: { ...this.state.validations, ResidentialAddressStateId: false }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                }
            }
        }
    }
    handleUpdateResidentialAddressPostalCode = (event) => {
        this.setState({
            ResidentialAddressPostalCode: event.target.value,
            validations: { ...this.state.validations, ResidentialAddressPostalCode: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateResidentialPhoneNumber = (phone, country) => {
        debugger
        this.switchFunctionPhone(phone, country.dialCode,'ResidentialPhoneNumber');
    }

    switchFunctionPhone = (value, countryCode, label) => {
        var labelCode = 'Code'+label;
        this.setState({
            [label]: (value === countryCode ? '' : value),
            [labelCode]: '+'+countryCode,
            validations: { ...this.state.validations, [label]: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1);
            this.setState({[label]: '+'+value}, () =>
            {
                this.props.ApplicantGeneralInformation(this.state)
            })
            
        });
    }

    handleUpdateMobileNumber = (phone, country) => {
        this.switchFunctionPhone(phone, country.dialCode,'MobileNumber');
    };

    handleUpdateEmailAddress = (event) => {
        this.setState({
            EmailAddress: event.target.value,
            validations: { ...this.state.validations, EmailAddress: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateCitizenship = (value) => {
        /* this.setState({
            CitizenshipID: event.target.value,
            validations: { ...this.state.validations, CitizenshipID: true }
        }, () => {
            //this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        }); */
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        CitizenshipID: value.value, CitizenshipName: value.name,
                        validations: { ...this.state.validations, CitizenshipID: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                } else {
                    this.setState({
                        CitizenshipID: '', CitizenshipName: ''
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                }
            }
        }
    }

    handleUpdateRemitterName = (event) => {
        this.setState({
            RemitterName: event.target.value,
            validations: { ...this.state.validations, RemitterName: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    };

    handleUpdateRemitterContactNumber = (event) => {
        this.setState({
            RemitterContactNumber: event.target.value,
            validations: { ...this.state.validations, RemitterContactNumber: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    };

    handleUpdateRemitterRelationship = (event) => {
        this.setState({
            RemitterRelationship: event.target.value,
            validations: { ...this.state.validations, RemitterRelationship: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    };

    handleUpdatePEP = (event) => {
        this.setState({
            PEP: !event.target.checked,
            validations: { ...this.state.validations, PEP: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    };

    handleUpdateReferredBy = (event) => {
        this.setState({
            ReferredBy: event.target.checked,
            ReferredByName: '',
            validations: { ...this.state.validations, ReferredByName: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    };

    handleUpdateReferredByName = (event) => {
        this.setState({
            ReferredByName: event.target.value,
            validations: { ...this.state.validations, ReferredByName: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    };

    handleUpdateSourceOfIncomeOther = (event) => {
        this.setState({
            SourceOfIncomeOther: event.target.value,
            validations: { ...this.state.validations, SourceOfIncomeOther: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    };

    handleUpdateEmploymentStatus = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        employmentStatusID: value.value, employmentStatusName: value.name,
                        validations: { ...this.state.validations, employmentStatusID: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                } else {
                    this.setState({
                        employmentStatusID: '', employmentStatusName: ''
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                }
            }
        }
    }
    handleUpdateOccupation = (event) => {
        this.setState({
            Occupation: event.target.value,
            validations: { ...this.state.validations, Occupation: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateSourceOfWealth = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        sourceOfWealthID: value.value, sourceOfWealthName: value.name,
                        validations: { ...this.state.validations, sourceOfWealthID: true, SourceOfIncomeOther: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                } else {
                    this.setState({
                        sourceOfWealthID: '', sourceOfWealthName: '', SourceOfIncomeOther: ''
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                }
            }
        }
    }
    handleUpdateIdentificationType = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        identificationTypeID: value.value, identificationTypeName: value.name,
                        validations: { ...this.state.validations, identificationTypeID: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                } else {
                    this.setState({
                        identificationTypeID: '', identificationTypeName: ''
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                }
            }
        }
    }
    handleUpdateIdentificationNumber = (event) => {
        this.setState({
            identificationNumber: event.target.value,
            validations: { ...this.state.validations, identificationNumber: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    handleUpdateIdentificationIssuingAgency = (event) => {
        this.setState({
            IdentificationIssuingAgency: event.target.value,
            validations: { ...this.state.validations, IdentificationIssuingAgency: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    ageVerification = () => {
        const dateOfBirth = formatStringToDate(this.state.DateOfBirth);
        //const today = new Date();
        let age = today.getFullYear() - dateOfBirth.getFullYear();
        const m = today.getMonth() - dateOfBirth.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
            age--;
        }
        return age >= 18;
    }
    //Inputs Validations
    validateRequiredInputs() {
        let fields = [
            { displayName: getResource('Label_FirstName'), validateName: 'FirstName', value: this.state.FirstName, type: 'string' },
            { displayName: getResource('Label_LastName'), validateName: 'LastName', value: this.state.LastName, type: 'string' },
            { displayName: getResource('Label_DateOfBirth'), validateName: 'DateOfBirth', value: this.state.DateOfBirth, type: 'string' },
            //{ displayName: getResource('Label_Gender'), validateName: 'clickRadioGender', value: this.state.clickRadioGender, type: 'radio' },
            { displayName: getResource('Label_StreetAddress'), validateName: 'ResidentialAddressStreet', value: this.state.ResidentialAddressStreet, type: 'string' },
            { displayName: getResource('Label_City'), validateName: 'ResidentialAddressCity', value: this.state.ResidentialAddressCity, type: 'string' },
            { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
            { displayName: getResource('Label_State'), validateName: 'OtherState', value: this.state.OtherState, type: 'string' },
            { displayName: getResource('Label_Postal'), validateName: 'ResidentialAddressPostalCode', value: this.state.ResidentialAddressPostalCode, type: 'string' },

            { displayName: getResource('Label_Email'), validateName: 'EmailAddress', value: this.state.EmailAddress, type: 'email' },

            // { displayName: getResource('Label_DocumentType'), validateName: 'documentTypeID', value: this.state.documentTypeID, type: 'string' },
            { displayName: getResource('Label_Country') + ' identification', validateName: 'countryIDIdentification', value: this.state.countryIDIdentification, type: 'string' }
        ]
        // if (this.state.statesByCountryID.length > 0) {
        //     fields.push({ displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state.ResidentialAddressStateId, type: 'string' });
        // } else {
        //     fields.push({ displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state.OtherState, type: 'string' });
        // }

        if(this.state.CodeMobileNumber === this.state.MobileNumber || this.state.MobileNumber.length === 0)
        {
            fields.push({ displayName: getResource('Label_MobileNumber')/* + ' 0' + trader*/, validateName: 'MobileNumber' , value: '', type: 'string' });
        }

        if (this.state.DateOfBirth !== '') {
            fields.push({ displayName: getResource('Label_DateOfBirth') + ': Must be older than 18 years', validation: this.ageVerification });
        }

        if (getCurrentCompanyName() === 'Xushi') {
            fields.push({ displayName: getResource('Label_Salutation'), validateName: 'saludationID', value: this.state.saludationID, type: 'string' });
            fields.push({ displayName: getResource('Label_MainContactNumber'), validateName: 'ResidentialPhoneNumber', value: this.state.ResidentialPhoneNumber, type: 'string' });
            
            if (this.state.ReferredBy) {
                fields.push({ displayName: getResource('Label_ReferredByName'), validateName: 'ReferredByName', value: this.state.ReferredByName, type: 'string' });
            }
            if (this.state.sourceOfWealthID === 'Other') {
                fields.push({ displayName: getResource('Label_SourceOfIncomeOther'), validateName: 'SourceOfIncomeOther', value: this.state.SourceOfIncomeOther, type: 'string' });
            }
        }
        //----------------Documents: Identity Card----------------
        // let document1 = false, document2 = false;
        // if (this.state.TaskList.length > 0) {
        //     this.state.TaskList.forEach(value => {
        //         if (value.Title.includes('document - 1') || value.Title.includes('Document - 1')) {
        //             document1 = true;
        //         } else if (value.Title.includes('document - 2') || value.Title.includes('Document - 2')) {
        //             document2 = true;
        //         }
        //     });
        // }
        // if (this.state.documentTypeNumber !== '') {
        //     if (this.state.documentTypeNumber.includes('Identity')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'numberIdentification', value: this.state.numberIdentification, type: 'wholeNumber' }
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Driving')) {
        //         fields.push(
        //             { displayName: getResource('Label_LicenseNumber'), validateName: 'numberIdentification', value: this.state.DriverLicenseNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Passport')) {
        //         fields.push(
        //             { displayName: getResource('Label_PassportNumber'), validateName: 'numberIdentification', value: this.state.PassportNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1) {
        //             fields.push(
        //                 { displayName: 'Attachment Passport', validateName: 'passportFile', value: this.state.documents.passportFile, type: 'string' }
        //             );
        //         }
        //     }
        // }
        const [valid, , errors] = validate(fields);
        return { valid, errors };
    }

    //Fields Validations
    validateRequiredFields() {
        let fields = [
            { displayName: getResource('Label_FirstName'), validateName: 'FirstName', value: this.state.FirstName, type: 'string' },
            { displayName: getResource('Label_LastName'), validateName: 'LastName', value: this.state.LastName, type: 'string' },
            { displayName: getResource('Label_DateOfBirth'), validateName: 'DateOfBirth', value: this.state.DateOfBirth, type: 'string' },
            //{ displayName: getResource('Label_Gender'), validateName: 'clickRadioGender', value: this.state.clickRadioGender, type: 'radio' },
            { displayName: getResource('Label_StreetAddress'), validateName: 'ResidentialAddressStreet', value: this.state.ResidentialAddressStreet, type: 'string' },
            { displayName: getResource('Label_City'), validateName: 'ResidentialAddressCity', value: this.state.ResidentialAddressCity, type: 'string' },
            { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
            //{ displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state.ResidentialAddressStateId, type: 'string' },
            { displayName: getResource('Label_Postal'), validateName: 'ResidentialAddressPostalCode', value: this.state.ResidentialAddressPostalCode, type: 'string' },
           // { displayName: getResource('Label_MobileNumber'), validateName: 'MobileNumber', value: this.state.MobileNumber, type: 'string' },
            { displayName: getResource('Label_Email'), validateName: 'EmailAddress', value: this.state.EmailAddress, type: 'email' },

            // { displayName: getResource('Label_DocumentType'), validateName: 'documentTypeID', value: this.state.documentTypeID, type: 'string' },
            { displayName: getResource('Label_Country') + ' identification', validateName: 'countryIDIdentification', value: this.state.countryIDIdentification, type: 'string' }
        ]
         if (this.state.statesByCountryID.length > 0) {
             fields.push({ displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state.ResidentialAddressStateId, type: 'string' });
         } else {
             fields.push({ displayName: getResource('Label_State'), validateName: 'OtherState', value: this.state.OtherState, type: 'string' });
         }
        if (this.state.DateOfBirth !== '') {
            fields.push({ displayName: getResource('Label_DateOfBirth') + ': Must be older than 18 years', validation: this.ageVerification });
        }

        if(this.state.CodeMobileNumber === this.state.MobileNumber || this.state.MobileNumber.length === 0)
        {
            fields.push({ displayName: getResource('Label_MobileNumber')/* + ' 0' + trader*/, validateName: 'MobileNumber' , value: '', type: 'string' });
        }
        
        if (getCurrentCompanyName() === 'Xushi') {
            fields.push({ displayName: getResource('Label_Salutation'), validateName: 'saludationID', value: this.state.saludationID, type: 'string' });
            fields.push({ displayName: getResource('Label_MainContactNumber'), validateName: 'ResidentialPhoneNumber', value: this.state.ResidentialPhoneNumber, type: 'string' });
            if (this.state.ReferredBy) {
                fields.push({ displayName: getResource('Label_ReferredByName'), validateName: 'ReferredByName', value: this.state.ReferredByName, type: 'string' });
            }
            if (this.state.sourceOfWealthID === 'Other') {
                fields.push({ displayName: getResource('Label_SourceOfIncomeOther'), validateName: 'SourceOfIncomeOther', value: this.state.SourceOfIncomeOther, type: 'string' });
            }
        }
        //----------------Documents: Identity Card----------------
        // let document1 = false, document2 = false;
        // if (this.state.TaskList.length > 0) {
        //     this.state.TaskList.forEach(value => {
        //         if (value.Title.includes('document - 1') || value.Title.includes('Document - 1')) {
        //             document1 = true;
        //         } else if (value.Title.includes('document - 2') || value.Title.includes('Document - 2')) {
        //             document2 = true;
        //         }
        //     });
        // }
        // if (this.state.documentTypeNumber !== '') {
        //     if (this.state.documentTypeNumber.includes('Identity')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'numberIdentification', value: this.state.numberIdentification, type: 'wholeNumber' }
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Driving')) {
        //         fields.push(
        //             { displayName: getResource('Label_LicenseNumber'), validateName: 'numberIdentification', value: this.state.DriverLicenseNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Passport')) {
        //         fields.push(
        //             { displayName: getResource('Label_PassportNumber'), validateName: 'numberIdentification', value: this.state.PassportNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1) {
        //             fields.push(
        //                 { displayName: 'Attachment Passport', validateName: 'passportFile', value: this.state.documents.passportFile, type: 'string' }
        //             );
        //         }
        //     }
        // }
        const [valid, validations] = validate(fields);
        this.setState({ validations });
        return valid;
    }
    //Inputs Validations
    validateRequiredInputsPartial() {
        let fields = [
            { displayName: getResource('Label_FirstName'), validateName: 'FirstName', value: this.state.FirstName, type: 'string' },
            { displayName: getResource('Label_LastName'), validateName: 'LastName', value: this.state.LastName, type: 'string' },
           // { displayName: getResource('Label_MobileNumber'), validateName: 'MobileNumber', value: this.state.MobileNumber, type: 'string' },
        ]
        if (this.state.DateOfBirth !== '') {
            fields.push({ displayName: getResource('Label_DateOfBirth') + ': Must be older than 18 years', validation: this.ageVerification });
        }

        if(this.state.CodeMobileNumber === this.state.MobileNumber || this.state.MobileNumber.length === 0)
        {
            fields.push({ displayName: getResource('Label_MobileNumber')/* + ' 0' + trader*/, validateName: 'MobileNumber' , value: '', type: 'string' });
        }

        if (this.state.statesByCountryID.length > 0) {
            if (
                this.state.ResidentialAddressStreet !== '' ||
                this.state.ResidentialAddressCity !== '' ||
                this.state.countryID !== '' ||
                this.state.ResidentialAddressStateId !== ''
            ) {
                fields.push(
                    { displayName: getResource('Label_StreetAddress'), validateName: 'ResidentialAddressStreet', value: this.state.ResidentialAddressStreet, type: 'string' },
                    { displayName: getResource('Label_City'), validateName: 'ResidentialAddressCity', value: this.state.ResidentialAddressCity, type: 'string' },
                    { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
                    { displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state.ResidentialAddressStateId, type: 'string' },
                );
            }
        } else {
            if (
                this.state.ResidentialAddressStreet !== '' ||
                this.state.ResidentialAddressCity !== '' ||
                this.state.countryID !== '' ||
                this.state.OtherState !== ''
            ) {
                fields.push(
                    { displayName: getResource('Label_StreetAddress'), validateName: 'ResidentialAddressStreet', value: this.state.ResidentialAddressStreet, type: 'string' },
                    { displayName: getResource('Label_City'), validateName: 'ResidentialAddressCity', value: this.state.ResidentialAddressCity, type: 'string' },
                    { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
                    { displayName: getResource('Label_State'), validateName: 'OtherState', value: this.state.OtherState, type: 'string' }
                );
            }
        }

        //----------------Documents: Identity Card----------------
        // let document1 = false, document2 = false;
        // if (this.state.TaskList.length > 0) {
        //     this.state.TaskList.forEach(value => {
        //         if (value.Title.includes('document - 1') || value.Title.includes('Document - 1')) {
        //             document1 = true;
        //         } else if (value.Title.includes('document - 2') || value.Title.includes('Document - 2')) {
        //             document2 = true;
        //         }
        //     });
        // }
        // if (this.state.countryIDIdentification !== '') {
        //     fields.push(
        //         { displayName: getResource('Label_DocumentType'), validateName: 'documentTypeID', value: this.state.documentTypeID, type: 'string' },
        //     );
        // }
        // if (this.state.documentTypeNumber !== '') {
        //     if (this.state.documentTypeNumber.includes('Identity')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'numberIdentification', value: this.state.numberIdentification, type: 'wholeNumber' }
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Driving')) {
        //         fields.push(
        //             { displayName: getResource('Label_LicenseNumber'), validateName: 'numberIdentification', value: this.state.DriverLicenseNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Passport')) {
        //         fields.push(
        //             { displayName: getResource('Label_PassportNumber'), validateName: 'numberIdentification', value: this.state.PassportNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1) {
        //             fields.push(
        //                 { displayName: 'Attachment Passport', validateName: 'passportFile', value: this.state.documents.passportFile, type: 'string' }
        //             );
        //         }
        //     }
        // }
        const [valid, , errors] = validate(fields);
        return { valid, errors };
    }

    //Fields Validations
    validateRequiredFieldsPartial() {
        let fields = [
            { displayName: getResource('Label_FirstName'), validateName: 'FirstName', value: this.state.FirstName, type: 'string' },
            { displayName: getResource('Label_LastName'), validateName: 'LastName', value: this.state.LastName, type: 'string' },
            //{ displayName: getResource('Label_MobileNumber'), validateName: 'MobileNumber', value: this.state.MobileNumber, type: 'string' },
        ]
        if (this.state.DateOfBirth !== '') {
            fields.push({ displayName: getResource('Label_DateOfBirth') + ': Must be older than 18 years', validation: this.ageVerification });
        }

        if(this.state.CodeMobileNumber === this.state.MobileNumber || this.state.MobileNumber.length === 0)
        {
            fields.push({ displayName: getResource('Label_MobileNumber')/* + ' 0' + trader*/, validateName: 'MobileNumber' , value: '', type: 'string' });
        }

        if (this.state.statesByCountryID.length > 0) {
            if (
                this.state.ResidentialAddressStreet !== '' ||
                this.state.ResidentialAddressCity !== '' ||
                this.state.countryID !== '' ||
                this.state.ResidentialAddressStateId !== ''
            ) {
                fields.push(
                    { displayName: getResource('Label_StreetAddress'), validateName: 'ResidentialAddressStreet', value: this.state.ResidentialAddressStreet, type: 'string' },
                    { displayName: getResource('Label_City'), validateName: 'ResidentialAddressCity', value: this.state.ResidentialAddressCity, type: 'string' },
                    { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
                    { displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state.ResidentialAddressStateId, type: 'string' },
                );
            }
        } else {
            if (
                this.state.ResidentialAddressStreet !== '' ||
                this.state.ResidentialAddressCity !== '' ||
                this.state.countryID !== '' ||
                this.state.OtherState !== ''
            ) {
                fields.push(
                    { displayName: getResource('Label_StreetAddress'), validateName: 'ResidentialAddressStreet', value: this.state.ResidentialAddressStreet, type: 'string' },
                    { displayName: getResource('Label_City'), validateName: 'ResidentialAddressCity', value: this.state.ResidentialAddressCity, type: 'string' },
                    { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
                    { displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state.OtherState, type: 'string' }
                );
            }
        }
        const [valid, validations] = validate(fields);
        this.setState({ validations });
        return valid;
    }
    handleUpdateState = (event) => {
        this.setState({
            validations: { ...this.state.validations, ResidentialAddressStateId: true },
            OtherState: event.target.value, ResidentialAddressStateId: ''
        },
            () => {
                this.context.validated(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                this.props.ApplicantGeneralInformation(this.state)
            }
        );
    }

    handleUpdateCountryIdentification = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        countryIDIdentification: value.value, countryNameIdentification: value.name,
                        validations: { ...this.state.validations, countryIDIdentification: true }
                    }, () => {
                        //this.searchState(value.value, true);
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                } else {
                    this.setState({
                        countryIDIdentification: '', countryNameIdentification: ''
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                }
            }
        }
    }

      //RowClickTable
      handleRowClickID1 = () => {
        this.setState({ showAttachmentsDialogID1: true, rowClickID1: true })
    }
    showAttachmentsDialogID1 = () => {
        this.setState({ showAttachmentsDialogID1: false })
    }
    changeStateID1 = () => {
        this.setState({ rowClickID1: false })
    }
    handleRowClickID2 = () => {
        this.setState({ showAttachmentsDialogID2: true, rowClickID2: true })
    }
    showAttachmentsDialogID2 = () => {
        this.setState({ showAttachmentsDialogID2: false })
    }
    changeStateID2 = () => {
        this.setState({ rowClickID2: false })
    }
    handleRowClickDL1 = () => {
        this.setState({ showAttachmentsDialogDL1: true, rowClickDL1: true })
    }
    showAttachmentsDialogDL1 = () => {
        this.setState({ showAttachmentsDialogDL1: false })
    }
    changeStateDL1 = () => {
        this.setState({ rowClickDL1: false })
    }
    handleRowClickDL2 = () => {
        this.setState({ showAttachmentsDialogDL2: true, rowClickDL2: true })
    }
    showAttachmentsDialogDL2 = () => {
        this.setState({ showAttachmentsDialogDL2: false })
    }
    changeStateDL2 = () => {
        this.setState({ rowClickDL2: false })
    }
    handleRowClickPS1 = () => {
        this.setState({ showAttachmentsDialogPS1: true, rowClickPS1: true })
    }
    showAttachmentsDialogPS1 = () => {
        this.setState({ showAttachmentsDialogPS1: false })
    }
    changeStatePS1 = () => {
        this.setState({ rowClickPS1: false })
    }
    changeStateIDAdd = () => {
        this.setState({ rowClickID1: false })
    }
    handleRowClickIDAdd = () => {
        this.setState({ showAttachmentsDialogIDAdd: true, rowClickIDAdd: true })
    }
    showAttachmentsDialogIDAdd = () => {
        this.setState({ showAttachmentsDialogIDAdd: false })
    }
    changeStateDLAdd = () => {
        this.setState({ rowClickIDAdd: false })
    }
    handleRowClickDLAdd = () => {
        this.setState({ showAttachmentsDialogDLAdd: true, rowClickDLAdd: true })
    }
    showAttachmentsDialogDLAdd = () => {
        this.setState({ showAttachmentsDialogDLAdd: false })
    }
    changeStatePSAdd = () => {
        this.setState({ rowClickPSAdd: false })
    }
    handleRowClickPSAdd = () => {
        this.setState({ showAttachmentsDialogPSAdd: true, rowClickPSAdd: true })
    }
    showAttachmentsDialogPSAdd = () => {
        this.setState({ showAttachmentsDialogPSAdd: false })
    }

    handleUpdateDocumentType = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        documentTypeID: value.value, documentTypeIDName: value.name, documentTypeNumber: value.name,
                        validations: {
                            ...this.state.validations,
                            documentTypeID: true,
                            countryIDIdentification: true,
                            countryIDDrivingLicense: true,
                            countryIDPassport: true,
                            DriverLicenseNumber: true,
                            PassportNumber: true,
                            numberIdentification: true
                        }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                } else {
                    this.setState({
                        documentTypeID: '', documentTypeIDName: '', documentTypeNumber: ''
                    }, () => {
                        this.context.validated(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                        this.props.ApplicantGeneralInformation(this.state)
                    });
                }
            }
        }
    }
    handleUpdateNumberIdentification = (event) => {
        this.setState({
            numberIdentification: event.target.value,
            validations: { ...this.state.validations, numberIdentification: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }

    handleChangeID1File = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, personalIdentification1File: fileContent, personalIdentification1FileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        } else {
            const newDocuments = { ...this.state.documents, personalIdentification1File: '', personalIdentification1FileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        }
    }

    handleChangeID2File = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, personalIdentification2File: fileContent, personalIdentification2FileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        } else {
            const newDocuments = { ...this.state.documents, personalIdentification2File: '', personalIdentification2FileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        }
    }

    handleIdentityAdditionalFiles = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newFile = {
                FileContent: fileContent,
                FileName: file.name
            };
            // Validate file duplicates
            if (!this.state.documents.identityAdditionalFiles.find((_file) => _file.FileName === file.name)) {
                const newDocuments = { ...this.state.documents, identityAdditionalFiles: [...this.state.documents.identityAdditionalFiles, ...[newFile]] };
                this.setState({ documents: newDocuments });
                this.context.validated(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                this.props.ApplicantGeneralInformation(this.state)
            }
        } else {
            const newDocuments = { ...this.state.documents, identityAdditionalFiles: [] };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        }
    };

    clearIdentityAdditionalFiles = async () => {
        const newDocuments = { ...this.state.documents, identityAdditionalFiles: [] };
        this.setState({ documents: newDocuments });
        this.context.validated(this.validateRequiredInputs())
        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
        this.props.ApplicantGeneralInformation(this.state)
    };

    handleChangeDL1File = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, drivingLicense1File: fileContent, drivingLicense1FileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        } else {
            const newDocuments = { ...this.state.documents, drivingLicense1File: '', drivingLicense1FileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        }
    }

    handleChangeDL2File = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, drivingLicense2File: fileContent, drivingLicense2FileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        } else {
            const newDocuments = { ...this.state.documents, drivingLicense2File: '', drivingLicense2FileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        }
    }

    handleDrivingLicenseAdditionalFiles = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newFile = {
                FileContent: fileContent,
                FileName: file.name
            };
            // Validate file duplicates
            if (!this.state.documents.drivingLicenseAdditionalFiles.find((_file) => _file.FileName === file.name)) {
                const newDocuments = { ...this.state.documents, drivingLicenseAdditionalFiles: [...this.state.documents.drivingLicenseAdditionalFiles, ...[newFile]] };
                this.setState({ documents: newDocuments });
                this.context.validated(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                this.props.ApplicantGeneralInformation(this.state)
            }
        } else {
            const newDocuments = { ...this.state.documents, drivingLicenseAdditionalFiles: [] };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        }
    };

    clearDrivingLicenseAdditionalFiles = async () => {
        const newDocuments = { ...this.state.documents, drivingLicenseAdditionalFiles: [] };
        this.setState({ documents: newDocuments });
        this.context.validated(this.validateRequiredInputs())
        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
        this.props.ApplicantGeneralInformation(this.state)
    };

    handleChangePSSFile = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, passportFile: fileContent, passportFileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        } else {
            const newDocuments = { ...this.state.documents, passportFile: '', passportFileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        }
    }

    handlePassportAdditionalFiles = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newFile = {
                FileContent: fileContent,
                FileName: file.name
            };
            // Validate file duplicates
            if (!this.state.documents.passportAdditionalFiles.find((_file) => _file.FileName === file.name)) {
                const newDocuments = { ...this.state.documents, passportAdditionalFiles: [...this.state.documents.passportAdditionalFiles, ...[newFile]] };
                this.setState({ documents: newDocuments });
                this.context.validated(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                this.props.ApplicantGeneralInformation(this.state)
            }
        } else {
            const newDocuments = { ...this.state.documents, passportAdditionalFiles: [] };
            this.setState({ documents: newDocuments });
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        }
    };

    clearPassportAdditionalFiles = async () => {
        const newDocuments = { ...this.state.documents, passportAdditionalFiles: [] };
        this.setState({ documents: newDocuments });
        this.context.validated(this.validateRequiredInputs())
        this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
        this.props.ApplicantGeneralInformation(this.state)
    };

    readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    }

    deleteAttachmentClick(type, obj) {
        this.setState({ showDeleteAlert: true, readyToDeleteAttachment: obj, type });
    }

    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteAttachment: {} });
    }

    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }

    deleteE2EAttach = (personIdentificationID, attachmentID, onlyAttachment) => {
        if(onlyAttachment)
        {
            deleteE2EAttachment(attachmentID).then(
                (jsonResponse) => {
                    this.setState({ jsonResponse }) 
                });
        }
        else
        {
            deleteAttachment(personIdentificationID).then((jsonResponse) => { this.setState({ jsonResponse }) });
        }
    }

    yesDeleteAlert() {
        let frontPI = this.state.frontPI;
        let endPI = this.state.endPI;
        let frontDL = this.state.frontDL;
        let endDL = this.state.endDL;
        let frontPss = this.state.frontPss;
        let addPI = this.state.addPI;
        let addDL = this.state.addDL;
        let addPss = this.state.addPss;
        switch (this.state.type) {
            case 'frontPI':
                if (frontPI != null && frontPI !== '') {
                    if (frontPI[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttach(this.state.IdentificationID_frontPI);
                        this.deleteE2EAttach(this.state.IdentificationID_endPI);
                        this.setState({ frontPI: '', endPI: '', showDeleteAlert: false, }, () => {
                            this.context.validated(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                            this.props.ApplicantGeneralInformation(this.state)
                        });
                    }
                }
                break;
            case 'endPI':
                if (endPI != null && endPI !== '') {
                    if (endPI[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttach(this.state.IdentificationID_frontPI);
                        this.deleteE2EAttach(this.state.IdentificationID_endPI);
                        this.setState({ frontPI: '', endPI: '', showDeleteAlert: false, }, () => {
                            this.context.validated(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                            this.props.ApplicantGeneralInformation(this.state)
                        });
                    }
                }
                break;

            case 'frontDL':
                if (frontDL != null && frontDL !== '') {
                    if (frontDL[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttach(this.state.IdentificationID_frontDL);
                        this.deleteE2EAttach(this.state.IdentificationID_endDL);
                        this.setState({ frontDL: '', endDL: '', showDeleteAlert: false, }, () => {
                            this.context.validated(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                            this.props.ApplicantGeneralInformation(this.state)
                        });
                    }
                }
                break;
            case 'endDL':
                if (endDL != null && endDL !== '') {
                    if (endDL[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttach(this.state.IdentificationID_frontDL);
                        this.deleteE2EAttach(this.state.IdentificationID_endDL);
                        this.setState({ frontDL: '', endDL: '', showDeleteAlert: false, }, () => {
                            this.context.validated(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                            this.props.ApplicantGeneralInformation(this.state)
                        });
                    }
                }
                break;
            case 'frontPss':
                if (frontPss != null && frontPss !== '') {
                    if (frontPss[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttach(this.state.IdentificationID_frontPss);
                        this.setState({ frontPss: '', showDeleteAlert: false, }, () => {
                            this.context.validated(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                            this.props.ApplicantGeneralInformation(this.state)
                        });
                    }
                }
                break;
            case 'addPI':
                if (addPI != null && addPI !== '') {
                    if (addPI && addPI.some((attachment) => attachment.AttachmentId === this.state.readyToDeleteAttachment.AttachmentId)) {
                        if(addPI.length === 1 )
                        {
                            this.deleteE2EAttach(this.state.IdentificationID_PIAdd,'',false);
                            this.setState({ addPI: '', showDeleteAlert: false, }, () => {
                                this.context.validated(this.validateRequiredInputs())
                                this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                                this.props.ApplicantGeneralInformation(this.state)
                            });
                        }
                        else
                        {
                            this.deleteE2EAttach('',this.state.readyToDeleteAttachment.AttachmentId,true);
                            let aux = this.state.addPI.filter((attachment) => attachment.AttachmentId !==this.state.readyToDeleteAttachment.AttachmentId);
                        this.setState({ addPI: aux, showDeleteAlert: false, }, () => {
                            this.context.validated(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                            this.props.ApplicantGeneralInformation(this.state)
                        });
                    }
                        
                    }
                }
                break;
            case 'addDL':
                if (addDL != null && addDL !== '') {
                    if (addDL && addDL.some((attachment) => attachment.AttachmentId === this.state.readyToDeleteAttachment.AttachmentId)) {
                        if(addDL.length === 1 )
                        {
                        this.deleteE2EAttach(this.state.IdentificationID_DLAdd, '', false);
                        this.setState({ addDL: '', showDeleteAlert: false, }, () => {
                            this.context.validated(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                            this.props.ApplicantGeneralInformation(this.state)
                        });
                    }
                    else
                    {
                        this.deleteE2EAttach('', this.state.readyToDeleteAttachment.AttachmentId, true);
                        let aux = this.state.addDL.filter((attachment) => attachment.AttachmentId !==this.state.readyToDeleteAttachment.AttachmentId);
                        this.setState({ addDL:aux, showDeleteAlert: false, }, () => {
                            this.context.validated(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                            this.props.ApplicantGeneralInformation(this.state)
                        });
                    }
                    }
                }
                break;
            case 'addPss':
                if (addPss != null && addPss !== '') {
                    if (addPss && addPss.some((attachment) => attachment.AttachmentId === this.state.readyToDeleteAttachment.AttachmentId)) {
                        if(addPss.length === 1)
                        {
                            this.deleteE2EAttach(this.state.IdentificationID_PssAdd,'', true);
                            this.setState({ addPss: '', showDeleteAlert: false, }, () => {
                                this.context.validated(this.validateRequiredInputs())
                                this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                                this.props.ApplicantGeneralInformation(this.state)
                            });
                        }
                        else
                        {
                            this.deleteE2EAttach('', this.state.readyToDeleteAttachment.AttachmentId, true);
                            let aux = this.state.addPss.filter((attachment) => attachment.AttachmentId !==this.state.readyToDeleteAttachment.AttachmentId);
                            this.setState({ addPss: aux, showDeleteAlert: false, }, () => {
                                this.context.validated(this.validateRequiredInputs())
                                this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
                                this.props.ApplicantGeneralInformation(this.state)
                            });
                        }
                    }
                }
                break;
            default:
                break;
        }
    }

    handleUpdateDriverLicenseNumber = (event) => {
        this.setState({
            DriverLicenseNumber: event.target.value,
            //validations: { ...this.state.validations, DriverLicenseNumber: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }

    handleUpdatePassportNumber = (event) => {
        this.setState({
            PassportNumber: event.target.value,
            //validations: { ...this.state.validations, PassportNumber: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 1)
            this.props.ApplicantGeneralInformation(this.state)
        });
    }
    
    render() {
        let countries = [], citizenship = [];
        countries = this.context != null ? (this.context.countries != null ? this.context.countries : []) : [];
        citizenship = this.context != null ? (this.context.citizenship != null ? this.context.citizenship : []) : [];
        
        let frontPI = '', endPI = '', frontDL = '', endDL = '', frontPss = '', addPI = '', addDL = '', addPss = '';
        
                    frontPI = this.state.frontPI != null ? this.state.frontPI : '';
                    endPI = this.state.endPI != null ? this.state.endPI : '';
                    frontDL = this.state.frontDL != null ? this.state.frontDL : '';
                    endDL = this.state.endDL != null ? this.state.endDL : '';
                    frontPss = this.state.frontPss != null ? this.state.frontPss : '';
        addPI = this.state.addPI != null ? this.state.addPI : '';
        addDL = this.state.addDL != null ? this.state.addDL : '';
        addPss = this.state.addPss != null ? this.state.addPss : '';

        let lookUpList = [];
        if (this.context.lookUpTableList['PersonIdentificationType'].length > 0) {
            this.context.lookUpTableList['PersonIdentificationType'].forEach((value, index) => {
                if (index < 4) {
                    lookUpList.push(value);
                }
            })
        }
        if (lookUpList.length > 0) {
        return (
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <div className="row">
                        {getCurrentCompanyName() === 'Xushi' &&
                            <div className={"col-sm-3 col-xs-12" + formatValidInput(this.state.validations.saludationID)}>
                            <DynamicSelect
                                autoFocus={true}
                                className="form-control use-select2 select2-selection__placeholder" id="salutation" name="salutation" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-placeholder="Select ..."
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                objValue={{ value: this.state.saludationID, name: this.state.saludationName }}
                                getValue={this.handleUpdateSaludation}
                                listHasPlaceholder={true}
                                data={this.context.lookUpTableList != null ? this.context.lookUpTableList['Salutation'] : []} />
                            <label for="salutation" className="small">{getResource('Label_Salutation')}</label>
                            {(this.state.validations.saludationID != null && !this.state.validations.saludationID) &&
                                <label id="salutation-error" class="error" for="salutation">Field Required.</label>
                            }
                        </div>}
                    </div>
                    <div className="row">
                        <div className={`${getCurrentCompanyName() !== 'Xushi' ? 'col-sm-5' : 'col-sm-4'} col-xs-12` + formatValidInput(this.state.validations.FirstName)}>
                            <label for="first_name" className="small">{getResource('Label_FirstName')} *</label>
                                {(this.state.validations.FirstName != null && !this.state.validations.FirstName) &&
                                    <label id="first_name-error" class="error" for="first_name">Field Required.</label>
                                }
                            <input type="text" className="form-control" id="first_name" name="first_name" maxlength="70"
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                value={this.state.FirstName}
                                onChange={this.handleUpdateFirstName} />
                            
                        </div>
                        {getCurrentCompanyName() === 'Xushi' &&<div className="col-sm-4 col-xs-12 form-group">
                            <input type="text" className="form-control" id="short_name" name="short_name"
                                maxlength="50" value={this.state.ShortName}
                                onChange={this.handleUpdateShortName} />
                            <label for="short_name" className="small">{getResource('Label_MiddleName')}</label>
                        </div>}
                        <div className={`${getCurrentCompanyName() !== 'Xushi' ? 'col-sm-5' : 'col-sm-4'} col-xs-12` + formatValidInput(this.state.validations.LastName)}>
                            <label for="last_name" className="small">{getCurrentCompanyName() !== 'Lighthouse' ? getResource('Label_Surname') : getResource('Label_LastName')} *</label>
                                {(this.state.validations.LastName != null && !this.state.validations.LastName) &&
                                    <label id="last_name-error" class="error" for="last_name">Field Required.</label>
                                }
                            <input type="text" className="form-control" id="last_name" name="last_name" maxlength="70"
                                data-rule-required="true"
                                data-msg-required="Required"
                                value={this.state.LastName}
                                onChange={this.handleUpdateLastName} />
                            
                        </div>
                    </div>{/* .row */}
                    <div className="row">
                        <div className={"col-sm-3 col-xs-12 form-group  relative col-middle " + formatValidInput(this.state.validations.DateOfBirth)}>
                            <label for="date_of_birth" className="small">{getResource('Label_DateOfBirth')} *</label>
                                {(this.state.validations.DateOfBirth != null && !this.state.validations.DateOfBirth) &&
                                    <label id="date_of_birth-error" class="error" for="date_of_birth">Field Required.</label>
                                }
                            <DatePicker
                                className="form-control date-picker use-select2 select2-selection__placeholder field-datepicker"
                                readOnly={true}
                                endDate={today}
                                value={this.state.DateOfBirth}
                                onDayChange={this.handleUpdateDateOfBirth} />

                            
                        </div>
                        {getCurrentCompanyName() === 'Xushi' && <div className={"col-sm-4 col-xs-12" + formatValidInput(this.state.validations.CitizenshipID)}>
                            <DynamicSelect
                                className="form-control use-select2 select2-selection__placeholder" id="Citizenship" name="Citizenship" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-paging="false"
                                data-select2-placeholder="Select Citizenship"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                objValue={{ value: this.state.CitizenshipID, name: this.state.CitizenshipName }}
                                getValue={this.handleUpdateCitizenship}
                                listHasPlaceholder={false}
                                placeholder={"Select Citizenship"}
                                data={citizenship != null ? citizenship : []} />
                            <label for="Citizenship" className="small">{getResource('Label_Citizenship')}</label>
                            {(this.state.validations.CitizenshipID != null && !this.state.validations.CitizenshipID) &&
                                <label id="Citizenship-error" class="error" for="Citizenship">Field Required.</label>
                            }
                        </div>}
                        {/* <div className="col-sm-4 col-xs-12 form-group">
                            <NumberInput id="affiliate-referred-by" name="affiliate-referred-by" type="Integer" className="form-control" value={this.state.AffiliateReferredBy} onChange={this.handleAffiliateReferredBy}/>
                            <label htmlFor="affiliate-referred-by" className="small">{getResource('Label_AffiliateReferredBy')}</label>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <h5 class="subsection"><b>{getResource('Subtitle_ResidentialAddress')}</b></h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-md-12" + formatValidInput(this.state.validations.ResidentialAddressStreet)}>
                            <label for="residential_address_street" className="small">{getResource('Label_StreetAddress')} *</label>
                            {(this.state.validations.ResidentialAddressStreet != null && !this.state.validations.ResidentialAddressStreet) &&
                                <label id="residential_address_street-error" class="error" for="residential_address_street">Field Required.</label>
                            }
                            <input type="text" className="form-control" id="residential_address_street" name="residential_address_street" maxlength="100"
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                value={this.state.ResidentialAddressStreet}
                                onChange={this.handleUpdateResidentialAddressStreet} />
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label for="residential_address_line_2" className="small">{getResource('Label_AddressLine2')}</label>
                            <input type="text" className="form-control" id="residential_address_line_2" name="residential_address_line_2" maxlength="100"
                                value={this.state.ResidentialAddressLine2}
                                onChange={this.handleUpdateResidentialAddressLine2} />
                            
                        </div>
                    </div>

                    <div className="row">
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialAddressCity)}>
                            <label for="residential_address_city" className="small">{getResource('Label_City')} *</label>
                            {(this.state.validations.ResidentialAddressCity != null && !this.state.validations.ResidentialAddressCity) &&
                                <label id="residential_address_city-error" class="error" for="residential_address_city">Field Required.</label>
                            }
                            <input type="text" className="form-control" id="residential_address_city" name="residential_address_city" maxlength="50"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                value={this.state.ResidentialAddressCity}
                                onChange={this.handleUpdateResidentialAddressCity} />
                            
                        </div> {/* .col-md-6 */}
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryID)}>
                            <label for="residential_address_country" className="small">{getResource('Label_Country')} *</label>
                            {(this.state.validations.countryID != null && !this.state.validations.countryID) &&
                                <label id="residential_address_country-error" class="error" for="residential_address_country">Field Required.</label>
                            }
                            <DynamicSelect
                                className="form-control use-select2 select2-selection__placeholder" id="residential_address_country" name="residential_address_country" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-paging="false"
                                data-select2-placeholder="Select Country"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                objValue={{ value: this.state.countryID, name: this.state.countryName }}
                                getValue={this.handleUpdateCountry}
                                listHasPlaceholder={false}
                                placeholder={"Select Country"}
                                data={countries != null ? countries : []} />
                        </div> {/* .col-md-6 */}
                    </div> {/* .row */}
                    <div className="row">
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialAddressStateId)}>
                            <label htmlFor="residential_address_state" className="small">{getResource('Label_State')} *</label>
                            {(this.state.validations.ResidentialAddressStateId != null && !this.state.validations.ResidentialAddressStateId) &&
                                <label id="residential_address_state_name-error" class="error" htmlFor="residential_address_state_name">Field Required.</label>
                            }
                            {
                                this.state.statesByCountryID.length > 0 ?
                                    <DynamicSelect
                                        className="form-control use-select2 select2-selection__placeholder" id="residential_address_state" name="residential_address_state" style={{ width: "100%" }}
                                        data-select2-type="simple"
                                        data-select2-paging="false"
                                        data-select2-placeholder="Select State"
                                        data-rule-required="true"
                                        data-msg-required="Field Required"
                                        objValue={{ value: this.state.ResidentialAddressStateId, name: this.state.ResidentialAddressStateName }}
                                        getValue={this.handleUpdateResidentialAddressState}
                                        listHasPlaceholder={false}
                                        placeholder={"Select State"}
                                        data={this.state.statesByCountryID} />
                                    :
                                    <input type="text" className="form-control"
                                        id="residential_address_otherstate"
                                        name="residential_address_otherstate" maxLength="50"
                                        data-rule-required="true"
                                        data-msg-required="Field Required."
                                        value={this.state.OtherState}
                                        onChange={this.handleUpdateState} />
                            }
                            
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialAddressPostalCode)}>
                        <label htmlFor="residential_address_postalcode" className="small">{getResource('Label_Postal')} *</label>
                            {(this.state.validations.ResidentialAddressPostalCode != null && !this.state.validations.ResidentialAddressPostalCode) &&
                                <label id="residential_address_postalcode-error" class="error" htmlFor="residential_address_postalcode">Field Required.</label>
                            }
                            <input type="text" className="form-control" id="residential_address_postalcode" name="residential_address_postalcode" maxLength="50"
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                value={this.state.ResidentialAddressPostalCode}
                                onChange={this.handleUpdateResidentialAddressPostalCode} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <h5 class="subsection"><b>{getResource('SubTitle_ContactNumbers')}</b></h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialPhoneNumber)}>
                            <label for="residential_phone_number" className="small">{getResource('Label_MainContactNumber')}</label>
                            {(this.state.validations.ResidentialPhoneNumber != null && !this.state.validations.ResidentialPhoneNumber) &&
                                <label id="residential_phone_number-error" class="error" for="residential_phone_number">Field Required.</label>
                            }
                            <PhoneInput
                                autoFormat
                                enableSearch
                                country={defaultMobileCountry}
                                placeholder="Enter phone number"
                                id="phone-number-01"
                                name="phone-number-01"
                                copyNumbersOnly={false}
                                countryCodeEditable={false}
                                value={this.state.ResidentialPhoneNumber}
                                onChange={(value, country) => this.handleUpdateResidentialPhoneNumber(value, country)}                                
                            />
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.EmailAddress)}>
                            <label for="email_address" className="small">{getResource('Label_Email')} *</label>
                            {(this.state.validations.EmailAddress != null && !this.state.validations.EmailAddress) &&
                                <label id="email_address-error" class="error" for="email_address">Field Required.</label>
                            }
                            <input type="email" className="form-control" id="email_address" name="email_address" maxlength="150"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                data-rule-email="true"
                                data-msg-email="Email Required"
                                value={this.state.EmailAddress}
                                onChange={this.handleUpdateEmailAddress} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.MobileNumber)}>
                            <label for="mobile_number" className="small">{getResource('Label_MobileNumber')} *</label>
                            {(this.state.validations.MobileNumber != null && !this.state.validations.MobileNumber) &&
                                <label id="mobile_number-error" class="error" for="mobile_number">Field Required.</label>
                            }
                            <PhoneInput
                                enableSearch
                                autoFormat
                                country={defaultMobileCountry}
                                placeholder="Enter mobile number"
                                copyNumbersOnly={false}
                                countryCodeEditable={false}
                                value={this.state.MobileNumber}
                                onChange={(value, country) => this.handleUpdateMobileNumber(value, country)}
                                id='mobile_number'
                                name='mobile_number'
                            />
                        </div>
                    </div>
                    {getCurrentCompanyName() === 'Xushi' && <div className='row'>
                        <div className={"col-sm-6 col-xs-12 form-group"}>
                            <label className="description" htmlFor="referred_by">
                            <input id="referred_by" name="referred_by"
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                type="checkbox"
                                checked={this.state.ReferredBy}
                                onClick={this.handleUpdateReferredBy} />{getResource('Label_ReferredBy')}
                            </label>
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ReferredByName)}>
                            <input type="email" className="form-control" id="referred_by_name" name="referred_by_name" maxlength="150"
                                data-rule-required="true"
                                disabled={!this.state.ReferredBy}
                                data-msg-required="Field Required."
                                value={this.state.ReferredByName}
                                onChange={this.handleUpdateReferredByName} />
                            <label for="referred_by_name" className="small">{getResource('Label_ReferredByName')}</label>
                            {(this.state.validations.ReferredByName != null && !this.state.validations.ReferredByName) &&
                                <label id="referred_by_name-error" class="error" for="referred_by_name">Field Required.</label>
                            }
                        </div>
                    </div>}
                    {getCurrentCompanyName() === 'Xushi' && <div className='row'>
                        <div className={"col-sm-6 col-xs-12 form-group"}>
                            <DynamicSelect
                                autoFocus={true}
                                className="form-control use-select2 select2-selection__placeholder" id="sourceOfWealth" name="sourceOfWealth" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-placeholder="Select ..."
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                objValue={{ value: this.state.sourceOfWealthID, name: this.state.sourceOfWealthName }}
                                getValue={this.handleUpdateSourceOfWealth}
                                listHasPlaceholder={true}
                                data={this.context.lookUpTableList != null ? this.context.lookUpTableList['Source of Income'] : []} />
                            <label for="sourceOfWealth" className="small">{getResource('Label_SourceOfIncome')}</label>
                            {(this.state.validations.sourceOfWealthID != null && !this.state.validations.sourceOfWealthID) &&
                                <label id="sourceOfWealth-error" class="error" for="sourceOfWealth">Field Required.</label>
                            }
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.SourceOfIncomeOther)}>
                            <input type="email" className="form-control" id="referred_by_name" name="source_of_income_other" maxlength="150"
                                data-rule-required="true"
                                disabled={this.state.sourceOfWealthID !== 'Others'}
                                data-msg-required="Field Required."
                                value={this.state.SourceOfIncomeOther}
                                onChange={this.handleUpdateSourceOfIncomeOther} />
                            <label for="source_of_income_other" className="small">{getResource('Label_SourceOfIncomeOther')}</label>
                            {(this.state.validations.SourceOfIncomeOther != null && !this.state.validations.SourceOfIncomeOther) &&
                                <label id="source_of_income_other-error" class="error" for="source_of_income_other">Field Required.</label>
                            }
                        </div>
                    </div>}
                    {getCurrentCompanyName() === 'Xushi' && <div className='row'>
                        <div className={"col-sm-12 col-xs-12" + formatValidInput(this.state.validations.PEP)}>
                            <label className="description" htmlFor="politically_exposed_person">
                            <input id="politically_exposed_person" name="politically_exposed_person"
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                type="checkbox"
                                checked={!this.state.PEP}
                                onClick={this.handleUpdatePEP} />{getResource('Label_PoliticallyExposed2')}
                            </label>
                            {((this.state.validations.PEP != null && !this.state.validations.PEP)) &&
                                <label id="politically_exposed_person-error" class="error" for="politically_exposed_person">Field Required.</label>
                            }
                            <h6 style={{ fontWeight: 'normal' }}>{getResource('PEP_Paragraph')}</h6>
                        </div>
                    </div>}
                    {getCurrentCompanyName() === 'Xushi' && <div className='row'>
                        <div className="col-md-12 form-group">
                            <h5><b>{getResource('SubTitle_RemitterInformation')}</b></h5>
                            <h6 style={{ fontWeight: 'normal' }}>{getResource('Remitter_Paragraph')}</h6>
                        </div>
                    </div>}
                    {getCurrentCompanyName() === 'Xushi' && <div className='row'>
                        <div className="col-sm-6 col-xs-12 form-group">
                            <input type="text" className="form-control" id="remitter_name" name="remitter_name"
                                maxlength="50" value={this.state.RemitterName}
                                onChange={this.handleUpdateRemitterName} />
                            <label for="short_name" className="small">{getResource('Label_RemitterName')}</label>
                        </div>
                        <div className="col-sm-6 col-xs-12 form-group">
                            <input type="text" className="form-control" id="remitter_name" name="remitter_contact_numer"
                                maxlength="50" value={this.state.RemitterContactNumber}
                                onChange={this.handleUpdateRemitterContactNumber} />
                            <label for="short_name" className="small">{getResource('Label_RemitterContactNumber')}</label>
                        </div>
                        <div className="col-sm-6 col-xs-12 form-group">
                            <input type="text" className="form-control" id="remitter_relationship" name="remitter_relationship"
                                maxlength="50" value={this.state.RemitterRelationship}
                                onChange={this.handleUpdateRemitterRelationship} />
                            <label for="short_name" className="small">{getResource('Label_RemitterRelationship')}</label>
                        </div>
                    </div>}

                                    {/* PERSONAL IDENTIFICATION */}
                                    <div className="row">
                                    <div className="col-md-12 form-group">
                                        <h5 class="subsection"><b>{getResource('SubTitle_PersonalIdentification')}</b></h5>
                                        <p>If you wish to upload any relevant documentation to streamline the onboarding process, such as driving license or passport, you can do so here, but it is not mandatory at this stage.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryIDIdentification)}>
                                        <label for="personal_identification_country_01" className='small'>{getResource('Label_Country')} *</label>
                                        {(this.state.validations.countryIDIdentification != null && !this.state.validations.countryIDIdentification) &&
                                            <label id="personal_identification_country_01-error" class="error" htmlFor="personal_identification_country_01">Field Required.</label>
                                        }
                                        <DynamicSelect
                                            className="form-control" id="personal_identification_country_01" name="personal_identification_country_01" style={{ width: "100%" }}
                                            data-select2-type="simple"
                                            data-select2-paging="false"
                                            data-select2-placeholder="Select Country"
                                            data-rule-required="true"
                                            data-msg-required="Field Required"
                                            objValue={{ value: this.state.countryIDIdentification, name: this.state.countryNameIdentification }}
                                            getValue={this.handleUpdateCountryIdentification}
                                            listHasPlaceholder={false}
                                            placeholder={"Select Country"}
                                            data={this.context.countries != null ? this.context.countries : []}
                                        />
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.documentTypeID)}>
                                        <label for="document_type_01" className='small'>{getResource('Label_DocumentType')}</label>
                                        <DynamicSelect
                                            className="form-control use-select2" id="document_type_01" name="document_type_01" style={{ width: "100%" }}
                                            data-select2-type="simple"
                                            data-select2-placeholder="Select ..."
                                            data-rule-required="true"
                                            data-select2-show-wrapper-if="#document_type"
                                            data-select2-show-wrapper-if-values="Other"
                                            data-msg-required="Field Required."
                                            objValue={{ value: this.state.documentTypeID, name: this.state.documentTypeIDName }}
                                            getValue={this.handleUpdateDocumentType}
                                            listHasPlaceholder={false}
                                            data={lookUpList}
                                        />
                                    </div>
                                    {
                                        this.state.documentTypeNumber.includes('Identity') &&

                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.numberIdentification)}>
                                            <label for="identification_number_01" className='small'>{getResource('Label_NumberIdentification')}</label>
                                            {(this.state.validations.numberIdentification != null && !this.state.validations.numberIdentification) &&
                                                <label id="identification_number_01-error" class="error" htmlFor="identification_number_01">Field Required.</label>
                                            }
                                            <input type="email" className="form-control" id="identification_number_01" name="identification_number_01" maxlength="150"
                                                data-rule-required="true"
                                                data-msg-required="Field Required"
                                                data-rule-email="true"
                                                value={this.state.numberIdentification}
                                                onChange={this.handleUpdateNumberIdentification}
                                            />
                                            
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Identity') &&
                                        <div className={getCurrentCompanyName() !== 'Lighthouse' ? 'col-md-12' : 'col-md-6'} style={{ paddingBottom: 10 }}>
                                            <label for="personal_identification" className='small'>{getResource('Label_PersonalIdentification1')}</label>
                                            {
                                                frontPI === '' &&
                                                <div className="over-hidden pd-10p" style={{ paddingBottom: 0 }}>
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        onChange={this.handleChangeID1File}
                                                        name="personal_identification_01" id="personal_identification_01"
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                frontPI !== '' &&
                                                <NewDynamicTable key="personal_identification_01"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={frontPI}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickID1}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'frontPI')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                frontPI !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogID1}
                                                    onClose={this.showAttachmentsDialogID2}
                                                    attachmentId={frontPI[0].AttachmentId}
                                                    file={frontPI[0]}
                                                    rowClick={this.state.rowClickID1}
                                                    changeState={this.changeStateID1}
                                                />
                                            }
                                            
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Identity') &&
                                        <div className={getCurrentCompanyName() !== 'Lighthouse' ? 'col-md-12' : 'col-md-6'}>
                                            <label for="personal_identification_government" className='small'>{getResource('Label_PersonalIdentification2')}</label>
                                            {
                                                endPI === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        name="personal_identification_government" id="personal_identification_government"
                                                        onChange={this.handleChangeID2File}
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                endPI !== '' &&
                                                <NewDynamicTable key="personal_identification2"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={endPI}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickID2}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'endPI')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                endPI !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogID2}
                                                    onClose={this.showAttachmentsDialogID2}
                                                    attachmentId={endPI[0].AttachmentId}
                                                    file={endPI[0]}
                                                    rowClick={this.state.rowClickID2}
                                                    changeState={this.changeStateID2}
                                                />
                                            }
                                            
                                        </div>

                                    }
                                    {getCurrentCompanyName() === 'Lighthouse' && this.state.documentTypeNumber.includes('Identity') &&
                                        <div className="col-md-12">
                                            {addPI !== '' &&
                                                <label for="personal_identification_additional" className='small'>{getResource('Label_PersonalIdentification_Additional')}</label>
                                            }
                                            {this.state.documents.identityAdditionalFiles.map(additionalFile =>
                                                <div className="col-md-12">
                                                    <label>{additionalFile.FileName}</label>
                                                </div>
                                            )}
                                            {
                                                addPI === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        style={{display: 'none'}}
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        name="personal_identification_additional" id="personal_identification_additional"
                                                        onChange={this.handleIdentityAdditionalFiles}
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                    <label htmlFor='personal_identification_additional'>
                                                        <a className='button'>+ Upload additional pages</a>
                                                    </label>
                                                    {this.state.documents.identityAdditionalFiles.length > 0 &&
                                                        <label>
                                                            <a className='red' onClick={this.clearIdentityAdditionalFiles}>- Remove documents</a>
                                                        </label>
                                                    }
                                                </div>
                                            }
                                            {
                                                addPI !== '' &&
                                                <NewDynamicTable key="personal_identification_add"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={addPI}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickIDAdd}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'addPI')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                addPI !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogIDAdd}
                                                    onClose={this.showAttachmentsDialogIDAdd}
                                                    attachmentId={addPI[0].AttachmentId}
                                                    file={addPI[0]}
                                                    rowClick={this.state.rowClickIDAdd}
                                                    changeState={this.changeStateIDAdd}
                                                />
                                            }
                                            
                                        </div>

                                    }
                                    {this.state.documentTypeNumber.includes('Driving') &&
                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.DriverLicenseNumber)}>
                                            <label for="australian_driver_license_number" className="small">{getResource('Label_LicenseNumber')}</label>
                                            {(this.state.validations.DriverLicenseNumber != null && !this.state.validations.DriverLicenseNumber) &&
                                                <label id="australian_driver_license_number-error" class="error" for="australian_driver_license_number">Field Required.</label>
                                            }
                                            <input type="text" className="form-control" id="australian_driver_license_number" name="australian_driver_license_number" maxlength="30"
                                                data-rule-required="true"
                                                data-msg-required="Field Required"
                                                value={this.state.DriverLicenseNumber}
                                                onChange={this.handleUpdateDriverLicenseNumber} />
                                            
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Driving') &&
                                        <div className={getCurrentCompanyName() !== 'Lighthouse' ? 'col-md-12' : 'col-md-6'}>
                                            <label for="driving_License1" className="small">{getResource('Label_PersonalIdentification1')}</label>
                                            {
                                                frontDL === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        onChange={this.handleChangeDL1File}
                                                        name="driving_License1" id="driving_License1"
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                frontDL !== '' &&
                                                <NewDynamicTable key="driving_License1"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={frontDL}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickDL1}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this,'frontDL')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                frontDL !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogDL1}
                                                    onClose={this.showAttachmentsDialogDL1}
                                                    attachmentId={frontDL[0].AttachmentId}
                                                    file={frontDL[0]}
                                                    rowClick={this.state.rowClickDL1}
                                                    changeState={this.changeStateDL1}
                                                />
                                            }
                                            
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Driving') &&
                                        <div className={getCurrentCompanyName() !== 'Lighthouse' ? 'col-md-12' : 'col-md-6'}>
                                            <label for="driving_License2" className="small">{getResource('Label_PersonalIdentification2')}</label>
                                            {
                                                endDL === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        name="driving_License2" id="driving_License2"
                                                        onChange={this.handleChangeDL2File}
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                endDL !== '' &&
                                                <NewDynamicTable key="driving_License2"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={endDL}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickDL2}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'endDL')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                endDL !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogDL2}
                                                    onClose={this.showAttachmentsDialogDL2}
                                                    attachmentId={endDL[0].AttachmentId}
                                                    file={endDL[0]}
                                                    rowClick={this.state.rowClickDL2}
                                                    changeState={this.changeStateDL2}
                                                />
                                            }
                                        </div>
                                    }
                                    {getCurrentCompanyName() === 'Lighthouse' && this.state.documentTypeNumber.includes('Driving') &&
                                        <div className="col-md-12">
                                            {addDL !== '' &&
                                                <label for="driving_License_additional" className='small'>{getResource('Label_PersonalIdentification_Additional')}</label>
                                            }
                                            {this.state.documents.drivingLicenseAdditionalFiles.map(additionalFile =>
                                                <div className="col-md-12">
                                                    <label>{additionalFile.FileName}</label>
                                                </div>
                                            )}
                                            {
                                                addDL === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        onChange={this.handleDrivingLicenseAdditionalFiles}
                                                        style={{display: 'none'}}
                                                        name="driving_License1" id="driving_License_additional"
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                    <label htmlFor='driving_License_additional'>
                                                        <a className='button'>+ Upload additional pages</a>
                                                    </label>
                                                    {this.state.documents.drivingLicenseAdditionalFiles.length > 0 &&
                                                        <label>
                                                            <a className='red' onClick={this.clearDrivingLicenseAdditionalFiles}>- Remove documents</a>
                                                        </label>
                                                    }
                                                </div>
                                            }
                                            {
                                                addDL !== '' &&
                                                <NewDynamicTable key="driving_License1"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={addDL}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickDLAdd}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this,'addDL')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                addDL !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogDLAdd}
                                                    onClose={this.showAttachmentsDialogDLAdd}
                                                    attachmentId={addDL[0].AttachmentId}
                                                    file={addDL[0]}
                                                    rowClick={this.state.rowClickDLAdd}
                                                    changeState={this.changeStateDLAdd}
                                                />
                                            }
                                            
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Passport') &&
                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.PassportNumber)}>
                                            <label for="australian_passport_number" className="small">{getResource('Label_PassportNumber')}</label>
                                            {(this.state.validations.PassportNumber != null && !this.state.validations.PassportNumber) &&
                                                <label id="australian_passport_number-error" class="error" for="australian_passport_number">Field Required.</label>
                                            }
                                            <input type="text" className="form-control" id="australian_passport_number" name="australian_passport_number" maxlength="30"
                                                data-rule-required="true"
                                                data-msg-required="Field Required"
                                                value={this.state.PassportNumber}
                                                onChange={this.handleUpdatePassportNumber} />
                                            
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Passport') &&
                                        <div className="col-md-12">
                                            <label for="passport" className="small">{getResource('Label_PersonalIdentificationPassport')}</label>
                                            {
                                                frontPss === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        name="passport" id="passport"
                                                        onChange={this.handleChangePSSFile}
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                frontPss !== '' &&
                                                <NewDynamicTable key="personal_identification2"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={frontPss}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickPS1}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'frontPss')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                frontPss !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogPS1}
                                                    onClose={this.showAttachmentsDialogPS1}
                                                    attachmentId={frontPss[0].AttachmentId}
                                                    file={frontPss[0]}
                                                    rowClick={this.state.rowClickPS1}
                                                    changeState={this.changeStatePS1}
                                                />
                                            }
                                            
                                        </div>
                                    }
                                    {getCurrentCompanyName() === 'Lighthouse' && this.state.documentTypeNumber.includes('Passport') &&
                                        <div className="col-md-12">
                                            {addPss !== '' &&
                                                <label for="passport_additional" className='small'>{getResource('Label_PersonalIdentification_Additional')}</label>
                                            }
                                            {this.state.documents.passportAdditionalFiles.map(additionalFile =>
                                                <div className="col-md-12">
                                                    <label>{additionalFile.FileName}</label>
                                                </div>
                                            )}
                                            {
                                                addPss === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        name="passport" id="passport_additional"
                                                        onChange={this.handlePassportAdditionalFiles}
                                                        style={{display: 'none'}}
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                    <label htmlFor='passport_additional'>
                                                        <a className='button'>+ Upload additional pages</a>
                                                    </label>
                                                    {this.state.documents.passportAdditionalFiles.length > 0 &&
                                                        <label>
                                                            <a className='red' onClick={this.clearPassportAdditionalFiles}>- Remove documents</a>
                                                        </label>
                                                    }
                                                </div>
                                            }
                                            {
                                                addPss !== '' &&
                                                <NewDynamicTable key="personal_identification2"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={addPss}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickPSAdd}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'addPss')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                addPss !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogPSAdd}
                                                    onClose={this.showAttachmentsDialogPSAdd}
                                                    attachmentId={addPss[0].AttachmentId}
                                                    file={addPss[0]}
                                                    rowClick={this.state.rowClickPSAdd}
                                                    changeState={this.changeStatePSAdd}
                                                />
                                            }
                                            
                                        </div>
                                    }
                                </div> {/* END PERSON IDENTIFICATION */}
                    </div>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this file?" message="If you delete this file, you will need to upload all the corresponding files. This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
            </div>
        );
    } else {
        return (
            <div>
                <LoadingBox loading={true}></LoadingBox>
            </div>
        );
    }
    }
}