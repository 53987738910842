import React from 'react';
/*r2d2 ------------------------------------------------------------------------------ */
/*r2d2 ------------------------------------------------------------------------------ */

//<AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this bank?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>
//<AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Bank successfully removed." message="" type="Ok" okClick={this.okSuccessAlert.bind(this)}/>
export default class DynamicInfoScreen extends React.Component {

    getInfoFormat = () =>{
    let info = [
      {
        id: "jurisdiction",
        label: "Jurisdiction",
        value: "",
        colspan: 1
      },
      {
        id: "companyname",
        label: "Name",
        value: "",
        colspan: 1
      },
      {
        id: "address_1",
        label: "Address 1",
        value: "",
        colspan: 1
      },
      {
        id: "address_2",
        label: "Address 2",
        value: "",
        colspan: 1
      },
      {
        id: "postalcode",
        label: "Postal Code",
        value: "",
        colspan: 1
      },
      {
        id: "companytype",
        label: "Type",
        value: "",
        colspan: 1
      },
      {
        id: "companystatus",
        label: "Status",
        value: "",
        colspan: 1
      }
    ]
    
    if(this.props.infoJson && this.props.infoJson.length > 0)
        {
            info = this.props.infoJson;
        }
   
    let infoRows = [];
    if(info && info.length > 0)
        {
            info.map(item => 
                { 
                    let colspan = 2;
                    if(item.colspan)
                    {
                        colspan = item.colspan;
                    }
                    infoRows.push(
                    <div className={`uk-width-1-${colspan} uk-form-stacked`}>
                        <div className="uk-margin-right">
                            <label className="uk-form-label" style={{fontSize:'12px'}} id={`label${item.id}`} key={`label${item.id}`}>{item.label}:</label>
                            <div className="uk-margin-right">
                            <input className="uk-input" key={`info${item.id}`} disabled={true} id={`id-${item.id}`} type="text" placeholder="" value={item.value} />
                            </div>
                        </div>
                    </div> )
                    return true;
                }
        )
        }
/*
    if(info)
    {
        let data = Object.keys(info).map(function (key) {
            return [key, info[key]];
        });

        if(data && data.length > 0)
            {
                data = data.filter(d => d[0] !== 'httpStatusCode');
            data.map(i => {
                let value = i[1];
                let colspan = 2;
                if(typeof(value) === 'object')
                    {
                        value = JSON.stringify(value);
                    }
                infoRows.push(
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin-right">
                            <label className="uk-form-label" style={{fontSize:'12px'}} key={`label-info${i[0]}`}>{i[0]}:</label>
                            <div className="uk-margin-right">                                
                                <input className="uk-input" key={`value-info${i[0]}`} disabled={true} id="dealVersionTrader" type="text" placeholder="" value={value} />
                
                            </div>
                        </div>
                    </div> );
                    return true;
            }
            )
            }
    }*/
    return infoRows;
}

    render() {
              return (
          
              <div className="uk-grid">
              {this.getInfoFormat()}
              </div>

              
        );
    }
}