const pjson = require('../../package.json');
const apiURL = window.REACT_APP_API_URL_GENERAL;
const homepageURL = window.REACT_APP_HOMEPAGE_URL;
const showLanguages = window.REACT_APP_SHOW_LANGUAGES;
var enableCustomLogoPath = window.REACT_APP_ENABLE_CUSTOM_LOGOPATH;
var customLogoPath = window.REACT_APP_CUSTOM_LOGOPATH;
var bypassPhoneValidation = window.REACT_APP_BYPASS_PHONE_VALIDATION;
var defaultCountry = window.REACT_APP_DEFAULT_COUNTRY;
var defaultMobileCountry = window.REACT_APP_MOBILE_DEFAULT_COUNTRY;


export function getCurrentCompanyName(){
    return pjson.companyName;
}

export function getHomepageURL(){
    return homepageURL;
}
export function getCurrentURLImage() {
    //../App_Data/images/${REACT_APP_CUSTOMER_NAME}/
    let urlImage = apiURL + 'App_Data/images/' + getCurrentCompanyName() + '/logoid.jpg';
    if (enableCustomLogoPath) {
        urlImage = customLogoPath;
    }
    //const urlImage = logo;
    return urlImage;
}

export function getShowLanguages() {
    return showLanguages;
}

export function getBypassPhoneValidation() {
    return bypassPhoneValidation;
}

export function getDefaultCountry() {
    return defaultCountry;
}

export function getDefaultMobileCountry() {
    return defaultMobileCountry;
}

export function getCurrentVersionNumber() {
    return pjson.version;
}

export function getTermsAndConditions() {
    switch (getCurrentCompanyName()) {
        case 'Xushi': return require('../documents/Terms and conditions pending.pdf');
        case 'Premier': return require('../documents/Terms and conditions.pdf');
        case 'Lighthouse': return 'https://acrobat.adobe.com/id/urn:aaid:sc:EU:fc54447b-0058-4e8e-931a-8812b452622e';
        default: return require('../documents/Terms and conditions pending.pdf');
    }
}

export function getCompanyLogoFromProject() {
    const company = window.REACT_APP_COMPANY;
    switch (company) {
      case 'Premier': return require('../images/Logo_Premier.png');
      case 'Xushi': return require('../images/xushi-logo.jpg');
      case 'Lighthouse': return require('../images/lighthouse-logo.jpg');
      default: return require('../images/logo_generic.png');
    }
  }