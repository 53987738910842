import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { getBypassPhoneValidation, getCurrentCompanyName, getDefaultCountry, getDefaultMobileCountry } from '../../helper/PackageJsonHelper';
import { getResource } from '../../helper/ResourcesHelper';
import { validate } from '../../helper/ValidateHelper';
import { checkValidReferralCustomer, getCustomerInfoByID, getParameterByName } from '../../networking/Networking';
import { getEntityProperties } from '../../networking/NetworkingAdditionalPropertiesDefs';
import { getCountries } from '../../networking/NetworkingCountries';
import { generateCode } from '../../networking/NetworkingMobileValidation';
import { submitPreReg } from '../../networking/NetworkingPreReg';
import { companiesHouseGetInformation, updateFromCompaniesHouse } from '../../networking/NetworkingRegForm';
import { getStyle } from '../../styles/styles';
import AlertBox from '../shared/AlertBox';
import DynamicInfoScreen from '../shared/DynamicInfoScreen';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';
import ValidatePhone from '../shared/ValidatePhone';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const style = getStyle();
const pathname = window.location.pathname;
const bypassPhoneValidationConst = getBypassPhoneValidation();
const currentCompanyName = getCurrentCompanyName();

const defaultCountry = getDefaultCountry();
const defaultMobileCountry = getDefaultMobileCountry();
const useCompaniesHouse = window.REACT_USE_COMPANIES_HOUSE;
const useCompaniesHouseCountries = window.REACT_USE_COMPANIES_HOUSE_COUNTRIES;
export default class Registration extends React.Component {
    state = {
        //form
        firstname: '',
        lastname: '',
        companyname: '',
        email: '',
        password: '',
        confpass: '',
        phone: '',
        countryList: [],
        tradingList:[],
        countryId: defaultCountry,
        countryIdName: '',
        //---
        type: currentCompanyName !== 'Khyber' ? 'Corporate' : 'Individual',
        customerID: 0,

        //--LOGIN
        isLogin: false,
        loading: false,
        //AlertBox
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        errors: [],
        isEmail: '',
        openValidatePhone: false,

        //Settings
        phoneValidated: false,
        bypassAllValidations: false,
        bypassPhoneValidation: bypassPhoneValidationConst,
        showInvalidReferralAlert: false,
        validReferral: false,
        //Validations
        validations: {
            companyName: true,
            email: true,
            firstName: true,
            lastName: true,
            password: true,
            confPass: true,
            phone: true,
            country: true
        },

        customerBody: null,
        //placeholder
        placeholder:'input_placeholder',
        showCompaniesHouse: false,
        companyInfoJson:{},
        regNumber:'',
        regNumberDisabled: false,
        showAlertCH: false,
        showConfirmCH:false
    }

    componentDidMount() {
        console.log("pathname ---> " + JSON.stringify(pathname));

        if (pathname === '/email-verified') {
            this.props.navigate('/email-verified');
        } else {
            this.validateRequiredInputs();
            getCountries(1, 250).then(
                (json) => {
                    if (json != null && json.countries != null) {
                        const list = json.countries;
                        const countries = list.map(c => ({ ...c, name: c["Country Name"], value: c.Country }));
                        this.setState({ countryList: countries });
                    }
                }
            )
        }
    }

    trySubmit = async (event) => {
        event.preventDefault();
        if (this.state.bypassAllValidations) {
            if (this.state.type === "Corporate") {
                this.props.navigate('/corporate-account/:regionID');
            } else {
                this.props.navigate('/individual-account/:regionID');
            }
            return;
        }
        if (this.validateRequiredInputs(true)) {
            if (this.state.bypassPhoneValidation || this.state.phoneValidated) {
                const validReferral = await this.checkValidReferralCustomer();
                if (validReferral) {
                    this.setState({ validReferral: true }, async () => {
                        await this.submit(event);
                    });
                } else {
                    this.setState({ showInvalidReferralAlert: true });
                }
            } else {
                const model = {
                    numberMobile: '+' + this.state.phone,
                    token: '',
                    companyName: this.state.companyname
                };
                const json = await generateCode(model);
                console.log(json);
                this.setState({ openValidatePhone: true })
            }
        }
    }

    submit = async (event = null) => {
        if (event != null) {
            event.preventDefault();
        }
        const model = {
            'Type': this.state.type,
            'FirstName': this.state.firstname,
            'LastName': this.state.lastname,
            'MiddleName': '',
            'CountryID': this.state.countryId,
            'Email': this.state.email,
            'Password': this.state.password,
            'PhoneNumber': '+' + this.state.phone,
            'CompanyName': this.state.companyname,
            'PreferredLanguage': sessionStorage.getItem('360Language'),
            'ReferralID': this.state.validReferral ? this.props.referralID : null,
            'MobileValidated': this.state.phoneValidated
        }
        //if (this.state.region != null && this.state.region !== '') {
            this.setState({ loading: true })
            const json = await submitPreReg(model);
            this.setState({ loading: false })
            if (json.httpStatusCode !== 200) {
                const errors = [json.Message]
                this.setState({
                    errors: errors,
                    showAlertError: true,
                    alertTitle: 'Error',
                    alertMessage: 'Please fix the following errors:'
                });
            } else {
                const parameterResponse = await getParameterByName('DisableSendEmailRegform');
                let alertMessage = getResource('Message_SuccessRegistration');
                // Use different message resource without email references when global is true
                if (parameterResponse != null && parameterResponse.ParameterValue != null && parameterResponse.ParameterValue !== '' && parameterResponse.ParameterValue.toLowerCase() === 'true') {
                    alertMessage = getResource('Message_SuccessRegistrationNoEmail');
                }

                if(this.state.type === 'Corporate' && useCompaniesHouseCountries.includes(this.state.countryId) && useCompaniesHouse)
                {
                    this.setState({
                        showCompaniesHouse: true,
                        alertTitle: 'Success',
                        alertMessage: alertMessage,
                        isEmail: this.state.email,
                        customerID: json.customerID
                    });
                }
                else{
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Success',
                        alertMessage: alertMessage,
                        isEmail: this.state.email,
                        customerID: json.customerID
                    },
                        () => {                                                
                            this.getCustomer(json.customerID);                            
                        });
                }
            }
        /*}else{
            this.setState({
                errors: [],
                showAlertError: true,
                alertTitle: 'Alert',
                alertMessage: 'Unfortunately we are not currently accepting registrations from your country. We are constantly adding new countries to our list so try with us later',
            });
        }*/
    }

    getCustomer = (customerID) => {
        getCustomerInfoByID(customerID).then(
            (json) => {
                if (json != null) {//json.Parent = 'Newport'
                    this.setState({ customerBody: json });
                }
            }
        );
    }

    checkValidReferralCustomer = async () => {
        if (this.props.referralID == null ||
            this.props.referralID === '' ||
            this.props.referralID === 0) {
                return true;
        } else {
            const json = await checkValidReferralCustomer(this.props.referralID);
            if (json != null && json.validReferralCustomer != null) {
                const validReferralCustomer = json.validReferralCustomer;
                return validReferralCustomer;
            }
            return false;
        }
    };

    validatePassword = () => this.state.password === this.state.confpass;

    //Inputs Validations
    validateRequiredInputs = (sideEffects = false) => {
        let corporate = [
            { displayName: 'Company name', validateName: 'companyName', value: this.state.companyname, type: 'string' },
            { displayName: 'Email of contact', validateName: 'email', value: this.state.email, type: 'email' },
            { displayName: 'First name of contact', validateName: 'firstName', value: this.state.firstname, type: 'string' },
            { displayName: 'Last name of contact', validateName: 'lastName', value: this.state.lastname, type: 'string' },
            { displayName: 'Password', validateName: 'password', value: this.state.password, type: 'string' },
            { displayName: 'Password confirmation', validateName: 'confPass', value: this.state.confpass, type: 'string' },
            { displayName: 'Mobile number', validateName: 'phone', value: this.state.phone, type: 'string' },
            { displayName: 'Country', validateName: 'country', value: this.state.countryId, type: 'string' },
            { displayName: 'Your passwords do not match. Please try again', validation: this.validatePassword },
        ];

        let individual = [
            { displayName: 'Email', validateName: 'email', value: this.state.email, type: 'email' },
            { displayName: 'First name', validateName: 'firstName', value: this.state.firstname, type: 'string' },
            { displayName: 'Last name', validateName: 'lastName', value: this.state.lastname, type: 'string' },
            { displayName: 'Password', validateName: 'password', value: this.state.password, type: 'string' },
            { displayName: 'Password confirmation', validateName: 'confPass', value: this.state.confpass, type: 'string' },
            { displayName: 'Mobile number', validateName: 'phone', value: this.state.phone, type: 'string' },
            { displayName: 'Country', validateName: 'country', value: this.state.countryId, type: 'string' },
            { displayName: 'Your passwords do not match. Please try again', validation: this.validatePassword },
        ]

        switch (this.state.type) {
            default:
            case 'Corporate': {
                // if (this.state.password !== '' && this.state.confpass !== '') {
                //     corporate.push({ displayName: 'Your passwords do not match. Please try again', validation: this.validatePassword });
                // }
                const [valid, validations, errors] = validate(corporate);
                this.setState({ errors });
                if (!valid && sideEffects) {
                    this.setState({
                        showAlertError: true, alertTitle: '', alertMessage: 'Please review and correct the errors or omissions within the following field(s)',
                        validations: { ...this.state.validations, ...validations }
                    });
                }
                return valid;
            }
            case 'Individual': {
                // // if (this.state.password !== '' && this.state.confpass !== '') {
                //     individual.push({ displayName: 'Your passwords do not match. Please try again', validation: this.validatePassword });
                // // }
                const [valid, validations, errors] = validate(individual);
                this.setState({ errors });
                if (!valid && sideEffects) {
                    this.setState({
                        showAlertError: true, alertTitle: '', alertMessage: 'Please review and correct the errors or omissions within the following field(s)',
                        validations: { ...this.state.validations, ...validations }
                    });
                }
                return valid;
            }
        }
    }

    Clear = () => {
        this.setState({
            //form
            firstname: '',
            lastname: '',
            companyname: '',
            email: '',
            password: '',
            confpass: '',
            phone: '',
            countryList: [],
            countryId: '',
            countryIdName: '',
            //---
            loading: false,
            type: 'Corporate',

            //--LOGIN
            isLogin: false,
            //AlertBox
            showAlert: false,
            showAlertError: false,
            alertTitle: '',
            alertMessage: '',
            errors: [],
            isEmail: '',
            showCompaniesHouse: false,
            companyInfoJson:{},
            regNumber:'',
            regNumberDisabled: false,
            showAlertCH: false,
            //Validations
            validations: {
                companyName: true,
                email: true,
                firstName: true,
                lastName: true,
                password: true,
                confPass: true,
                phone: true,
                country: true
            }
        })
    }

    clearValidations = () => {
        this.setState({
            validations: {
                companyName: true,
                email: true,
                firstName: true,
                lastName: true,
                password: true,
                confPass: true,
                phone: true,
                country: true
            }
        });
    }

    validatePhoneClick = async (event) => {
        event.preventDefault();
        if (this.state.phoneValidated) {
            return;
        }
        if (this.validatePhone()) {
            const model = {
                numberMobile: '+' + this.state.phone,
                token: '',
                companyName: this.state.companyname
            };
            const json = await generateCode(model);
            console.log(json);
            this.setState({ openValidatePhone: true })
        }
    }

    checkPhone = () => isValidPhoneNumber('+' + this.state.phone);

    validatePhone = () => {
        const fields = [
            { displayName: 'Mobile Number is not valid', validateName: 'phone', validation: this.checkPhone }
        ];
        const [valid, validations, errors] = validate(fields);
        this.setState({ errors });
        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fix the following errors:',
                validations: { ...this.state.validations, ...validations }
            })
        }
        return valid;
    }

    onValidatePhoneSuccess = async(isPhoneValidated, bypassPhoneValidation) => {
        this.setState({ phoneValidated: isPhoneValidated, openValidatePhone: false, bypassPhoneValidation: bypassPhoneValidation },
            async() =>{
                if(isPhoneValidated || bypassPhoneValidation )
                    {
                    const validReferral = await this.checkValidReferralCustomer();
                    if (validReferral) {
                        this.setState({ validReferral: true }, async () => {
                            await this.submit(null);
                        });
                    } else {
                        this.setState({ showInvalidReferralAlert: true });
                    }
                }
            }
        );
    }

    onValidatePhoneClose = () => {
        this.setState({ openValidatePhone: false });
    }

    getCountryValue(value) {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        countryId: value.value, countryIdName: value.name != null ? value.name : "", placeholder: '',
                        validations: { ...this.state.validations, country: true }
                    }, () => { this.getAddPropertiesByCountry(value['Num ID']) });
                } else {
                    this.setState({
                        countryID: '', countryName: '', placeholder: 'input_placeholder'
                    });
                }
            } else {
                this.setState({
                    countryID: '', countryName: '', placeholder: 'input_placeholder'
                });
            }
        }
    }
    getAddPropertiesByCountry = (country) => {
        getEntityProperties(country, 'Country').then(
            (jsonResponse) => {
                let region = '';
                if (jsonResponse.totalCount > 0) {
                    jsonResponse.entityProperties.forEach(element => {
                        switch (element.Description) {
                            case 'Regions':
                                region = element.TextValue;
                                break;
                            default:
                                break;
                        }
                        this.setState({ region })
                    })
                }
            }
        );
    }
    onChangeCustomerType = (event) => {
        this.setState({
            type: event.target.value,
            validations: { ...this.state.validations, country: true }
        });
        this.clearValidations();
    }

    handleUpdateFirstName = (event) => {
        this.setState({
            firstname: event.target.value,
            validations: { ...this.state.validations, firstName: true }
        });
    }

    handleUpdateEmail = (event) => {
        this.setState({
            email: event.target.value.trim(),
            validations: { ...this.state.validations, email: true }
        });
    }

    handleUpdateLastName = (event) => {
        this.setState({
            lastname: event.target.value,
            validations: { ...this.state.validations, lastName: true }
        });
    }

    handleUpdateCorporate = (event) => {
        this.setState({
            companyname: event.target.value,
            validations: { ...this.state.validations, companyName: true }
        });
    }

    handleUpdatePassword = (event) => {
        this.setState({
            password: event.target.value,
            validations: { ...this.state.validations, password: true }
        });
    }

    handleUpdateConfPass = (event) => {
        this.setState({
            confpass: event.target.value,
            validations: { ...this.state.validations, confPass: true }
        });
    }

    handleUpdatePhone = (phone) => {
        this.setState({
            phone,
            validations: { ...this.state.validations, phone: true },
            phoneValidated: false,
            bypassPhoneValidation: bypassPhoneValidationConst
        });
    }

    closeAlert() {
        let login = true;
        this.setState({ isLogin: login, showAlert: false});
    }

    closeAlert2() {
        this.setState({ showAlertError: false });
    }

    closeAlertPhoneValidated = () => {
        this.setState({ showAlertPhoneValidated: false });
    }

    closeInvalidReferralAlert = () => {
        this.setState({ showInvalidReferralAlert: false }, async () => {
            await this.submit();
        });
    }

    isLogin = (value) => {
        this.setState({ isLogin: value })
    }

    searchCompaniesHouse = async() =>{
        if(this.state.regNumber)
            {
                this.setState({loading: true});
                let companyInfo = await companiesHouseGetInformation(this.state.regNumber, this.state.customerID);
                
                if(companyInfo)
                {
                    this.setState({companyInfoJson: companyInfo, loading: false, regNumberDisabled: true});
                }
                else
                {
                    this.setState({regNumber:'',showAlertCH: true, loading: false, regNumberDisabled: false});
                }
            }

    }

    closeAlertCH() {
        this.setState({ showAlertCH: false });
    }

    continueCompaniesHouse = async ()=> 
    {
        if(this.state.regNumber && this.state.regNumber !== '' && this.state.companyInfoJson && this.state.companyInfoJson.length > 0)
            {
        this.setState({showCompaniesHouse:false});
        this.updateInfoFromCompaniesHouse();
            }
            else
            {
                this.setState({ showConfirmCH:true});                
            }
    }

    yesConfirmCH = () =>{
        this.setState({showCompaniesHouse:false,showConfirmCH:false}); 
        this.continueProcess();
    }

    closeConfirmCH = () =>{
        this.setState({showConfirmCH:false}); 
    }

    continueProcess = async() =>{
        const parameterResponse = await getParameterByName('DisableSendEmailRegform');
                let alertMessage = getResource('Message_SuccessRegistration');
                // Use different message resource without email references when global is true
                if (parameterResponse != null && parameterResponse.ParameterValue != null && parameterResponse.ParameterValue !== '' && parameterResponse.ParameterValue.toLowerCase() === 'true') {
                    alertMessage = getResource('Message_SuccessRegistrationNoEmail');
                }                
                    this.setState({                        
                        alertTitle: 'Success',
                        alertMessage: alertMessage,
                        showAlert: true,
                    },
                        () => {                                                
                            this.getCustomer(this.state.customerID);                            
                        });
    }

    updateInfoFromCompaniesHouse = async () => {
        
        const model = {
            'CustomerID': this.state.customerID,
            'RegNumber': this.state.regNumber
        }
        //if (this.state.region != null && this.state.region !== '') {
            this.setState({ loading: true });
            const json = await updateFromCompaniesHouse(model);
            this.setState({ loading: false });

            if (json.httpStatusCode !== 200) {
                const errors = [json.Message]
                this.setState({
                    errors: errors,
                    showAlertError: true,
                    alertTitle: 'Error',
                    alertMessage: 'Please fix the following errors:'
                });
            } else {
                this.continueProcess();
                }
    }

    render() {
        var isCorporate = ('');
        var nameCorporate = ('');
        if (this.state.isLogin) {
            switch (this.state.type) {
                case 'MSB':
                    this.props.navigate('/msb-account/:regionID', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: '' } });
                    break;
                case 'Corporate':
                    this.props.navigate('/corporate-account/:regionID', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: '' } });
                    break;
                case 'Individual':
                    this.props.navigate('/individual-account/:regionID', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: '' } });

                    break;
                default:
                    break;
            }
            // return (<RegistrationScreen Email={this.state.isEmail} CountryObj={[this.state.countryId, this.state.countryIdName]} isClear={this.Clear} CustomerID={this.state.customerID} isLogin={this.isLogin} isCorporate={this.state.isCorporate} />);
        }
        switch (this.state.type) {
            case 'MSB':
            case 'Corporate':
                isCorporate = (
                    <div className="col-md-12 form-group" >
                        <label htmlFor="company_name" className="small">{getResource('Label_CompanyName')} *</label>
                        {!this.state.validations.companyName &&
                            <label id="company_name-error" className="error" for="company_name">Field required.</label>}
                        <input type="text"
                            className="form-control"
                            id="company_name"
                            name="company_name"
                            maxLength="50"
                            data-rule-required="true"
                            data-msg-required="Field Required"
                            placeholder="Company Name..."
                            value={this.state.companyname}
                            onChange={this.handleUpdateCorporate} />
                        
                    </ div>
                );
                nameCorporate = (
                    <div>
                        <div className="col-md-12 form-group" >
                        <label htmlFor="first_name" className="small">{getResource('Label_FirstName')} *</label>
                            {!this.state.validations.firstName &&
                            <label id="first_name-error" className="error" for="first_name">Field required.</label>}
                            <input type="text"
                                className="form-control"
                                id="first_name"
                                name="first_name"
                                maxLength="50"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                placeholder="First Name..."
                                value={this.state.firstname}
                                onChange={this.handleUpdateFirstName} />
                        </ div>
                        <div className="col-md-12 form-group" >
                        <label htmlFor="last_name" className="small">{getResource('Label_LastName')} *</label>
                            {!this.state.validations.lastName &&
                                <label id="last_name-error" className="error" for="last_name">Field required.</label>}
                            <input type="text"
                                className="form-control"
                                id="last_name"
                                name="last_name"
                                maxLength="50"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                placeholder="Last Name..."
                                value={this.state.lastname}
                                onChange={this.handleUpdateLastName} />
                        </ div>
                        <div className="col-md-12 form-group" >
                        <label htmlFor="email" className="small">{getResource('Label_Email')} *</label>
                            {!this.state.validations.email &&
                                <label id="email-error" className="error" for="email">Field required.</label>}
                            <input type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                maxLength="50"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                placeholder="Email..."
                                value={this.state.email}
                                onChange={this.handleUpdateEmail} />              
                        </ div>
                    </div>
                );
                break;
            case 'Individual':
                nameCorporate = (
                    <div className="col-md-12 form-group" >
                        <label htmlFor="email" className="small">{getResource('Label_Email')} *</label>
                        {!this.state.validations.email &&
                            <label id="email-error" className="error" for="email">Field required.</label>}
                        <input type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            maxLength="50"
                            data-rule-required="true"
                            data-msg-required="Field Required"
                            placeholder="Email..."
                            value={this.state.email}
                            onChange={this.handleUpdateEmail} />
                    </ div>
                );
                isCorporate = (
                    <div>
                        <div className="col-md-12 form-group" >
                        <label htmlFor="first_name" className="small">{getResource('Label_FirstName')} *</label>
                            {!this.state.validations.firstName &&
                                <label id="first_name-error" className="error" for="first_name">Field required.</label>}
                            <input type="text"
                                className="form-control"
                                id="first_name"
                                name="first_name"
                                maxLength="50"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                placeholder="First Name..."
                                value={this.state.firstname}
                                onChange={this.handleUpdateFirstName} />
                        </ div>
                        <div className="col-md-12 form-group" >
                        <label htmlFor="last_name" className="small">{getResource('Label_LastName')} *</label>
                            {!this.state.validations.lastName &&
                                <label id="last_name-error" className="error" for="last_name">Field required.</label>}
                            <input type="text"
                                className="form-control"
                                id="last_name"
                                name="last_name"
                                maxLength="50"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                placeholder="Last Name..."
                                value={this.state.lastname}
                                onChange={this.handleUpdateLastName} />                            
                        </ div>
                    </div>
                );
                break;
            default:
                break;
        }
        return (
            <div className="uk-grid" style={{ marginLeft: '0px' }}>
                <div className="uk-width-1-1 uk-width-xlarge" style={style.cForm}>
                    <div className="uk-card uk-card-default uk-width-xlarge" style={style.backForm}>
                        <div className="uk-card-body" style={style.cBody}>
                            <div>
                                {/* <h3 className="uk-card-title uk-heading-divider">{getResource("SubTitle_PreRegistration")}</h3> */}
                                <form>
                                    <div className="spaceSection">
                                        <div className="uk-grid-small uk-child-width-auto uk-grid">
                                            <label className="" style={style.preRegRadioLabel} for="form-country">{getResource('Button_PreReg')}:</label>
                                            {currentCompanyName !== 'Khyber' && <label>{getResource("Label_Corporate")} <input className="" type="radio" id="corporate" name="radio1" value={"Corporate"} checked={this.state.type === "Corporate"} onChange={this.onChangeCustomerType} /></label>}
                                            <label>{getResource("Label_Individual")} <input className="" type="radio" id="individual" name="radio1" value={"Individual"} checked={this.state.type === "Individual"} onChange={this.onChangeCustomerType} /></label>
                                            {/* <label>{getResource("Label_MSB")} <input className="" type="radio" id="msb" name="radio1" value={"MSB"} checked={this.state.type === "MSB"} onChange={this.onChangeCustomerType} /></label> */}
                                        </div>
                                    </div>
                                    <div className="borderSection" style={{ paddingTop: '0px' }}>
                                        <div className="spaceSection row">
                                            {isCorporate}
                                            <div className="col-md-12 form-group">
                                            <label htmlFor="residential_address_street" className="small">{getResource('Label_Country')} *</label>
                                                {!this.state.validations.country &&
                                                    <label id="form-country-error" className="error" for="form-country">Field required.</label>}
                                                <DynamicSelect
                                                    className={"form-control use-select2 " + this.state.placeholder}
                                                    id="form-country"
                                                    placeholder="Select a country"
                                                    getValue={this.getCountryValue.bind(this)}
                                                    data={this.state.countryList}
                                                    objValue={{ value: this.state.countryId, name: this.state.countryIdName }}/>                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="borderSection">
                                        <div className="spaceSection row">
                                            {nameCorporate}
                                            <div className="col-md-12 form-group" >
                                            <label htmlFor="password" className="small">{getResource('Label_Password')} *</label>
                                                {!this.state.validations.password &&
                                                    <label id="password-error" className="error" for="password">Field required.</label>}
                                                <input type="password"
                                                    className="form-control"
                                                    id="password"
                                                    name="password"
                                                    maxLength="50"
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required"
                                                    placeholder="Password..."
                                                    value={this.state.password}
                                                    onChange={this.handleUpdatePassword} />                                                
                                            </ div>
                                            <div className="col-md-12 form-group" >
                                            <label htmlFor="confPass" className="small">{getResource('Label_PasswordConfirmation')} *</label>
                                                {!this.state.validations.confPass &&
                                                    <label id="confPass-error" className="error" for="confPass">Field required.</label>}
                                                <input type="password"
                                                    className="form-control"
                                                    id="confPass"
                                                    name="confPass"
                                                    maxLength="50"
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required"
                                                    placeholder="Password Confirmation..."
                                                    value={this.state.confpass}
                                                    onChange={this.handleUpdateConfPass} />                                                
                                            </ div>
                                        </div>
                                    </div>
                                    <div className="spaceSection row" style={{marginTop:'10px'}}>
                                        <div className="col-lg-6 col-sm-12 form-group" >
                                        <label htmlFor="phone" className="small">{getResource('Label_MobileNumber')} *</label>
                                            {!this.state.validations.phone &&
                                                <label id="phone-error" className="error" for="phone">Field required.</label>}
                                            <PhoneInput
                                                autoFormat
                                                country={defaultMobileCountry}
                                                placeholder="Enter Mobile Number"
                                                copyNumbersOnly={false}
                                                countryCodeEditable={false}
                                                value={this.state.phone}
                                                onChange={this.handleUpdatePhone}
                                            />                                            
                                        </ div>
                                        <div className="col-lg-6 col-sm-12">
                                          {/**   <button className="btn btn-primary" style={style.wButton} id="preRegButton"onClick={this.validatePhoneClick}>{getResource('Button_Send')}</button>
                                           * */}
                                        </div>
                                    </div>
                                    <div className="spaceSection uk-margin">
                                        <button className="btn btn-primary pull-right" onClick={this.trySubmit}>{getResource('Button_Submit')}</button>
                                    </div>
                                </form>
                            </div>
                            <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                            <AlertBox open={this.state.showAlertPhoneValidated} onClose={this.closeAlertPhoneValidated} title="Success" message="Phone validated." type="Ok" okClick={this.closeAlertPhoneValidated} />
                            <AlertBox open={this.state.showAlertError} onClose={this.closeAlert2.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert2.bind(this)} />
                            <AlertBox
                                open={this.state.showInvalidReferralAlert}
                                onClose={this.closeInvalidReferralAlert}
                                title='Warning'
                                message='The entered Affiliate Referred by code is not valid.'
                                type="Ok"
                                okClick={this.closeInvalidReferralAlert}
                            />
                            <LoadingBox loading={this.state.loading} />                            
                            <ValidatePhone open={!this.state.phoneValidated && this.state.openValidatePhone} close={this.onValidatePhoneClose} 
                            bypassPhoneValidationConst={bypassPhoneValidationConst}
                            onSuccess={this.onValidatePhoneSuccess} phone={'+' + this.state.phone} companyName={this.state.companyname}/>
                        
                            <Dialog 
                            disableBackdropClick
                            disableEscapeKeyDown
                            maxWidth={'sm'} fullWidth={true}
                            open={this.state.showCompaniesHouse} onClose={() => {this.setState({showCompaniesHouse: false})}} aria-labelledby={'info-dialog'}>
                                <DialogTitle>{getResource('Label_CompaniesHouse')}</DialogTitle>
                                <DialogContent dividers>
                                <div className="uk-grid">
                                <div className="uk-width-1-2 uk-form-stacked">
                                    <label className="small">{getResource('Label_RegistrationNumber')}</label>
                                    <input
                                        id={'reg-nro'}
                                        type="text"
                                        className="form-control"
                                        value={this.state.regNumber}
                                        disabled={this.state.regNumberDisabled}
                                        onChange={(e)=>{this.setState({regNumber: e.target.value})}}
                                    />  
                                </div>
                                    <div className='uk-width-1-4' style={{paddingTop: '35px'}}>
                                        <button className="btn btn-primary" key={"search-CH"} type="button" onClick={this.searchCompaniesHouse}>Search</button>
                                    </div>
                                    <div className='uk-width-1-4' style={{paddingTop: '35px'}}>
                                        <button className="btn btn-secondary" key={"cancel-CH"} type="button" onClick={() => {this.setState({regNumber:'', regNumberDisabled: false, companyInfoJson:{}})}}>Clear</button>
                                    </div>
                                <DynamicInfoScreen infoJson={this.state.companyInfoJson} />
                                </div>

                                </DialogContent>
                                <DialogActions>
                                
                                <button className="btn btn-primary" key={"CH-continue"} type="button" onClick={this.continueCompaniesHouse}>Continue</button>
                                </DialogActions>
                            </Dialog>
                        
                            

                            <AlertBox
                                open={this.state.showAlertCH}
                                onClose={()=> this.setState({showAlertCH:false})}
                                title={'Message'}
                                message={'There is no information for this Registration Number'}
                                type="Ok"
                                okClick={()=> this.setState({showAlertCH:false})}
                            />

                <AlertBox
                    open={this.state.showConfirmCH}
                    onClose={this.closeConfirmCH}
                    title={getResource('Label_Warning')}
                    message={getResource('Label_CompaniesHouseConfirmation')}
                    type="Yes/No"
                    yesClick={this.yesConfirmCH}
                    noClick={this.closeConfirmCH}
                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}