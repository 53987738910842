import { Dialog, DialogActions, DialogContent, IconButton, Typography, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import MultiSelect from "react-multi-select-component";
import AppContext from '../../AppContext';
import { formatDate, formatValidInput } from '../../helper/FormatHelper';
import { validate } from '../../helper/ValidateHelper';
import { companiesHouseGetInformation } from '../../networking/NetworkingRegForm';
import AlertBox from '../shared/AlertBox';
import DatePicker from '../shared/DatePicker';
import DynamicInfoScreen from '../shared/DynamicInfoScreen';
import NumberInput from '../shared/NumberInput';
/* 
const REGEX= {
    number : '/^[0-9]+$/'
} */

const useCompaniesHouse = false;//window.REACT_USE_COMPANIES_HOUSE;
const useCompaniesHouseCountries = window.REACT_USE_COMPANIES_HOUSE_COUNTRIES;

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

export default class AdditionalInformation extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            validations: [],
            regNumberCompaniesHouse:'',
            showDialog : false,
            showAlert : false,
            companyInfoJson: {}
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.totalList !== prevProps.totalList) {
            this.loadPropertyList();
        }

        if (this.props.values !== prevProps.values) {
            this.setState({
                values: this.props.values,
                validations: this.props.validations
            });
        }

        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
    }

    componentDidMount() {
        this.loadPropertyList();
    }
    loadPropertyList = ()=>{
        if (Array.isArray(this.props.totalList)) {
            //Value for Customer
            let customerAdditionalProperties = this.props.customerAdditionalProperties;

            var listValues = [];
            if (this.props.values != null) {
                listValues = this.props.values
            }

            var listValidations = [];
            if (this.props.validations != null) {
                listValidations = this.props.validations
            }

            this.props.totalList.forEach(addditionalPropertiesDefs => {
                /*{
                    "addPropertydefID": 1,
                    "label": "Label Test One",
                    "description": "Label Test 1",
                    "dataType": "Text",
                    "mandatory": true,
                    "lookUpTableID": 0,
                    "lookUpTableDescription": "",
                    "rangeValidate": false,
                    "minDate": null,
                    "maxDate": null,
                    "minNumber": null,
                    "maxNumber": null,
                    "noOfDecimals": null,
                    "pageNumber": 1
                }
                let model = {
                    name        : addditionalPropertiesDefs.label,
                    value       : '',
                }
*/
                addditionalPropertiesDefs.name = addditionalPropertiesDefs.label;
                addditionalPropertiesDefs.value = '';

                //Value for Customer
                if (customerAdditionalProperties != null) {
                    customerAdditionalProperties.forEach(element => {
                        if (element.Description === addditionalPropertiesDefs.description) {
                            if (element.DataType !== 'Numeric') {
                                addditionalPropertiesDefs.value = element.value;
                            } else {
                                addditionalPropertiesDefs.value = parseInt(element.value);
                            }
                        }
                    })
                }

                if (Array.isArray(listValues)) {
                    listValues.push(addditionalPropertiesDefs);
                }

                if (addditionalPropertiesDefs.mandatory === true) {
                    let modelV = {
                        name: addditionalPropertiesDefs.label,
                        value: true
                    }
                    if (Array.isArray(listValidations))
                        listValidations.push(modelV)
                }
            });

            this.setState({
                values: listValues,
                validations: listValidations
            }, () => {
                this.switchSteps();
            });
        }
    }
    switchSteps = () => {
        switch (this.props.step) {
            default:
            case 1:
                {
                    this.context.validated(this.validateRequiredInputs(1), 1);
                    //this.context.validatedPartial(this.validateRequiredInputs(1), 1);
                    break;
                }
            case 2:
                {
                    this.context.validated2(this.validateRequiredInputs(2), 2);
                    //this.context.validatedPartial(this.validateRequiredInputs(2), 2);
                    break;
                }
            case 3:
                {
                    this.context.validated3(this.validateRequiredInputs(3), 3);
                    //this.context.validatedPartial(this.validateRequiredInputs(3), 3);
                    break;
                }
            case 4:
                {
                    this.context.validated4(this.validateRequiredInputs(4), 4);
                    //this.context.validatedPartial(this.validateRequiredInputs(4), 4);
                    break;
                }
            case 5:
                {
                    this.context.validated5(this.validateRequiredInputs(5), 5);
                    //this.context.validatedPartial(this.validateRequiredInputs(5), 5);
                    break;
                }
            case 6:
                {
                    this.context.validated6(this.validateRequiredInputs(6), 6);
                    //this.context.validatedPartial(this.validateRequiredInputs(6), 6);
                    break;
                }
            case 7:
                {
                    this.context.validated7(this.validateRequiredInputs(7), 7);
                    //this.context.validatedPartial(this.validateRequiredInputs(7), 7);
                    break;
                }
            case 8:
                {
                    this.context.validated8(this.validateRequiredInputs(8), 8);
                    //this.context.validatedPartial(this.validateRequiredInputs(8), 8);
                    break;
                }
        }
    }
    handleUpdateValueTypeList = (item, label) => {
        var aux = this.props.values;
        var auxVal = this.props.validations;

        var auxItems = item.map((country) => country.value).join(',');

        aux.forEach(element=> {
            const name = element?.name?.toLowerCase() ?? '';
            if((name.includes('trading countries') || name.includes('destination of funds') || name.includes('origin of funds') || name.includes('currencies')) && element?.name?.includes(label)){
                element.value = auxItems;
            }
        });
        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, 1);
            this.switchSteps();
        });
    }
    handleUpdateValueTypeText = (event) => {
        var aux = this.props.values;
        var auxVal = this.props.validations;

        if(event.target.id.toLowerCase().includes('registration number'))
            {
                this.setState({regNumberCompaniesHouse: event.target.value});
            }

        if (Array.isArray(aux)) {
            aux.forEach(element => {
                if (element.name === event.target.id) {
                    element.value = event.target.value.toLowerCase().includes('select') ? '' : event.target.value;
                }
            });
        }

        if (Array.isArray(auxVal)) {
            auxVal.forEach(currentVal => {
                if (currentVal.name === event.target.id) {
                    currentVal.value = true;
                }
            });
        }

        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, 1);
            this.switchSteps();
        });
    }

    handleUpdateValueTypeNumber = e => {
        let value = e.target.value;

        /*if (!Number(value)) {
            return;
        }*/

        if(e.target.id.toLowerCase().includes('registration number'))
        {
            this.setState({regNumberCompaniesHouse: value});
        }

        var aux = this.props.values;
        var auxVal = this.props.validations;

        aux.forEach(element => {
            if (element.name === e.target.id) {
                element.value = value;
            }
        });

        auxVal.forEach(currentVal => {
            if (currentVal.name === e.target.id) {
                currentVal.value = true;
            }
        });

        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, 1);
            this.switchSteps();
        });
    }

    handleUpdateValueTypeDate = (date, dayModifiers, dayPickerInput) => {

        console.log("handleUpdateValueTypeDate: " + JSON.stringify(date));
        console.log("handleUpdateValueTypeDate: " + JSON.stringify(dayPickerInput.props.inputProps.id));

        var aux = this.props.values;
        var auxVal = this.props.validations;

        aux.forEach(element => {
            if (element.name === dayPickerInput.props.inputProps.id) {
                element.value = formatDate(date);
            }
        });

        auxVal.forEach(currentVal => {
            if (currentVal.name === dayPickerInput.props.inputProps.id) {
                currentVal.value = true;
            }
        });

        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, 1);
            this.switchSteps();
        });
    }

    searchCompaniesHouse = async() =>{
        let customerID = sessionStorage.getItem('ActualCustomerID');
        if(this.state.regNumberCompaniesHouse && customerID > 0)
        {
            let companyInfo = await companiesHouseGetInformation(this.state.regNumberCompaniesHouse, customerID);
            console.log(companyInfo);
            if(companyInfo)
            {
                this.setState({companyInfoJson: companyInfo, showDialog: true});
            }
            else
            {
                this.setState({showAlert: true});
            }
        }
    }

    validateRequiredInputs(step) {
        var listValues = this.state.values;
        //var listValidations = this.state.validations;

        const fields = [];
        const others = [];

        /*{
            "addPropertydefID":1,
            "description":"Label Test 1",
            "dataType":"Text",
            "mandatory":false,
            "lookUpTableID":0,
            "lookUpTableDescription":"",
            "rangeValidate":false,
            "minDate":null,
            "maxDate":null,
            "minNumber":null,
            "maxNumber":null,
            "noOfDecimals":null,
            "pageNumber":1,
            value:
            name:
        }*/

        listValues.forEach(element => {
            if (element.mandatory === true) {
                var type = '';

                switch (element.dataType) {
                    case 'Text':
                        type = 'string';
                        break;
                    case 'Numeric':
                        {
                            type = 'wholeNumber';
                            break;
                        }
                    default:
                        type = 'string';
                        break;
                }

                var obj = {
                    displayName: element.label,
                    validateName: element.label,
                    value: element.value,
                    type: type
                }

                fields.push(obj);

                if (element.rangeValidate === true) {
                    //primero buscar el valor del elemento en el state
                    let objToValidate = null;
                    this.state.values.forEach(elementState => {
                        if (elementState.label === element.label) {
                            objToValidate = elementState;
                        }
                    });

                    //segun el tipo va a realizar las validaciones de rango
                    switch (objToValidate.dataType) {
                        case 'Text':
                            {
                                if (objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The value of ' + objToValidate.value + ' must not be more than ' + objToValidate.maxNumber + ' characters.',
                                        validation: this.textLengthVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value)        //No se puede mandar un method con parametros
                                    });
                                }
                                break;
                            }
                        case 'Numeric':
                            {
                                if (objToValidate.value !== 0 && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + objToValidate.minNumber + ' and ' + objToValidate.maxNumber + '.',
                                        validation: this.numericVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value)           //asi que lo guardo en otra lista para luego juntarlo
                                    });
                                }
                                break;
                            }
                        case 'DateTime':
                            {
                                if (objToValidate.value !== null && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + formatDate(objToValidate.minDate) + ' and ' + formatDate(objToValidate.maxDate) + '.',
                                        validation: this.dateTimeRangeVerification(objToValidate.minDate, objToValidate.maxDate, objToValidate.value)           //asi para todos
                                    });
                                }
                                break;
                            }
                        default: break;
                    }
                }
            }
        });
        const [valid, , errors] = validate(fields);
        let listErrors = [];
        switch (step) {           
            case 5:

                others.forEach(error => {                           //Acá junto con los validations por rango
                    if (error.validation !== true) {
                        errors.push(error.displayName)
                    }
                });
                listErrors = [{ tab: "", errors: errors }]
                return { valid, listErrors, type: 'Additional' };

            default:
                others.forEach(error => {
                    if (error.validation !== true) {
                        errors.push(error.displayName)
                    }
                });

                return { valid, errors, type: 'Additional' };
        }

    }

    textLengthVerification(min, max, value) {
        const newMin = parseInt(min);
        const newMax = parseInt(max);
        const lengthString = value.length;
        return (newMin <= lengthString && lengthString <= newMax);
    }

    numericVerification(min, max, value) {
        return (min <= value && value <= max);
    }

    dateTimeRangeVerification(minDate, maxDate, valueDate) {
        //const currentDate = formatStringToDate(valueDate);
        const miniDate = new Date(minDate);
        const maxiDate = new Date(maxDate);
        const currDate = new Date(valueDate);

        return (miniDate <= currDate && currDate <= maxiDate);
    }

    validateRequiredFields() {
        let listValues = this.state.values;
        let fields = [];
        let others = [];

        listValues.forEach(element => {
            if (element.mandatory === true) {
                var type = '';

                switch (element.dataType) {
                    case 'Text':
                        type = 'string';
                        break;
                    case 'Numeric':
                        {
                            type = 'wholeNumber';
                            break;
                        }
                    default:
                        type = 'string';
                        break;
                }

                var obj = {
                    displayName: element.label,
                    validateName: element.label,
                    value: element.value,
                    type: type
                }

                fields.push(obj);

                if (element.rangeValidate === true) {
                    //primero buscar el valor del elemento en el state
                    let objToValidate = null;
                    this.state.values.forEach(elementState => {
                        if (elementState.label === element.label) {
                            objToValidate = elementState;
                        }
                    });

                    //segun el tipo va a realizar las validaciones de rango
                    switch (objToValidate.dataType) {
                        case 'Text':
                            {
                                if (objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The value of ' + objToValidate.value + ' must not be more than ' + objToValidate.maxNumber + ' characters.',
                                        validation: this.textLengthVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value),        //No se puede mandar un method con parametros
                                        name: objToValidate.label
                                    });
                                }
                                break;
                            }
                        case 'Numeric':
                            {
                                if (objToValidate.value !== 0 && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + objToValidate.minNumber + ' and ' + objToValidate.maxNumber + '.',
                                        validation: this.numericVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value),           //asi que lo guardo en otra lista para luego juntarlo
                                        name: objToValidate.label
                                    });
                                }
                                break;
                            }
                        case 'DateTime':
                            {
                                if (objToValidate.value !== null && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + formatDate(objToValidate.minDate) + ' and ' + formatDate(objToValidate.maxDate) + '.',
                                        validation: this.dateTimeRangeVerification(objToValidate.minDate, objToValidate.maxDate, objToValidate.value),
                                        name: objToValidate.label           //asi para todos
                                    });
                                }
                                break;
                            }
                            default:break;
                    }
                }
            }
        });

        const [valid, validations] = validate(fields);

        var valis = this.state.validations;
        valis.forEach(element => {
            if (validations[element.name] != null) {
                element.value = validations[element.name];
            }
        });

        if (others.length !== 0) {
            // debugger
            valis.forEach(vali => {             //{name: "Label Test 3", value: true}
                others.forEach(error => {
                    /*
                        displayName: "The Label Test 3 value must be between 0 and 1000."
                        name: "Label Test 3"
                        validation: false
                    */
                    if (vali.name === error.name && error.validation === false) {
                        vali.value = false
                    }

                });
            });
        }

        this.setState({ validations: valis });
        return valid;
    }

    getExtendedCell = (addditionalPropertiesDefs) =>{
        let vals = [];
            if (this.state.values != null) {
                vals = this.state.values;
            }

            let validations = [];
            if (this.state.validations != null) {
                validations = this.state.validations;
            }
            let tradingValues = [];
            let tradingValuesIndex = vals.findIndex(element => {
                const name = element?.name?.toLowerCase() ?? '';
                return ((name.includes('trading countries') || name.includes('destination of funds') || name.includes('origin of funds')) && element.name === addditionalPropertiesDefs.label);
            });
            if (tradingValuesIndex > -1) {
                if (vals[tradingValuesIndex].value != null && vals[tradingValuesIndex].value !== '') {
                    tradingValues = vals[tradingValuesIndex].value.split(',').map(item => ({ label: item, value: item }));
                }
            }
            let currencyValues = [];
            let currencyyValuesIndex = vals.findIndex(element => {
                const name = element?.name?.toLowerCase() ?? '';
                return ((name.includes('currencies')) && element.name === addditionalPropertiesDefs.label);
            });
            if (currencyyValuesIndex > -1) {
                if (vals[currencyyValuesIndex].value != null && vals[currencyyValuesIndex].value !== '') {
                    currencyValues = vals[currencyyValuesIndex].value.split(',').map(item => ({ label: item, value: item }));
                }
            }
            let actualValue = '';
            let valueIndex = vals.findIndex(element => element.name === addditionalPropertiesDefs.label);
            if (valueIndex > -1) {
                actualValue = vals[valueIndex].value;
            }

            let actualVali = true;
            let validationsIndex = validations.findIndex(currentVal => currentVal.name === addditionalPropertiesDefs.label);
            if (validationsIndex > -1) {
                actualVali = validations[validationsIndex].value;
            }
            
        switch (addditionalPropertiesDefs.dataType) {
            case 'Text': {
                const label = addditionalPropertiesDefs.label.toLowerCase();
                if(label.includes('trading countries') || label.includes('destination of funds') || label.includes('origin of funds')) {
                    return (
                        <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                            {/*                                              
                            {tradingValues.length > 0 &&
                                <DropdownMultiple
                                name="country"
                                title="Select..."
                                titleSingular="Country"
                                searchable={["Search countries", "No matching country"]}
                                list={this.context.trades}
                                select={tradingValues}
                                onChange={this.handleUpdateValueTypeList}
                            />
                            }*/}
                            <label
                                className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                
                            </label>
                            {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                && 
                                <label className="instructions">{addditionalPropertiesDefs.instructions}
                                </label>
                                }

                            {!actualVali &&
                                <label
                                    id={addditionalPropertiesDefs.label + "-error"}
                                    class="error"
                                >Field Required.
                            </label>
                            }
                            < MultiSelect
                                className='extended-multiselect'
                                key={addditionalPropertiesDefs.label}
                                options={this.context.trades}
                                selected={tradingValues}
                                value={tradingValues}
                                onChange={(item) => this.handleUpdateValueTypeList(item, addditionalPropertiesDefs.label)}
                                overrideStrings={{ "selectSomeItems": `Select ${addditionalPropertiesDefs.label}...` }}
                            /> 
                        </div>
                    );
                } else if (label.includes('currencies')) {
                    return (
                        <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                             <label
                                className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}                                
                            </label>

                            {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                && 
                                <label className="instructions">{addditionalPropertiesDefs.instructions}
                                </label>
                                }

                            {!actualVali &&
                                <label
                                    id={addditionalPropertiesDefs.label + "-error"}
                                    class="error"
                                >Field Required.
                            </label>
                            }
                            < MultiSelect
                                className='extended-multiselect'
                                key={addditionalPropertiesDefs.label}
                                options={this.context.currencies}
                                selected={currencyValues}
                                value={currencyValues}
                                onChange={(item) => this.handleUpdateValueTypeList(item, addditionalPropertiesDefs.label)}
                                overrideStrings={{ "selectSomeItems": `Select ${addditionalPropertiesDefs.label}...` }}
                            />                                                   
                        </div>
                    );
                } else if (addditionalPropertiesDefs.lookUpTableID && addditionalPropertiesDefs.lookUpTableDetails != null && addditionalPropertiesDefs.lookUpTableDetails.length > 0) {
                    const details = [...addditionalPropertiesDefs.lookUpTableDetails].sort((a, b) => a.DisplayOrder - b.DisplayOrder);
                    return (
                        <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                            <label
                                className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                            </label>
                            {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                && 
                                <label className="instructions">{addditionalPropertiesDefs.instructions}
                                </label>
                                }

                            {!actualVali &&
                                <label
                                    id={addditionalPropertiesDefs.label + "-error"}
                                    class="error"
                                >Field Required.
                            </label>
                            }
                            <select
                                className="form-control"
                                id={addditionalPropertiesDefs.label}
                                key={addditionalPropertiesDefs.label}
                                name="residential_address_street"
                                maxLength="100"
                                value={actualValue}
                                onChange={this.handleUpdateValueTypeText}
                            >
                                {details.map((d) => (
                                    <option value={d.Description}>{d.Description}</option>
                                ))}
                            </select>
                        </div>
                    );
                } else {
                    return (
                        <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                            <label
                                className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                            </label>
                            {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                && 
                                <label className="instructions">{addditionalPropertiesDefs.instructions}
                                </label>
                                }

                            {!actualVali &&
                                <label
                                    id={addditionalPropertiesDefs.label + "-error"}
                                    class="error"
                                >Field Required.
                            </label>
                            }
                            <input
                                type="text"
                                className="form-control"
                                id={addditionalPropertiesDefs.label}
                                key={addditionalPropertiesDefs.label}
                                name="residential_address_street"
                                maxLength="100"
                                value={actualValue}
                                onChange={this.handleUpdateValueTypeText}
                            />    
                            {
                                (useCompaniesHouse && useCompaniesHouseCountries.includes(this.props.countryID) && addditionalPropertiesDefs.label.toLowerCase().includes('registration number')) 
                                &&
                                <button
                        type="button"
                        id="companies_house_button"
                        key="companies_house_button"
                        className="btn btn-primary"
                        style={{ borderColor: 'white' }}
                        onClick={this.searchCompaniesHouse}>Companies House</button>
                                    
                            }                                                
                        </div>
                    );
                }
            }
            case 'Numeric': {
                return (
                    <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                        <label
                            className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                        </label>
                        {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                && 
                                <label className="instructions">{addditionalPropertiesDefs.instructions}
                                </label>
                                }
                        {!actualVali &&
                            <label
                                id={addditionalPropertiesDefs.label + "-error"}
                                class="error"
                            >Field Required.
                        </label>
                        }
                        <NumberInput
                            id={addditionalPropertiesDefs.label}
                            type="Integer"
                            className="form-control"
                            value={actualValue}
                            onChange={this.handleUpdateValueTypeNumber}
                        />        
                        {
                                (useCompaniesHouse && this.props.countryID === 'GBR' && addditionalPropertiesDefs.label.toLowerCase().includes('registration number')) 
                                &&
                                <button
                                type="button"
                                id="companies_house_button"
                                key="companies_house_button"
                                className="btn btn-primary"
                                style={{ borderColor: 'white', marginTop:'10px' }}
                                onClick={this.searchCompaniesHouse}>Companies House</button>
                                            
                                }                                            
                    </div>
                );
            }
            case 'DateTime': {
                return (
                    <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                        <label
                            className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                        </label>
                        {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                && 
                                <label className="instructions">{addditionalPropertiesDefs.instructions}
                                </label>
                                }

                        {!actualVali &&
                            <label
                                id={addditionalPropertiesDefs.label + "-error"}
                                class="error"
                            >Field Required.
                        </label>
                        }
                        <DatePicker
                            id={addditionalPropertiesDefs.label}
                            className="form-control extended-datepicker"
                            readOnly={true}
                            value={actualValue}
                            onDayChange={this.handleUpdateValueTypeDate}
                        />                                               
                    </div>
                );
            }
            default: return <></>;
        }
    }

    getExtendedRows = () =>{
        let entendedRows = [];
        let extendedRow = [];
        let columnsTotal = 2;
        let count = 0;
        
        const groupInfo = this.props.totalList.reduce((properties, addditionalPropertiesDefs) => {
            if (!properties[addditionalPropertiesDefs.groupBy]) properties[addditionalPropertiesDefs.groupBy] = [];
            properties[addditionalPropertiesDefs.groupBy].push(addditionalPropertiesDefs);
            return properties;
          }, {});

          let groupBy = Object.keys(groupInfo).map(function (key) {
            return [key, groupInfo[key]];
        });
          
          if(groupBy && groupBy.length > 0)
          {
            groupBy.sort((a, b) =>
            a[0]> b[0] ? 1 : -1,
          ).map(group => {

                    if(group[0])
                    {
                        let groupTitle = (!group[0].includes('#@')) ? group[0]:'';
                        entendedRows.push(<div className='extended-group'><p>{groupTitle}</p></div>)
                    }

                    let totalProps = group[1].length;
                    let diff = totalProps % columnsTotal;
                    let countProps = 0;
                    group[1].map(addditionalPropertiesDefs => {

                        if(count < columnsTotal)
                        {
                            extendedRow.push(this.getExtendedCell(addditionalPropertiesDefs));
                            count++;
                            countProps ++;
                            if(count === columnsTotal || countProps === totalProps)
                            {
                                count=0;
                                entendedRows.push(<div className="row" style={{display: 'flex',alignItems: 'flex-end'}}>
                                    {extendedRow}
                                </div>);
                                extendedRow = [];
                            }  
                        }           
                    })
                })
            }
        return entendedRows;
    }


    render() {

        if (this.props.totalList.length === 0) {
            return (<div></div>);
        } else {
            return (
                <>
                {this.getExtendedRows()}
                <Dialog 
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.showDialog} onClose={()=> this.setState({showDialog:false})} aria-labelledby={'info-dialog'}>
              <DialogTitle>{'Company Information'}</DialogTitle>
              <DialogContent>
                <DynamicInfoScreen infoJson={this.state.companyInfoJson} />
                </DialogContent>
              <DialogActions>
              <button className="btn btn-primary" key={"Ok-info"} type="button" onClick={()=> this.setState({showDialog:false})}>Ok</button>
              </DialogActions>
          </Dialog>

                <AlertBox
                    open={this.state.showAlert}
                    onClose={()=> this.setState({showAlert:false})}
                    title={'Message'}
                    message={'There is no information for this Registration Number'}
                    type="Ok"
                    okClick={()=> this.setState({showAlert:false})}
                />
                </>
            );
        }
    }
}