import React from 'react';
import AppContext from '../../AppContext';
import { getResource } from '../../helper/ResourcesHelper';
import { validate } from '../../helper/ValidateHelper';
import { deleteE2EAttachment } from '../../networking/NetworkingAttachments';
import AlertBox from '../shared/AlertBox';
import AttachmentsDialog from '../shared/AttachmentsDialog';
import NewDynamicTable from '../shared/NewDynamicTable';

const generateAttachment = (documentFiles, func, clearFunc) => (
    <div id="submit-form-area">
        <div className="row">
            <div className="col-md-12">
                <div className="pd-10p">                
                <label htmlFor='passport_additional_01'>
                    <a className='button'>+ Upload attachment</a>
                </label>
                {documentFiles && documentFiles.length > 0 &&
                    <label>
                        <a className='red' onClick={clearFunc}>- Remove documents</a>
                    </label>
                }
                <input type="file"
                    accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                    name="passport_additional_01" id="passport_additional_01"
                    onChange={func}
                    style={{display: 'none'}}
                    data-rule-required="true"
                    data-msg-required="Attachment required"
                    data-accept-size="512000"
                    data-accept-size-message="Maximum 500 kb"
                    data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                    data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />                                                    
                                                
                    {documentFiles.map(additionalFile =>
                    <div className="col-md-12">
                        <label>{additionalFile.FileName}</label>
                    </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);
const loadingAttachment = (detail, element, func, showAttachmentsDialog, rowClick, changeState, showAttachmentsDialogFunc, handleRowClick, objRow) => (
    
    <div className="row">
        <div className="col-md-12">
            <NewDynamicTable key="utility_bill"
                hiddenHeaders={['description', 'AttachmentId', 'content']}
                data={element}
                enableClick={true}
                clickFunction={handleRowClick.bind(this)}
                newSearch={false}
                useDeleteButton={true}
                deleteFunction={func.bind(this)}
                enableSortByHeader={false}
                usePaginator={false}
                useFilter={false}
            />
            <AttachmentsDialog
                open={showAttachmentsDialog}
                onClose={showAttachmentsDialogFunc}
                attachmentId={objRow.AttachmentId}
                file={objRow}
                rowClick={rowClick}
                changeState={changeState}
            />
            
        </div>
    </div>
);
export default class TaskAttachments extends React.Component {
    static contextType = AppContext;
    state = {
        customerAgreement: false,
        attachmentGenerate: [],
        documentFiles: [],
        documents: {
            utilityBillFile: '',
            utilityBillFileName: '',
        },
        TaskList: [],
        showDeleteAlert: false,
        //
        showAttachmentsDialog: false,
        rowClick: false,
        showAttachmentsDialog1: false,
        rowClick1: false,
        readyToDeleteAttachment: {},
        objRowClick: {}
    }
    componentDidMount() {
        if (this.props.attachmentGenerate.length > 0) {
            this.setState({ attachmentGenerate: this.props.attachmentGenerate, ...this.props.objTaskAttachments }, () => {
                //this.context.validated6(this.validateRequiredInputs(this.props.attachmentGenerate));
                this.props.TaskAttachments(this.state);
            })
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.clickNext !== this.props.clickNext && this.props.clickNext) {
            let item = document.getElementById("document_form");
            if (item != null) {
                item.focus();
            }
            this.props.NoClickNext();
        }
        if (this.props.attachmentGenerate != null && prevProps.attachmentGenerate !== this.props.attachmentGenerate) {
            this.setState({ attachmentGenerate: this.props.attachmentGenerate, TaskList: this.props.objTaskAttachments }, () => {
                //this.context.validated6(this.validateRequiredInputs(this.props.attachmentGenerate));
                this.props.TaskAttachments(this.state);
            })
        }
    }
    /*
    AttachmentBySubEntityTypeID: 4
    AttachmentLinks: 33
    Countries: null
    Description: "Testing doc 1"
    Display Order: 0
    Mandatory: false
    Property/Task: "Personal Identification document - 1"
    TaskID: "724BFD3A-C44A-456C-8DF8-232680DD4627"
    UserPropertyID: null
    */
    handleChangeUtilityBillFile = async (index, event) => {
        let documentFiles = this.state.documentFiles;
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            //const newDocuments = { ...this.state.documents, ['accountFile' + index]: fileContent, ['accountFileName' + index]: file.name };
            let helper = this.state.attachmentGenerate[index];
            let document = {
                UserPropertyID: helper.UserPropertyID != null ? helper.UserPropertyID : 0,
                TaskID: helper.TaskID,
                TaskName: helper['Property/Task'],
                FileName: file.name,
                Content: fileContent
            };
           /* if (documentFiles.length > 0) {
                documentFiles.map((value, index1) => {
                    if (value.TaskID !== document.TaskID) {
                        documentFiles.push(document);
                    } else {
                        documentFiles.splice(index1);
                        documentFiles.push(document);
                    }
                })
            } else {*/
                documentFiles.push(document);
            //}
            this.setState({ documentFiles }, () => {
                this.context.validated6(this.validateRequiredInputs(documentFiles));
                this.props.TaskAttachments(this.state);
            });
            //this.setState({ documents: newDocuments });
            //this.props.CustomerAgreement(newDocuments);
        } else {
            if (documentFiles.length > 0) {
                documentFiles.map((value, index1) => {
                    if (value.TaskID === document.TaskID) {
                        documentFiles.splice(index1);
                    }
                })
            }
            this.setState({ documentFiles }, () => {
                this.context.validated6(this.validateRequiredInputs(documentFiles));
                this.props.TaskAttachments(this.state);
            });
            /*const newDocuments = { ...this.state.documents, ['accountFile' + index]: '', ['accountFileName' + index]: '' };
            this.setState({ documents: newDocuments });
            this.props.CustomerAgreement(newDocuments);*/
        }
    }

    readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    }

    validateRequiredInputs = (attachments) => {
        let fields = [];
        let documentFiles = [];
        let TaskList = this.state.TaskList;
        attachments.forEach((value) => {
            if (value.Mandatory) {
                documentFiles.push({
                    UserPropertyID: value.UserPropertyID != null ? value.UserPropertyID : 0,
                    TaskID: value.TaskID,
                    TaskName: value['Property/Task'],
                    FileName: value.FileName != null ? value.FileName : '',
                    Content: value.Content != null ? value.Content : ''
                });
                if (TaskList.length > 0) {
                    TaskList.forEach(element => {
                        documentFiles.forEach((helper, index) => {
                            if (element.Title !== value['Property/Task']) {
                                if (helper.TaskID === value.TaskID) {
                                    fields.push(
                                        { displayName: value.Description, validateName: element.Files[index].filename, value: element.Files[index].filename, type: 'string' },
                                    );
                                }
                            }
                        });
                    })
                } else {
                    documentFiles.forEach((helper, index) => {
                        if (helper.TaskID === value.TaskID) {
                            fields.push(
                                { displayName: value.Description, validateName: documentFiles[index].FileName, value: documentFiles[index].FileName, type: 'string' },
                            );
                        }
                    });
                }
            }
        });
        const [valid, , errors] = validate(fields);
        return { valid, errors };
    }
    handleChangeUtilityBillFileStatic = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, utilityBillFile: fileContent, utilityBillFileName: file.name };
            this.setState({ documents: newDocuments });
            this.props.TaskAttachments(this.state);
            //this.context.validated5(this.validateRequiredInputs(newDocuments));
        } else {
            const newDocuments = { ...this.state.documents, utilityBillFile: '', utilityBillFileName: '' };
            this.setState({ documents: newDocuments });
            this.props.TaskAttachments(this.state);
            //this.context.validated5(this.validateRequiredInputs(newDocuments));
        }
    }
    deleteAttachmentClick = (obj) => {
        console.log(obj);
        console.log('Deleteee');
        this.setState({ showDeleteAlert: true, readyToDeleteAttachment: obj });
    }

    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteAttachment: {} });
    }

    yesDeleteAlert() {
        if(this.state.readyToDeleteAttachment && this.state.readyToDeleteAttachment.AttachmentId)
        {

            deleteE2EAttachment(this.state.readyToDeleteAttachment.AttachmentId).then(
                (jsonResponse) => {
                    if(jsonResponse && jsonResponse.Result)
                    {
                    let TaskList = this.state.TaskList;

                    TaskList.forEach((value, index) => {
                        let files = value.Files;
                        files = files.filter(f => f.AttachmentId !== this.state.readyToDeleteAttachment.AttachmentId );

                        value.Files = files;
                        
                            this.setState({
                                TaskList,
                                showDeleteAlert: false,
                                readyToDeleteAttachment:{}
                            });
                        }
                    );
                    }
                    else
                    {
                        this.setState({
                            showDeleteAlert: false,
                            readyToDeleteAttachment:{}
                        });
                    }
                })
        }
    }
    //RowClickTable
    handleRowClick = () => {
        this.setState({ showAttachmentsDialog: true, rowClick: true })
    }
    showAttachmentsDialog = () => {
        this.setState({ showAttachmentsDialog: false })
    }
    changeState = () => {
        this.setState({ rowClick: false })
    }
    handleRowClickAttachments = (obj) => {
        this.setState({ showAttachmentsDialog1: true, rowClick1: true, objRowClick: obj })
    }
    showAttachmentsDialogAttachments = () => {
        this.setState({ showAttachmentsDialog1: false })
    }
    changeStateAttachments = () => {
        this.setState({ rowClick1: false, objRowClick:{} })
    }

    clearNewDocuments = () => {
        this.setState({ documentFiles:[] }, () => {
            this.context.validated6(this.validateRequiredInputs([]));
            this.props.TaskAttachments(this.state);
        });
    }

    render() {
        let attatchments = this.state.attachmentGenerate;
        return (
            <div className="row" id="customer_agreement">
                {attatchments.length > 0 &&
                    <div className="col-md-12 form-group">
                        <h5><b>{getResource('SubTitle_AdditionalAttachments')}</b></h5>
                        <p>If you wish to upload any relevant documentation to streamline the onboarding process, such as sales or purchase invoices to support business activity, you can do so here, but it is not mandatory at this stage.</p>
                    </div>
                }
                <div className="col-sm-12 col-xs-12">
                    {
                        attatchments.map((detail, index) => {
                            if (this.state.TaskList.length > 0) {
                                var isEqual = false;
                                var helper = null;
                                this.state.TaskList.map((element) => {
                                    if (element.Title === detail['Property/Task']) {
                                       // isEqual = true;
                                        helper = element;
                                        return;
                                    }
                                })
                                //if (isEqual)
                                    return (
                                        <>
                                        {helper && helper.Files && loadingAttachment(detail, helper.Files, this.deleteAttachmentClick, this.state.showAttachmentsDialog1, this.state.rowClick1, this.changeStateAttachments, this.showAttachmentsDialogAttachments, this.handleRowClickAttachments, this.state.objRowClick
                                       )}
                                        {generateAttachment(this.state.documentFiles, this.handleChangeUtilityBillFile.bind(this, index),this.clearNewDocuments)}
                                        </>
                                    )
                                //else
                                    //return (generateAttachment(detail, this.handleChangeUtilityBillFile.bind(this, index)))
                            } else {
                                return (
                                    <>
                                        
                                        {generateAttachment(this.state.documentFiles, this.handleChangeUtilityBillFile.bind(this, index),this.clearNewDocuments)}
                                        </>
                                    )
                            }
                        })
                    }
                </div>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this file?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
            </div>
        );
    }
}