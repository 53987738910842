import jwt from 'jsonwebtoken';
var CryptoJS = require("crypto-js");

const encryptKey = process.env.REACT_APP_ENCRYPTION_KEY;
const useEncryption = window.REACT_APP_USE_NEW_ENCRYPT;
const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;
const sessionID = window.REACT_APP_API_SESSION_ID;

export async function decrypt(cypher){
    let responseJSON;
    if(useEncryption){
        var cipher = cypher.data;

        var key = CryptoJS.enc.Utf8.parse(encryptKey);     // Use Utf8-Encoder. 
        var iv  = CryptoJS.enc.Utf8.parse('');                     // Use Utf8-Encoder

        var ciphertext = CryptoJS.enc.Base64.parse(cipher);    // Use Base64-Encoder.       
        var encryptedCP = CryptoJS.lib.CipherParams.create({
            ciphertext: ciphertext,
            formatter: CryptoJS.format.OpenSSL                                     // Optional, but required for encryptedCP.toString() 
        });
        var decryptedWA = CryptoJS.AES.decrypt(encryptedCP, key, { iv: iv});
        var decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8); 
        responseJSON = await JSON.parse(decryptedUtf8);

    } else {
       var decoded = jwt.decode(cypher.data);
       responseJSON = decoded.response;
    }

    return responseJSON;
}

export async function genericGetWithParameters(apiName, params){
    try {
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJWT = await response.json();
        //console.log(responseJWT.data);
        console.log(responseJWT);
        let responseJSON = await decrypt(responseJWT);
        console.group(apiName)
        console.log(responseJSON);
        console.groupEnd();
        responseJSON.httpStatusCode = response.status;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function genericDeleteWithParameters(apiName, params){
    try {
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'DELETE',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBody(method, apiName, model){
    try {
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        console.log(response);
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 200:
                break;
            case 300:
                httpErrorMessage = 'Duplicated user name.';
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        responseJson.httpErrorMessage = httpErrorMessage;
        return responseJson;
        /*if(response.status === 200 ||
            response.status === 400){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.httpStatusCode = response.status;
            return responseJSON;
        }else{
            return response;
        }*/
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBodySession(method, apiName, model){
    try {
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                SessionID: sessionID,
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        console.log(responseJson);
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}
export async function genericGetWithParametersNoEncrypted(apiName, params){
    try {
        let query = '';
        if(params != null){
            query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
        } 
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let jsonResponse = await response.json();
        jsonResponse.httpStatusCode = response.status;
        return jsonResponse;
    } catch (error) {
        console.error(error);
    }
}
export async function genericCallWithBodyAndResponseDecoded(method, apiName, model){
    try {
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                SessionID: sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        let responseJWT = await response.json();
        let responseJSON = await decrypt(responseJWT);
        responseJSON.httpStatusCode = response.status;
        console.group(apiName)
        console.log(responseJSON);
        console.groupEnd();
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}
export async function getLookUpTable(name) {
    try {
        let params = {
            LookUpTablesDescription: name,
        }
        let response = await genericGetWithParameters('LookUpTable/Get', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export const getCustomerByID = async (customerID) => {
    try {
        const params = {
            'customerID': customerID
        };
        const response = await genericGetWithParameters('Customer/GetCustomerById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export const getCustomerInfoByID = async (customerID) => {
    try {
        const params = {
            'customerID': customerID
        };
        const response = await genericGetWithParameters('Customer/GetRegistrationFormInfo', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function newSearchCustomers(search,page,items){
    try {
        let params = {
            'TextToSearch': search,
            'Page': page,
            'Items': items
        }
        let response = await genericGetWithParameters('CustomerSearch/GetJson',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getParametersByName(parameterNames){
    try{
        let params = {
            "parameterNames":parameterNames,
        };

        let response = await genericGetWithParameters('GlobalSystemParameter/GetParametersByName', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getParameterByName(parameterName) {
    try {
        const params = {
            "parameterName":parameterName
        };

        const response = await genericGetWithParametersNoEncrypted('GlobalSystemParameter/Get', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getToken(){
    try {
        let response = await fetch(apiURL + 'AFEX/GetToken' , {
            method: 'GET',
            headers: {
            },
        });
        let responseJWT = await response.json();
        //console.log(responseJWT.data);
        var decoded = jwt.decode(responseJWT.data);
        console.log(decoded);
        console.groupEnd();
        let responseJSON = decoded.response;
        responseJSON.httpStatusCode = response.status;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}
export async function getGenericAFEX(apiName) {
    try {
        let response = await fetch(apiURL + apiName, {
            method: 'GET',
            headers: {
                'Token': sessionStorage.getItem('Token')
            },
        });
        let responseJWT = await response.json();
        //console.log(responseJWT.data);
        var decoded = jwt.decode(responseJWT.data);
        console.log(decoded);
        console.groupEnd();
        let responseJSON = decoded.response;
        responseJSON.httpStatusCode = response.status;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBodyAFEX(method, apiName, model){
    try {
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Token': sessionStorage.getItem('Token')
            },
            body: JSON.stringify(model),
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        console.log(responseJson);
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithParametersAFEX(apiName, params){
    try {
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Token': sessionStorage.getItem('Token')
            },
        });
        let responseJWT = await response.json();
        //console.log(responseJWT.data);
        var decoded = jwt.decode(responseJWT.data);
        console.group(apiName)
        console.log(decoded);
        console.groupEnd();
        let responseJSON = decoded.response;
        responseJSON.httpStatusCode = response.status;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function genericPostWithParametersAFEX(apiName, params){
    try {
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Token': sessionStorage.getItem('Token')
            },
        });
        let jsonResponse = await response.json();
        jsonResponse.httpStatusCode = response.status;
        return jsonResponse;
    } catch (error) {
        console.error(error);
    }
}

export const checkValidReferralCustomer = async (customerID) => {
    try {
        const params = {
            'customerID': customerID
        };
        const response = await genericGetWithParameters('Customer/CheckValidReferralCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getCustomerInfoByRequestID = async (requestID) => {
    try {
        const params = {
            'requestID': requestID
        };
        const response = await genericGetWithParameters('Customer/GetRegistrationFormInfoByRequestID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}